import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import SignInCard from "./SignInCard";
import partner from "../../../assets/images/collaboration.jpeg";
import provider from "../../../assets/images/chess2.jpeg";
import school from "../../../assets/images/karate.jpeg";
import parent from "../../../assets/images/parents.jpeg";
import university from "../../../assets/images/uni1.jpeg";
import admin from "../../../assets/images/admin.jpeg";

const data = [
  {
    id: 1,
    title: "Partner",
    subtitle: "Are you a Partner? Sign in here.",
    linkTo: "partner-signin",
    backgroundColor: colors.primary,
    src: partner,
  },
  {
    id: 2,
    title: "Provider",
    subtitle: "Are you a Service Provider? Sign in here.",
    linkTo: "provider-signin",
    backgroundColor: colors.green,
    src: provider,
  },
  {
    id: 3,
    title: "School",
    subtitle: "Are you a School Owner? Sign in here.",
    linkTo: "school-signin",
    backgroundColor: colors.orange,
    src: school,
  },
  {
    id: 4,
    title: "Parent",
    subtitle: "Are you a Parent? Sign in here.",
    linkTo: "parent-signin",
    backgroundColor: colors.bluePry,
    src: parent,
  },
  {
    id: 5,
    title: "University",
    subtitle: "Are you a University? Sign in here.",
    linkTo: "university-signin",
    backgroundColor: colors.secondary,
    src: university,
  },
  {
    id: 6,
    title: "Admin",
    subtitle: "Are you an Admin? Sign in here.",
    linkTo: "admin-signin",
    backgroundColor: colors.dark,
    src: admin,
  },
];

const SignIn = () => {
  return (
    <Container>
      <Wrapper>
        <h1>Sign in</h1>
        <small>
          Select your user type and sign in to your account to access your
          dashboard.
        </small>
        <CardWrapper>
          {data.map((item) => {
            return (
              <SignInCard
                key={item.id}
                title={item.title}
                subtitle={item.subtitle}
                linkTo={item.linkTo}
                backgroundColor={item.backgroundColor}
                src={item.src}
              />
            );
          })}
        </CardWrapper>
      </Wrapper>
    </Container>
  );
};

export default SignIn;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: 90vh;
  margin: 1rem auto;

  h1 {
    font-size: 2rem;
    font-weight: 500;
    color: ${colors.dark};
    margin-bottom: 0.5rem;
  }

  small {
    font-size: 0.7rem;
    color: ${colors.dark};
    margin-bottom: 2rem;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  row-gap: 2rem;

  @media Screen and (max-width: ${screens.tab}) {
    flex-direction: column;
    gap: 1rem;
  }
`;
