import { styled } from "styled-components";
import FeatureCard from "./FeatureCard";
import { IoAppsOutline } from "react-icons/io5";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { BiSolidBadgeDollar } from "react-icons/bi";
import { BsPersonCheck } from "react-icons/bs";
import { TbApps } from "react-icons/tb";
import { HiOutlineBadgeCheck } from "react-icons/hi";
import { BsAward } from "react-icons/bs";
import src from "../../../../assets/images/books.jpeg";
import { useNavigate } from "react-router-dom";
import { colors, screens } from "../../../../utils";

const data = [
  {
    id: 1,
    icon: <BsPersonCheck size="3rem" color={colors.dark} />,
    title: "Easy for schools to onboard",
    body: "Schools can easily onboard and access hundreds of Providers within minutes",
  },
  {
    id: 2,
    icon: <FaMoneyCheckDollar size="3rem" color={colors.dark} />,
    title: "Unlimited opportunities for Providers",
    body: "Access unlimited opportunities with thounsands of registered students on the platform",
  },
  {
    id: 3,
    icon: <TbApps size="3rem" color={colors.dark} />,
    title: "Tools to make the job easy",
    body: "Easy management of activities for both the Providers and Schools.",
  },
  {
    id: 4,
    icon: <BsAward size="3rem" color={colors.dark} />,
    title: "Juicy rewards for students",
    body: "From micro-scholarships to a robust Welcome Pack, the platform offers great rewards",
  },
];

const Work = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/sign-up");
  };

  return (
    <Container>
      <Wrapper>
        <ImageWrapper>
          <img src={src} alt="" />
        </ImageWrapper>
        <Content>
          <TitleWrapper>
            <h5>The only Extracurriculr ecosystem in Africa</h5>
          </TitleWrapper>
          <ContentWrapper>
            {data.map((feature) => {
              return (
                <FeatureCard
                  key={feature.id}
                  icon={feature.icon}
                  title={feature.title}
                  body={feature.body}
                />
              );
            })}
          </ContentWrapper>
          <Button onClick={handleClick}>Sign up for free</Button>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Work;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  gap: 2rem;

  @media Screen and (max-width: ${screens.microtab}) {
    width: 90%;
    flex-direction: column-reverse;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 45%;
  height: 65vh;
  overflow: hidden;
  border-radius: 0.5rem;

  @media Screen and (max-width: ${screens.minitab}) {
    width: 100%;
    height: 50vh;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  width: 55%;
  flex-direction: column;
  gap: 0.2rem;

  @media Screen and (max-width: ${screens.microtab}) {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h5 {
    font-weight: 500;
    color: ${colors.black};
    font-size: 1.8rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  display: flex;
  border: none;
  width: fit-content;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: ${colors.white};
  font-weight: 600;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: ${colors.primary};
`;
