import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { PiTagChevronThin } from "react-icons/pi";
import { MdOutlineScore } from "react-icons/md";
import { TbUserCheck } from "react-icons/tb";
import { colors, screens } from "../../../utils";

interface IActivity {
  linkTo: string;
  name: string;
  score: string;
  sessions: number;
}

const OneResult = ({ linkTo, name, score, sessions }: IActivity) => {
  return (
    <Container>
      <ActivityLink to={linkTo}>
        <Wrapper>
          <Title>
            <PiTagChevronThin size="0.8rem" color={colors.dark} />
            <p>{name}</p>
          </Title>

          <Content>
            <IconWrapper>
              <MdOutlineScore size="0.9rem" color={colors.dark} />
            </IconWrapper>
            <ContentText>{score}</ContentText>
          </Content>
          <Content>
            <IconWrapper>
              <TbUserCheck size="0.9rem" color={colors.dark} />
            </IconWrapper>
            <ContentText>{sessions}</ContentText>
          </Content>
        </Wrapper>
      </ActivityLink>
    </Container>
  );
};

export default OneResult;

const Container = styled.div`
  display: flex;
  //flex-direction: column;
  border-top: 0.5px solid ${colors.grey};
  border-bottom: 0.5px solid ${colors.grey};

  &:hover {
    border: 0.5px solid ${colors.grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const ActivityLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0.5rem;
  gap: 1rem;
`;

const Title = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    margin: auto 1rem auto 0;
    display: flex;
    width: 100%;
    text-transform: capitalize;
    word-wrap: break-word;

    @media (max-width: ${screens.minitab}) {
      width: 80%;
      margin: auto;
    }
  }
`;

const ContentText = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.dark};
  display: flex;
  width: 100%;
  text-transform: capitalize;

  strong {
    font-size: 0.8rem;
    font-weight: 700;
    color: ${colors.dark};
    display: flex;
    margin-right: 0.3rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  width: fit-content;
  margin-left: auto;
  gap: 0.4rem;
`;
