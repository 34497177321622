import React, { useState, ChangeEvent, FormEvent } from "react";

// Define the type for form values
export interface FormValues {
  [key: string]: string;
}

// Define the type for the useForm hook
export type UseFormType<T extends FormValues> = {
  formValues: T;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  resetForm: () => void;
};

// Define the useForm hook
const useForm = <T extends FormValues>(initialState: T): UseFormType<T> => {
  // Initialize state for form values
  const [formValues, setFormValues] = useState<T>(initialState);

  // Function to handle input changes
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Function to reset the form to its initial state
  const resetForm = () => {
    setFormValues(initialState);
  };

  // Return form values and functions to handle input changes and reset the form
  return {
    formValues,
    handleInputChange,
    resetForm,
  };
};

export default useForm;
