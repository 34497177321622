import React, { ReactNode } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Navigation from "../../components/common/Navigation";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { logoutProvider, selectProvider } from "../../app/features/providerSlice";

const data = [
  {
    id: 1,
    title: "Dashboard",
    linkTo: "provider-dashboard/home",
  },

  {
    id: 2,
    title: "Activities",
    linkTo: "provider-dashboard/activities",
  },

  {
    id: 3,
    title: "Schools & Students",
    linkTo: "provider-dashboard/schools",
  },

  {
    id: 4,
    title: "Schedule",
    linkTo: "provider-dashboard/schedules",
  },

  {
    id: 5,
    title: "Settings",
    linkTo: "provider-dashboard/settings",
  },
];

interface IProviderLayout {
  children: ReactNode;
}

const ProviderLayout = ({ children }: IProviderLayout) => {
  const { token: providerToken, firstName } = useAppSelector(selectProvider);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(logoutProvider());
    navigate("/sign-in/provider-signin");
    toast.success("You successfully logged out");
  };



  return (
    <ProtectedRoute token={providerToken} linkTo="/sign-in/provider-signin">
      <Navigation
        token={providerToken}
        firstName={firstName}
        clickLink="provider-signup"
        buttonText="Sign in"
        navData={data}
        handleLogout={handleLogout}
      />
      {children}
    </ProtectedRoute>
  );
};

export default ProviderLayout;
