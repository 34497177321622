import { Outlet } from "react-router-dom";
import PartnerDashboard from "../../components/PartnerDashboard";
import PartnerScreens from "../../components/PartnerScreens";

const PartnerPages = () => {
  return (
    <PartnerDashboard>
      <Outlet />
      {/* <PartnerScreens /> */}
    </PartnerDashboard>
  );
};

export default PartnerPages;
