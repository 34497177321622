import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { Link } from "react-router-dom";

interface ISignUpCard {
  linkTo: string;
  title: string;
  subtitle: string;
  backgroundColor: string;
  src: any;
}

const SignUpCard = ({
  linkTo,
  title,
  subtitle,
  backgroundColor,
  src,
}: ISignUpCard) => {
  return (
    <Container>
      <Wrapper>
        <ImageWrapper>
          <img src={src} alt="" />
        </ImageWrapper>
        <LinkWrapper style={{ backgroundColor: backgroundColor }} to={linkTo}>
          <TitleWrapper>
            <ContentWrapper>
              <h4>{title}</h4>
              <small>{subtitle}</small>
            </ContentWrapper>
          </TitleWrapper>
        </LinkWrapper>
      </Wrapper>
    </Container>
  );
};

export default SignUpCard;

const Container = styled.div`
  display: flex;
  height: fit-content;
  background-color: ${colors.blueBack};
  border-radius: 0.8rem;
  width: 30%;
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;

  @media Screen and (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 10rem;
  width: 100%;
  position: relative;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 50%;
  height: 10rem;
  overflow: hidden;
  border-radius: 0.8rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 1;
`;

const ContentWrapper = styled.div`
  margin: 2rem 0.5rem 2rem 1rem;
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 0.4rem;
    font-weight: 600;
    color: ${colors.white};
  }

  small {
    line-height: 1.4;
    color: ${colors.white} !important;
  }
`;

const LinkWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 10rem;
  border-radius: 0.8rem;
  text-decoration: none;
  color: ${colors.white};
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
  position: absolute;
  z-index: 1;
  right: 0;
`;
