import React, { useState } from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { Link } from "react-router-dom";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";

interface IFooterItem {
  title: string;
  content: {
    id: number;
    heading: string;
    linkTo: string;
  }[];
}

const FooterElement = ({ title, content }: IFooterItem) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <ItemWrapper>
      <ItemContent>
        <ItemTitle>
          <p>{title}</p>
          {screens.minitab && (
            <IconWrapper onClick={toggleDropdown}>
              {isDropdownOpen ? (
                <RxCaretUp color={colors.white} />
              ) : (
                <RxCaretDown color={colors.white} />
              )}
            </IconWrapper>
          )}
        </ItemTitle>
        <DropdownItems isOpen={isDropdownOpen}>
          {content.map((c) => {
            return (
              <Item key={c.id} to={c.linkTo}>
                {c.heading}
              </Item>
            );
          })}
        </DropdownItems>
      </ItemContent>
    </ItemWrapper>
  );
};

export default FooterElement;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: ${screens.minitab}) {
    margin-bottom: 0;
  }
`;

const ItemContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ItemTitle = styled.h4`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.6rem;

  p {
    font-size: 0.9rem;
    color: ${colors.white};
    font-weight: 600;
  }

  @media (max-width: ${screens.minitab}) {
    padding: 1.5rem 0;
    border-bottom: 1px solid ${colors.white};
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  display: none;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${screens.minitab}) {
    display: flex;
  }
`;

const DropdownItems = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: ${screens.minitab}) {
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: ${(props) => (props.isOpen ? "fit-content" : "0")};
    gap: 0;
  }
`;

const Item = styled(Link)`
  font-size: 0.9rem;
  color: ${colors.white};
  font-weight: 400;
  text-decoration: none;

  @media (max-width: ${screens.minitab}) {
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }
`;
