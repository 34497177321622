import React, { FormEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import {
  useGetStudentByIdQuery,
  useUpdateStudentScoreMutation,
} from "../../../app/services/schoolApi";
import { toast } from "react-hot-toast";
import { CustomError } from "../../../types";
import { Button, Form, Input } from "../../FormElements";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import { styled } from "styled-components";

interface ISession {
  closeModal: () => void;
  student?: string;
  activity?: string | undefined;
}

const initialState = {
  session: "",
  score: 0,
  attendance: false,
};

const Session: React.FC<ISession> = ({ closeModal, activity, student }) => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const [formValue, setFormValue] = useState(initialState);
  const [updateStudentScore, { isLoading, isError, isSuccess, error }] =
    useUpdateStudentScoreMutation();

  const { data: studentData, isLoading: studentLoading } =
    useGetStudentByIdQuery({
      studentId: student,
      schoolId: schoolId,
    });

  const { session, score, attendance } = formValue;

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const isChecked = (e.target as HTMLInputElement).checked;
      setFormValue({ ...formValue, [name]: isChecked });
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (session && score && attendance && student && schoolId && activity) {
      await updateStudentScore({
        schoolId,
        studentId: student,
        activityId: activity,
        sessionData: { session, score, attendance },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Session added successfully");
      closeModal();
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  if (studentLoading) {
    return <Spinner color={colors.primary} />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormHeading>Add Session Data for {studentData?.fullName}</FormHeading>
      <InputWrapper>
        <label htmlFor="session">Session:</label>
        <Input
          type="text"
          name="session"
          value={session}
          placeholder="Session no e.g. 2"
          onChange={handleInputChange}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="score">Score:</label>
        <Input
          type="number"
          name="score"
          value={score}
          onChange={handleInputChange}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="attendance">
          Attendance:
          <input
            type="checkbox"
            name="attendance"
            checked={attendance}
            onChange={handleInputChange}
          />
        </label>
      </InputWrapper>
      <Button
        color={colors.white}
        type="submit"
        border="none"
        backgroundColor={colors.primary}
        text={isLoading ? <Spinner color={colors.white} /> : "Add Session"}
        disabled={isLoading}
      />
    </Form>
  );
};

export default Session;

const FormHeading = styled.h3`
  margin-bottom: 0.5rem;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;
  margin-bottom: 0.6rem;
`;
