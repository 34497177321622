import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  address: "",
  email: "",
  website: "",
  phoneNo: "",
  country: "",
  state: "",
  lga: "",
  token: null,
  id: null,
  logo: "",
};

export const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
    createSchool: (state, action) => {},

    setSchool: (state, action) => {
      localStorage.setItem(
        "school",
        JSON.stringify({
          id: action.payload.id,
          name: action.payload.name,
          address: action.payload.address,
          email: action.payload.email,
          website: action.payload.website,
          phoneNo: action.payload.phoneNo,
          country: action.payload.country,
          state: action.payload.state,
          lga: action.payload.lga,
          token: action.payload.token,
          logo: action.payload.logo,
        })
      );
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.address = action.payload.address;
      state.email = action.payload.email;
      state.website = action.payload.website;
      state.phoneNo = action.payload.phoneNo;
      state.country = action.payload.country;
      state.state = action.payload.state;
      state.lga = action.payload.lga;
      state.token = action.payload.token;
      state.logo = action.payload.logo;
    },

    logoutSchool: (state) => {
      localStorage.clear();
      state.name = "";
      state.address = "";
      state.id = null;
      state.email = "";
      state.website = "";
      state.phoneNo = "";
      state.country = "";
      state.state = "";
      state.lga = "";
      state.token = null;
      state.logo = "";
    },
  },
});

export const selectSchool = (state: any) => state.school;

export const { createSchool, setSchool, logoutSchool } =
  schoolSlice.actions;

export default schoolSlice.reducer;
