import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import { Button, Input } from "../../FormElements";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import { CustomError } from "../../../types";
import { useAddParentMutation } from "../../../app/services/schoolApi";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";

interface IAddParent {
  studentId: string | undefined;
  closeModal: () => void;
}

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
};

const AddParent = ({ studentId, closeModal }: IAddParent) => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const [formValue, setFormValue] = useState(initialState);
  const [addParent, { isLoading, isError, isSuccess, error }] =
    useAddParentMutation();

  const { firstName, lastName, email, phoneNo } = formValue;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (firstName && lastName && email && phoneNo) {
      await addParent({
        schoolId,
        studentId,
        parentData: { firstName, lastName, email, phoneNo },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Parent added successfully");
      closeModal();
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
    }
  }, [isSuccess, isError, error, closeModal]);

  return (
    <Container>
      <Wrapper>
        <h3>Add a parent for this student</h3>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            value={firstName}
            name="firstName"
            onChange={handleInputChange}
            placeholder="Parent first name"
          />
          <Input
            type="text"
            value={lastName}
            name="lastName"
            onChange={handleInputChange}
            placeholder="Parent last name"
          />
          <Input
            type="text"
            value={email}
            name="email"
            onChange={handleInputChange}
            placeholder="Parent email"
          />
          <Input
            type="text"
            value={phoneNo}
            name="phoneNo"
            onChange={handleInputChange}
            placeholder="Parent phone number"
          />
          <Button
            color={colors.white}
            type="submit"
            border="none"
            backgroundColor={colors.primary}
            text={isLoading ? <Spinner color={colors.white} /> : "Add Parent"}
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default AddParent;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.6rem;
  margin: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
