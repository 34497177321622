import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const url = process.env.REACT_APP_BASE_URI as string;

export const parentApi = createApi({
  reducerPath: "parentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}parents/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.parent.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Parent"],
  endpoints: (builder) => ({
    // Generate Password Reset Code
    passwordCode: builder.mutation({
      query: (email) => ({
        url: "/password-code",
        method: "POST",
        body: email,
      }),
    }),

    // Update Password with Reset Code
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/password-reset",
        method: "POST",
        body: data,
      }),
    }),

    // Login a Parent
    signInParent: builder.mutation({
      query: (data) => {
        return {
          url: "parent-signin/",
          method: "POST",
          body: data,
        };
      },
    }),

    // Create a new Partner
    signupParent: builder.mutation({
      query: (data) => {
        return {
          url: "parent-signup",
          method: "POST",
          body: data,
        };
      },
    }),

    // ...
  }),
});

export const {
  usePasswordCodeMutation,
  useUpdatePasswordMutation,
  useSignInParentMutation,
  useSignupParentMutation,
} = parentApi;
