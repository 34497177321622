import React from "react";
import { styled } from "styled-components";

const RentAGadget = () => {
  return (
    <Container>
      <Wrapper>
        <h3>This is where schools can rent gadgets</h3>
      </Wrapper>
    </Container>
  );
};

export default RentAGadget;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
  margin: 2rem auto;
`;
