import React, { useState } from "react";
import { styled } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { selectUniversity } from "../../app/features/universitySlice";

const UniversityScreens = () => {
  const { universityName } =
    useAppSelector(selectUniversity);

  return (
    <Container>
      <Wrapper>
        <h1>Welcome to the dashboard, {universityName}</h1>
      </Wrapper>
    </Container>
  );
};

export default UniversityScreens;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  flex-direction: column;
  gap: 1rem;
`;
