import React from "react";
import { colors, screens } from "../../../../utils";
import { styled } from "styled-components";
import src from "../../../../assets/images/partners.jpeg";
import { Link } from "react-router-dom";

const ForProviders = () => {
  return (
    <Container>
      <Wrapper>
        <ImageWrapper>
          <img src={src} alt="" />
        </ImageWrapper>
        <ContentWrapper>
          <Title>For providers</Title>
          <Heading>Find schools and students ready to use your service</Heading>
          <Body>
            Meet schools who are excited to work with you and take your business
            to new heights.
          </Body>
          <HR />
          <TextWrapper>
            <Text>
              <p>Free access to enterprise tools to manage your work</p>
            </Text>
            <Text>
              <p>Find great opportunities wherever you are</p>
            </Text>
            <Text>
              <p>Earn with ease and cash out without stress</p>
            </Text>
          </TextWrapper>
          <LearnMore to="#">
            <p>Learn more</p>
          </LearnMore>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default ForProviders;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  background-color: ${colors.bluePry};
  border-radius: 1.2rem;

  @media (max-width: ${screens.minitab}) {
    flex-direction: column;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 2rem;

  @media (max-width: ${screens.minitab}) {
    margin: 2rem 1rem;
    width: 90%;
  }
`;

const Title = styled.h2`
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  color: ${colors.white};
  margin-bottom: 2rem;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  color: ${colors.white};
  font-weight: 600;
  display: flex;
  width: 100%;
`;

const Body = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3;
  color: ${colors.white};
  margin: 2.5rem 0;
`;

const HR = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.white};
  margin-bottom: 1.5rem;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1.5rem 0;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Text = styled.div`
  display: flex;
  width: 30%;

  p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.3;
    color: ${colors.white};
  }
`;

const LearnMore = styled(Link)`
  display: flex;
  text-decoration: none;
  border-radius: 0.5rem;
  background-color: ${colors.white};
  width: fit-content;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.3;
    color: ${colors.bluePry};
    margin: 0.6rem 1.4rem;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 40%;
  overflow: hidden;
  border-radius: 1.2rem 0 0 1.2rem;

  @media (max-width: ${screens.minitab}) {
    border-radius: 1.2rem 1.2rem 0 0;
    height: 40vh;
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    margin-left: auto;
  }
`;
