import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { PiTagChevronThin } from "react-icons/pi";
import { MdOutlineClass, MdOutlinePersonOutline, MdEdit } from "react-icons/md";
import { colors, screens } from "../../../utils";

interface IStudent {
  linkTo: string;
  fullName: string;
  studentClass: string;
  gender: string;
  studentId?: string;
}

const Student = ({
  linkTo,
  fullName,
  studentClass,
  gender,
  studentId,
}: IStudent) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`${studentId}/edit`);
  };

  return (
    <Container>
      <Wrapper to={linkTo}>
        <FullName>
          <PiTagChevronThin size="0.8rem" color={colors.dark} />
          <p>{fullName}</p>
        </FullName>
        <GenderWrapper>
          <IconWrapper>
            <MdOutlinePersonOutline size="0.9rem" color={colors.dark} />
          </IconWrapper>
          <Gender>{gender}</Gender>
        </GenderWrapper>
        <StudentClassWrapper>
          <IconWrapper>
            <MdOutlineClass size="0.9rem" color={colors.dark} />
          </IconWrapper>
          <Gender>{studentClass}</Gender>
        </StudentClassWrapper>
      </Wrapper>
      <EditButton onClick={handleEditClick}>
        <MdEdit size="1rem" color={colors.primary} />
        <EditText>Edit</EditText>
      </EditButton>
    </Container>
  );
};

export default Student;

const Container = styled.div`
  display: flex;
  align-items: center;
  border-top: 0.5px solid ${colors.grey};
  border-bottom: 0.5px solid ${colors.grey};
  gap: 0.5rem;

  &:hover {
    border: 0.5px solid ${colors.grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const Wrapper = styled(Link)`
  display: flex;
  width: 100%;
  margin: 0.5rem;
  gap: 1rem;
  align-items: center; /* Ensure items are centered */
  text-decoration: none;
`;

const FullName = styled.div`
  display: flex;
  width: 40%; /* Adjust width to make room for Edit button */
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    margin: auto 1rem auto 0;
    display: flex;
    width: 100%;
    text-transform: capitalize;
    word-wrap: break-word;

    @media (max-width: ${screens.minitab}) {
      width: 80%;
      margin: auto;
    }
  }
`;

const GenderWrapper = styled.div`
  display: flex;
  width: fit-content;
  gap: 0.5rem;
  align-items: center;

  @media (max-width: ${screens.minitab}) {
    display: none;
  }
`;

const Gender = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.dark};
  display: flex;
  width: 100%;
  text-transform: capitalize;

  strong {
    font-size: 0.8rem;
    font-weight: 700;
    color: ${colors.dark};
    display: flex;
    margin-right: 0.3rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const StudentClassWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin-left: auto;
  gap: 0.4rem;
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.2rem;
  margin-left: 1rem;
`;

const EditText = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.primary};
`;
