import React from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";
import { useGetLearningByIdQuery } from "../../app/services/learningApi";
import Spinner from "../../utils/Spinner";
import NoData from "../../utils/NoData";

const LearningDetails = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess, isError } = useGetLearningByIdQuery(id);

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Oops! Something went wrong. Try again</p>
        ) : !data ? (
          <NoData iconColor={colors.grey} textColor={colors.grey} />
        ) : (
          <ContentWrapper>
            <VideoWrapper>
              <IFrameWrapper>
                <IFrame
                  src={`https://www.youtube.com/embed/${data.url}`}
                  title={data.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </IFrameWrapper>

              <VideoText>
                <h4>{data.title}</h4>
                <p>{data.subtitle}</p>
              </VideoText>
            </VideoWrapper>
            <DescriptionWrapper>
              <h4>Description</h4>
              <p>{data.description}</p>
            </DescriptionWrapper>
          </ContentWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

export default LearningDetails;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  padding-right: 2rem;
  border-right: 0.5px solid ${colors.primary};

  @media Screen and (max-width: ${screens.minitab}) {
    width: 100%;
    padding-right: 0;
    border-right: none;
  }
`;

const IFrameWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
`;

const IFrame = styled.iframe`
  border: none;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoText = styled.div`
  margin: 1rem 0;
  padding-bottom: 2rem;
  border-bottom: 0.2px solid ${colors.grey};

  h4 {
    color: ${colors.black};
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
    line-height: 1.6;
  }

  p {
    color: ${colors.black};
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.4;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 1rem 0 1rem 1rem;

  h4 {
    color: ${colors.black};
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
    line-height: 1.6;
  }

  p {
    color: ${colors.black};
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.4;
  }

  @media (max-width: ${screens.tab}) {
    width: 100%;
    margin: 0;
  }
`;
