import React, { useState } from "react";
import {
  useGetAllStudentsQuery,
  useAddStudentToActivityMutation,
  useGetOneParentQuery,
} from "../../../app/services/schoolApi";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Spinner from "../../../utils/Spinner";
import { colors } from "../../../utils";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import Select from "react-select";

type Student = {
  _id: string;
  fullName: string;
  studentClass: string;
};

type Parent = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  students: Student[];
};

const SingleParent = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: parentId } = useParams<{ id: string }>();
  const {
    data: parentData,
    isLoading: parentLoading,
    isError: parentError,
    refetch: refetchParent,
  } = useGetOneParentQuery(parentId);
  const { data: studentsData, isLoading: studentsLoading } =
    useGetAllStudentsQuery(schoolId);
  const [addStudentToActivity] = useAddStudentToActivityMutation();
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);

  const handleAddStudent = async () => {
    if (selectedStudent) {
      await addStudentToActivity({
        schoolId,
        studentId: selectedStudent._id,
      });
      refetchParent();
      setSelectedStudent(null);
    }
  };

  const studentOptions =
    studentsData?.map((student: Student) => ({
      value: student._id,
      label: `${student.fullName}`,
    })) || [];

  return (
    <Container>
      <Wrapper>
        {parentLoading || studentsLoading ? (
          <Spinner color={colors.grey} />
        ) : parentError ? (
          <p>Something went wrong!</p>
        ) : parentData ? (
          <ContentWrapper>
            <p>
              Name: {parentData.firstName} {parentData.lastName}
            </p>
            <p>Email: {parentData.email}</p>
            <p>Phone No: {parentData.phoneNo}</p>
            <div>
              <h4>Students associated with this parent</h4>
              {parentData?.wards.map((ward: Student) => (
                <div key={ward._id}>
                  <p>{ward.fullName}</p>
                  <small>{ward.studentClass}</small>
                </div>
              ))}
            </div>
            {/* <Select
              options={studentOptions}
              value={selectedStudent}
              onChange={(value: Student | null) => setSelectedStudent(value)}
              placeholder="Select a student"
            />
            <Button onClick={handleAddStudent} disabled={!selectedStudent}>
              Add Student to to parent
            </Button> */}
          </ContentWrapper>
        ) : (
          <p>No student data found!</p>
        )}
      </Wrapper>
    </Container>
  );
};

export default SingleParent;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${colors.primary};
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primary};
  }
  &:disabled {
    background-color: ${colors.grey};
    cursor: not-allowed;
  }
`;
