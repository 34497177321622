import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const url = process.env.REACT_APP_BASE_URI as string;

export const universityApi = createApi({
  reducerPath: "universityApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}universities/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.university.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Provider"],
  endpoints: (builder) => ({
    // Generate Password Reset Code
    passwordCode: builder.mutation({
      query: (email) => ({
        url: "/password-code",
        method: "POST",
        body: email,
      }),
    }),

    // Update Password with Reset Code
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/password-reset",
        method: "POST",
        body: data,
      }),
    }),

    // Login a Provider
    signInUniversity: builder.mutation({
      query: (data) => {
        return {
          url: "university-signin/",
          method: "POST",
          body: data,
        };
      },
    }),

    // ...
  }),
});

export const {
  usePasswordCodeMutation,
  useUpdatePasswordMutation,
  useSignInUniversityMutation,
} = universityApi;
