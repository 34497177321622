import React from "react";
import { styled } from "styled-components";
import { colors } from "../../../../utils";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

interface ICard {
  to: string;
  title: string;
  action: string;
}

const Card = ({ to, title, action }: ICard) => {
  return (
    <Container to={to}>
      <Wrapper>
        <h2>{title}</h2>
        <TextWrapper>
          <p>{action}</p>
          <FaArrowRightStyled size="0.8rem" />
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};

export default Card;

const Container = styled(Link)`
  display: flex;
  border-radius: 0.25rem;
  background-color: ${colors.primary};
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: ${colors.white};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem;
  flex-direction: column;
  gap: 0.7rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${colors.white};
    transition: color 0.2s ease-in-out;
  }

  ${Container}:hover & h2 {
    color: ${colors.primary};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 1.3rem;
  align-items: center;

  p {
    font-size: 0.9rem;
    color: ${colors.white};
    font-weight: 500;
    transition: color 0.2s ease-in-out;
  }

  ${Container}:hover & p {
    color: ${colors.primary};
  }
`;

const FaArrowRightStyled = styled(FaArrowRight)`
  color: ${colors.white};
  transition: color 0.2s ease-in-out;

  ${Container}:hover & {
    color: ${colors.primary};
  }
`;
