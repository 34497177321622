import React, { FormEvent, useEffect, useState } from "react";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { Button, Form, Input, Label } from "../../FormElements";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import Select from "../../FormElements/Select";
import { CustomError } from "../../../types";
import {
  useEditStudentMutation,
  useGetStudentByIdQuery,
} from "../../../app/services/schoolApi";
import { uploadImage } from "../../../lib/firebase-client";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";

const genderData = [
  {
    optionText: "Male",
    optionValue: "male",
  },
  {
    optionText: "Female",
    optionValue: "female",
  },
];

const classData = [
  {
    optionText: "JSS 1",
    optionValue: "jss1",
  },
  {
    optionText: "JSS 2",
    optionValue: "jss2",
  },
  {
    optionText: "JSS 3",
    optionValue: "jss3",
  },
  {
    optionText: "SSS 1",
    optionValue: "sss1",
  },
  {
    optionText: "SSS 2",
    optionValue: "sss2",
  },
  {
    optionText: "SSS 3",
    optionValue: "sss3",
  },
];

const EditStudent = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: studentId } = useParams<{ id: string }>();
  const { data: studentData } = useGetStudentByIdQuery({ studentId, schoolId });
  const [formValue, setFormValue] = useState({
    fullName: "",
    gender: "",
    studentClass: "",
    image: null as File | null,
  });
  const [editStudent, { isLoading, isSuccess, isError, error }] =
    useEditStudentMutation();

  const { fullName, gender, studentClass, image } = formValue;
  console.log(schoolId, studentId);

  useEffect(() => {
    if (studentData) {
      setFormValue({
        fullName: studentData.fullName || "",
        gender: studentData.gender || "",
        studentClass: studentData.studentClass || "",
        image: null,
      });
    }
  }, [studentData]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (e.target instanceof HTMLInputElement && e.target.files) {
      setFormValue({ ...formValue, [name]: e.target.files[0] });
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (fullName && gender && studentClass) {
      let imageUrl = studentData?.avatar || "";

      if (image) {
        try {
          // Upload image and get the URL
          imageUrl = await uploadImage(image, `students/${studentId}`);
        } catch (uploadError) {
          toast.error("Failed to upload image. Please try again.");
          return;
        }
      }

      await editStudent({
        studentId,
        schoolId,
        studentData: { fullName, gender, studentClass, avatar: imageUrl },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Student updated successfully");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Edit Student</h3>
        <Form onSubmit={handleSubmit}>
          <Label text="Student's full name" />
          <Input
            type="text"
            value={fullName}
            name="fullName"
            onChange={handleInputChange}
          />

          <ItemWrapper>
            <Label
              text="Student's gender"
              fontSize="1rem"
              color={colors.dark}
            />
            <Select
              value={gender}
              name="gender"
              defaultOption="Select gender"
              onChange={handleInputChange}
              options={genderData}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Student's class" fontSize="1rem" color={colors.dark} />
            <Select
              value={studentClass}
              name="studentClass"
              defaultOption="Select class"
              onChange={handleInputChange}
              options={classData}
            />
          </ItemWrapper>

          <ItemWrapper>
            {studentData?.avatar ? (
              <AvatarWrapper>
                <img src={studentData.avatar} alt="" />
              </AvatarWrapper>
            ) : (
              <p>No avatar uploaded yet</p>
            )}
            <Label text="Student's image" fontSize="1rem" color={colors.dark} />
            <Input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <Button
            color={colors.white}
            type="submit"
            border="none"
            backgroundColor={colors.primary}
            text={isLoading ? <Spinner color={colors.white} /> : "Save Changes"}
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default EditStudent;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 0.6rem;
  margin: 1rem auto;

  @media (max-width: ${screens.tab}) {
    width: 80%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const AvatarWrapper = styled.div`
  display: flex;
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  border-radius: 0.4rem;
  border: 1px solid ${colors.primary};

  img {
    object-fit: contain;
  }
`;
