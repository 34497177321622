import React from 'react'
import HomeLayout from '../../layouts/HomeLayout'
import Admin from '../../components/Authentication/Admin'

const AdminAuth = () => {
  return (
    <HomeLayout>
        <Admin />
    </HomeLayout>
  )
}

export default AdminAuth