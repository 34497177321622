import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import logo from "../../assets/images/logo.png";

const Logo = () => {
  return (
    <Container to="/">
      <Wrapper>
        <img src={logo} alt="" />
      </Wrapper>
    </Container>
  );
};

export default Logo;

const Container = styled(Link)`
  text-decoration: none;
  height: 2rem;
  display: flex;
  margin: auto 0;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;
