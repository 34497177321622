import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteUniversityByIdMutation,
  useGetUniversityByIdQuery,
} from "../../../app/services/adminApi";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { IoTrashOutline } from "react-icons/io5";
import { toast } from "react-hot-toast";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail, MdLocalPhone, MdPerson } from "react-icons/md";
import Spinner from "../../../utils/Spinner";
import NoData from "../../../utils/NoData";
import { useAppSelector } from "../../../app/hooks";
import { selectAdmin } from "../../../app/features/adminSlice";
import AssignAdminToUniversity from "./AssignAdminToUniversity";

interface IContact {
  icon: any;
  heading: string;
  details: string;
}

const SingleUniversityAdmin = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { tag } = useAppSelector(selectAdmin);
  const { data, isLoading, isError, isSuccess } = useGetUniversityByIdQuery(id);
  const [del, { isSuccess: delIsSuccess, isLoading: delIsLoading }] =
    useDeleteUniversityByIdMutation();

  const handleDelete = async () => {
    await del(id);
  };

  useEffect(() => {
    if (delIsSuccess) {
      toast.success("University deleted successfully");
      navigate("/admin-dashboard");
    }
  }, [delIsSuccess]);

  const Contact = ({ icon, heading, details }: IContact) => {
    return (
      <ContactShell>
        <ContactIcon>{icon}</ContactIcon>
        <ContactText>
          <h4>{heading}:</h4>
          <p>{details}</p>
        </ContactText>
      </ContactShell>
    );
  };

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Error: Something went wrong!!!</p>
        ) : !data ? (
          <NoData iconColor={colors.grey} textColor={colors.dark} />
        ) : data ? (
          <UserWrapper>
            <ProfileHeading>
              <ImageWrapper>
                {data && data.avatar ? (
                  <img src={data.avatar} alt="" />
                ) : (
                  <h1>{data && data.universityName.slice(0, 1)}</h1>
                )}
              </ImageWrapper>
              <HeadingWrapper>
                <h3>{data && data.universityName}</h3>

                {data && data.assignedAdmin ? (
                  <p>
                    <span>Assigned Admin: </span> {data.assignedAdmin.firstName}{" "}
                    {data.assignedAdmin.lastName}
                  </p>
                ) : (
                  <p>
                    <span>Assigned Admin: </span> No Admin assigned
                  </p>
                )}

                {id && <AssignAdminToUniversity universityId={id} />}

                {tag === "ceo" || tag === "manager" ? (
                  <DeleteWrapper>
                    <h3>Delete this University</h3>
                    <Delete onClick={handleDelete}>
                      {delIsLoading ? (
                        <Spinner color={colors.white} />
                      ) : (
                        <IoTrashOutline color={colors.white} />
                      )}
                    </Delete>
                  </DeleteWrapper>
                ) : null}
              </HeadingWrapper>
            </ProfileHeading>
            <ContactDetails>
              <h3>UNIVERSITY INFORMATION</h3>
              <ContactWrapper>
                <Contact
                  heading="University Representative"
                  details={data && `${data.firstName} ${data.lastName}`}
                  icon={<MdPerson />}
                />
              </ContactWrapper>
              <ContactWrapper>
                <Contact
                  heading="Email"
                  details={data && data.email}
                  icon={<MdEmail />}
                />
                <Contact
                  heading="Phone No"
                  details={data && data.phoneNo}
                  icon={<MdLocalPhone />}
                />
              </ContactWrapper>

              <ContactWrapper>
                <Contact
                  heading="Address"
                  details={data && data.universityAddress}
                  icon={<FaLocationDot />}
                />
              </ContactWrapper>
            </ContactDetails>
          </UserWrapper>
        ) : null}
      </Wrapper>
    </Container>
  );
};

export default SingleUniversityAdmin;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem auto;
  flex-direction: column;

  @media (max-width: ${screens.tab}) {
    margin-top: 0;
  }
`;

const ProfileHeading = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.5px solid ${colors.grey};
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 6rem;
  height: 6rem;
  border-radius: 6rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: ${colors.blueBack};

  h1 {
    font-size: 3rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.7rem;

  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    color: ${colors.dark};
  }

  small {
    font-size: 0.6rem;
    color: ${colors.dark};
    font-weight: 400;
  }

  p {
    font-size: 0.8rem;
    color: ${colors.dark};
    font-weight: 400;
  }

  span {
    font-size: 0.8rem;
    color: ${colors.dark};
    font-weight: 600;
    margin-right: 0.5rem;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 1rem;

  h3 {
    font-size: 0.9rem;
    font-weight: 700;
    color: ${colors.black};
  }
`;

const FormWrapper = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const ContactShell = styled.div`
  display: flex;
  border-radius: 0.25rem;
  border: 0.5px solid ${colors.primary};
  padding: 0.5rem;
  width: 100%;
  gap: 1rem;
`;

const ContactIcon = styled.div`
  color: ${colors.primary};
`;

const ContactText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;

  h4 {
    font-size: 0.8rem;
    font-weight: 700;
    color: ${colors.black};
  }

  p {
    font-size: 0.8rem;
    font-weight: 400;
    color: ${colors.dark};
    text-transform: capitalize;
  }
`;

const DeleteWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;

  h3 {
    font-size: 0.7rem;
    font-weight: 400;
    color: ${colors.dark};
    width: fit-content;
  }
`;

const Delete = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.6rem;
  border-radius: 0.2rem;
  background-color: ${colors.red};
  cursor: pointer;
`;
