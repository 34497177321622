import React, { FormEvent, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Button, Form, Input, Label } from "../FormElements";
import { colors, screens } from "../../utils";
import Spinner from "../../utils/Spinner";
import { useAddProviderMutation } from "../../app/services/partnerApi";
import { toast } from "react-hot-toast";
import { useAppSelector } from "../../app/hooks";
import { selectPartner } from "../../app/features/partnerSlice";
import { CustomError } from "../../types";

interface IAddProvider {
  closeModal: () => void;
}

const initialState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNo: "",
  businessName: "",
  businessAddress: "",
};

const AddProvider = ({ closeModal }: IAddProvider) => {
  const { id } = useAppSelector(selectPartner);
  const [formValue, setFormValue] = useState(initialState);
  const [addProvider, { isLoading, data, isSuccess, isError, error }] =
    useAddProviderMutation();

  const {
    firstName,
    lastName,
    emailAddress,
    phoneNo,
    businessName,
    businessAddress,
  } = formValue;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      firstName &&
      lastName &&
      emailAddress &&
      phoneNo &&
      businessName &&
      businessAddress
    ) {
      await addProvider({
        providerData: {
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
          phoneNo: phoneNo,
          businessName: businessName,
          businessAddress: businessAddress,
        },
        partnerId: id,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Provider added successfully");
      closeModal();
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Add a new Provider</h3>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label text="Provider's first name" />
            <Input
              type="text"
              value={firstName}
              name="firstName"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Provider's last name" />
            <Input
              type="text"
              value={lastName}
              name="lastName"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Provider's email" />
            <Input
              type="email"
              value={emailAddress}
              name="emailAddress"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Provider's phone no" />
            <Input
              type="text"
              value={phoneNo}
              name="phoneNo"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Provider's business name" />
            <Input
              type="text"
              value={businessName}
              name="businessName"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Provider's business address" />
            <Input
              type="text"
              value={businessAddress}
              name="businessAddress"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ButtonWrapper>
            <Button
              color={colors.white}
              type="submit"
              border="none"
              backgroundColor={colors.primary}
              text={
                isLoading ? <Spinner color={colors.white} /> : "Add Provider"
              }
              disabled={isLoading}
            />
          </ButtonWrapper>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default AddProvider;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  gap: 1rem;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0.5rem auto 0;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  
  :last-child {
    margin-bottom: 0;
  }
`;
