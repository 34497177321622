import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectPartner } from "../../app/features/partnerSlice";
import {
  useGetProvidersQuery,
  useGetSchoolsQuery,
  useGetUniversitiesQuery,
} from "../../app/services/partnerApi";
import { styled } from "styled-components";
import PartnerCard from "./PartnerCard";
import Spinner from "../../utils/Spinner";
import { colors, screens } from "../../utils";

const PartnersStats = () => {
  const { id } = useAppSelector(selectPartner);
  const { data: providersData, isLoading: providerIsLoading } =
    useGetProvidersQuery(id);
  const { data: schoolData, isLoading: schoolIsLoading } =
    useGetSchoolsQuery(id);
  const { data: universityData, isLoading: universityIsLoading } =
    useGetUniversitiesQuery(id);

  return (
    <Container>
      <Wrapper>
        <PartnerCard
          to="/partner-dashboard/providers"
          title="Providers List"
          count={
            providerIsLoading ? (
              <Spinner color={colors.grey} />
            ) : (
              providersData && providersData.length
            )
          }
        />

        <PartnerCard
          to="/partner-dashboard/schools"
          title="Schools List"
          count={
            schoolIsLoading ? (
              <Spinner color={colors.grey} />
            ) : (
              schoolData && schoolData.numberOfSchools
            )
          }
        />

        <PartnerCard
          to="/partner-dashboard/schools"
          title="Total Students"
          count={
            schoolIsLoading ? (
              <Spinner color={colors.grey} />
            ) : (
              schoolData && schoolData.totalStudents
            )
          }
        />

        <PartnerCard
          to="/partner-dashboard/universities"
          title="Universities List"
          count={
            universityIsLoading ? (
              <Spinner color={colors.grey} />
            ) : (
              universityData && universityData.length
            )
          }
        />
      </Wrapper>
    </Container>
  );
};

export default PartnersStats;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;

  @media Screen and (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;
