export const colors = {
  dark: "#444746",
  black: "#1f1f1f",
  bluePry: "#2667D5",
  blueSec: "#C2E7FF",
  lightTer: "#EAEFF8",
  blueLight: "#F8FBFF",
  blueBack: "#EBF2FE",
  white: "#FFFFFF",
  offWhite: "#FDFCFD",
  grey: "#C7C7C7",
  greyText: "#0f0f0f",
  lightBack: "#F8F8F8",
  red: "#FF0100",
  green: "#27A65B",
  orange: "#EC6236",
  secondary: "#0028E9",
  //primary: "#082552",
  primary: "#000080",
  lightOrange: "#FBF7F1",

};
