import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNo: "",
  businessName: "",
  businessAddress: "",
  country: "",
  state: "",
  lga: "",
  token: null,
  id: null,
};

export const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    createProvider: (state, action) => {},

    setProvider: (state, action) => {
      localStorage.setItem(
        "provider",
        JSON.stringify({
          id: action.payload.id,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          emailAddress: action.payload.emailAddress,
          phoneNo: action.payload.phoneNo,
          businessName: action.payload.businessName,
          businessAddress: action.payload.businessAddress,
          country: action.payload.country,
          state: action.payload.state,
          lga: action.payload.lga,
          token: action.payload.token,
        })
      );
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.emailAddress = action.payload.emailAddress;
      state.phoneNo = action.payload.phoneNo;
      state.businessName = action.payload.businessName;
      state.businessAddress = action.payload.businessAddress;
      state.country = action.payload.country;
      state.state = action.payload.state;
      state.lga = action.payload.lga;
      state.token = action.payload.token;
    },

    logoutProvider: (state) => {
      localStorage.clear();
      state.firstName = "";
      state.lastName = "";
      state.id = null;
      state.emailAddress = "";
      state.phoneNo = "";
      state.businessName = "";
      state.businessAddress = "";
      state.country = "";
      state.state = "";
      state.lga = "";
      state.token = null;
    },
  },
});

export const selectProvider = (state: any) => state.provider;

export const { createProvider, setProvider, logoutProvider } =
  providerSlice.actions;

export default providerSlice.reducer;
