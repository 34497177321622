import React from "react";
import { useGetAssignedAdminByIdQuery } from "../../../app/services/partnerApi";
import { useAppSelector } from "../../../app/hooks";
import { selectPartner } from "../../../app/features/partnerSlice";
import { styled } from "styled-components";
import Spinner from "../../../utils/Spinner";
import { colors, screens } from "../../../utils";
import NoData from "../../../utils/NoData";

const GetAdminAttachedToPartner = () => {
  const { id } = useAppSelector(selectPartner);
  const { data, isLoading, isError, error } = useGetAssignedAdminByIdQuery(id);
  return (
    <Container>
      <Wrapper>
        <h2>Your Company Representative</h2>
        <small>
          This is your Liaison and Contact point with ExtraBox. They will help
          you with all you need.
        </small>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>There was an error</p>
        ) : !data ? (
          <p>A company representative has not been assigned</p>
        ) : data ? (
          <ContentWrapper>
            <Content>
              <Title>Name: </Title>
              <Text>
                {data.firstName} {data.lastName}
              </Text>
            </Content>
            <Content>
              <Title>Email: </Title>
              <Text>{data.email}</Text>
            </Content>
            <Content>
              <Title>Phone No: </Title>
              <Text>{data.phoneNo}</Text>
            </Content>
          </ContentWrapper>
        ) : (
          <NoData textColor={colors.dark} iconColor={colors.grey} />
        )}
      </Wrapper>
    </Container>
  );
};

export default GetAdminAttachedToPartner;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-left: 1rem;

  @media (max-width: ${screens.tab}) {
    margin-left: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem auto;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-size: 0.9rem;
    color: ${colors.dark};
  }

  small {
    font-size: 0.7rem;
    font-weight: 400;
    color: ${colors.dark};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.blueSec};
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 0.6rem;
`;

const Content = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
`;

const Title = styled.h4`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${colors.dark};
`;

const Text = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  color: ${colors.dark};
`;
