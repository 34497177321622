import { Outlet } from "react-router-dom";
import ParentLayout from "../../layouts/ParentLayout";

const ParentPages = () => {
  return (
    <ParentLayout>
      <Outlet />
    </ParentLayout>
  );
};

export default ParentPages;
