import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors, screens } from "../../../utils";
import Logo from "../../Logo";
import { Button } from "../../FormElements";
import TopBar from "./TopBar";

interface IHamburger {
  open: boolean;
}

interface INavItems {
  open: boolean;
}

interface INav {
  id: number;
  title: string;
  linkTo: string;
}

interface INavigation {
  token?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  navData: INav[];
  clickLink?: string;
  buttonText: string;
  handleLogout: () => void;
}

const Navigation = ({
  token,
  firstName,
  lastName,
  title,
  navData,
  clickLink,
  buttonText,
  handleLogout,
}: INavigation) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate(`/${clickLink}`);
  };

  const activeTabClass = (linkTo: string) => {
    return location.pathname.includes(linkTo) ? "active" : "";
  };

  return (
    <Container>
      <Wrapper>
        <Logo />
        <NavContainer open={open}>
          <NavItemsWrapper>
            {navData &&
              navData.map((navItem) => (
                <NavItem
                  key={navItem.id}
                  to={`/${navItem.linkTo}`}
                  className={activeTabClass(navItem.linkTo)}
                >
                  {navItem.title}
                </NavItem>
              ))}
          </NavItemsWrapper>
          {token ? (
            <TopBar
              firstName={firstName || ""}
              lastName={lastName || ""}
              title={title || ""}
              handleLogout={handleLogout}
              linkTo={clickLink || ""}
            />
          ) : (
            <ButtonWrapper>
              <Button
                onClick={handleClick}
                text={buttonText}
                color={colors.white}
                backgroundColor={colors.primary}
                border="none"
              />
            </ButtonWrapper>
          )}
        </NavContainer>
        <Wraps>
          {token ? (
            <TopBar
              firstName={firstName || ""}
              lastName={lastName || ""}
              title={title || ""}
              handleLogout={handleLogout}
              linkTo={clickLink || ""}
            />
          ) : (
            <MobileButtonWrapper>
              <Button
                onClick={handleClick}
                text={buttonText}
                color={colors.white}
                backgroundColor={colors.primary}
                border="none"
              />
            </MobileButtonWrapper>
          )}
          <Hamburger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </Hamburger>
        </Wraps>
      </Wrapper>
    </Container>
  );
};

export default Navigation;

const Container = styled.nav`
  display: flex;
  height: fit-content;
  width: 100%;
  background-color: ${colors.offWhite};
`;

const Wrapper = styled.div`
  display: flex;
  width: 95%;
  margin: 0.8rem auto;
  justify-content: space-between;
  align-items: center;
`;

const NavContainer = styled.div<INavItems>`
  display: flex;
  width: fit-content;
  margin: 0;
  gap: 1rem;

  @media Screen and (max-width: ${screens.tab}) {
    flex-flow: column nowrap;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 60vw;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    opacity: ${({ open }) => (open ? 1 : 0)};
    transition: all 0.3s linear;
    background-color: ${colors.blueLight};
    padding: 4rem 2rem;
    z-index: 999;
  }
`;

const NavItemsWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 3rem 0 0;
  gap: 1rem;

  @media Screen and (max-width: ${screens.tab}) {
    flex-flow: column nowrap;
    gap: 1rem; 
    margin: 0;
  }
`;

const NavItem = styled(Link)`
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  color: ${colors.dark};
  display: flex;
  margin: auto 0;
  padding: 0;

  &.active {
    color: ${colors.white};
    font-weight: 700;
    background-color: ${colors.primary};
    padding: 0.2rem;
    border-radius: 0.25rem;
  }

  @media Screen and (max-width: ${screens.tab}) {
    width: fit-content;
    color: ${colors.primary};
  }
`;

const Wraps = styled.div`
  display: none;

  @media Screen and (max-width: ${screens.tab}) {
    display: flex;
    gap: 0.5rem;
  }
`;

const Hamburger = styled.div<IHamburger>`
  width: 1.5rem;
  height: 1.5rem;
  display: none;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;
  margin: auto 0;
  z-index: 1000;

  @media Screen and (max-width: ${screens.tab}) {
    display: flex;
    position: ${({ open }) => (open ? "fixed" : "")};
    right: 4.9%;
    top: 2.2%;
  }

  @media Screen and (max-width: ${screens.minitab}) {
    right: 4.8%;
    top: 2.8%;
  }

  @media Screen and (max-width: ${screens.microtab}) {
    right: 5%;
    top: 3.8%;
  }

  div {
    width: 100%;
    height: 0.2rem;
    background-color: ${colors.black};
    margin-bottom: 0.3rem;
    border-radius: 0.25rem;
    transform-origin: 1px;
    transition: all 0.3s linear;

    @media Screen and (max-width: ${screens.tab}) {
      height: 0.13rem;
      margin-bottom: 0;
    }

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0deg)")};
    }

    &:nth-child(2) {
      visibility: ${({ open }) => (open ? "hidden" : "inherit")};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0deg)")};
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: auto 0;
  padding: 0;

  @media Screen and (max-width: ${screens.tab}) {
    display: none;
  }
`;

const MobileButtonWrapper = styled.div`
  display: none;

  @media Screen and (max-width: ${screens.tab}) {
    display: flex;
    align-items: center;
  }
`;
