
import { Outlet } from "react-router-dom";
import SchoolLayout from "../../layouts/SchoolLayout";

const SchoolPages = () => {
  return (
    <SchoolLayout>
      <Outlet />
    </SchoolLayout>
  );
};

export default SchoolPages;
