import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import GeneratePasswordResetCode from "../../common/PasswordReset/GeneratePasswordResetCode";
import { usePasswordCodeMutation } from "../../../app/services/adminApi";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../../../types";
import { setAdmin } from "../../../app/features/adminSlice";
import { useAppDispatch } from "../../../app/hooks";

const AdminPasswordCode = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [generateCode, { data, isLoading, isSuccess, isError, error }] =
    usePasswordCodeMutation();

  useEffect(() => {
    if (isSuccess && data) {
      toast.success("Code sent to your email");
      dispatch(
        setAdmin({
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNo: data.phoneNo,
          tag: data.tag,
          token: data.token,
        })
      );
      navigate("/admin-password-reset");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess]);

  return (
    <Container>
      <GeneratePasswordResetCode
        isLoading={isLoading}
        generateCode={generateCode}
      />
    </Container>
  );
};

export default AdminPasswordCode;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
