// lib/generatePDF.ts
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const convertImageToDataURL = (imageSrc: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Handle cross-origin images
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      } else {
        reject('Could not get canvas context');
      }
    };
    img.onerror = reject;
    img.src = imageSrc;
  });
};

const waitForImagesToLoad = (element: HTMLElement): Promise<void> => {
  return new Promise<void>((resolve) => {
    const images = Array.from(element.querySelectorAll('img'));
    if (images.length === 0) {
      resolve();
      return;
    }

    let loadedImagesCount = 0;

    const checkAllImagesLoaded = () => {
      loadedImagesCount += 1;
      if (loadedImagesCount === images.length) {
        resolve();
      }
    };

    images.forEach((img) => {
      if (img.complete) {
        checkAllImagesLoaded();
      } else {
        img.addEventListener('load', checkAllImagesLoaded);
        img.addEventListener('error', checkAllImagesLoaded); // Handle case where image fails to load
      }
    });
  });
};


const generatePDF = async (
  activityData: any,
  studentData: any,
  scoreData: any,
  name: string,
  address: string,
  email: string
) => {
  const input = document.getElementById('report-content');

  if (input) {
    await waitForImagesToLoad(input);

    const canvas = await html2canvas(input, {
      scale: 2,
    });

    const imgData = canvas.toDataURL('image/png');

    const doc = new jsPDF();
    const imgWidth = 210; // A4 size in mm
    const pageHeight = 295; // A4 size in mm
    const imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    doc.save('student_result.pdf');
  }
};

export default generatePDF;
