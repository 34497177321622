import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import GeneratePasswordResetCode from "../../common/PasswordReset/GeneratePasswordResetCode";
import { usePasswordCodeMutation } from "../../../app/services/schoolApi";
import { toast } from "react-hot-toast";
import { useAppDispatch } from "../../../app/hooks";
import { setSchool } from "../../../app/features/schoolSlice";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../../../types";


const SchoolPasswordCode = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [generateCode, { data, isLoading, isSuccess, isError, error }] =
    usePasswordCodeMutation();

  useEffect(() => {
    if (isSuccess && data) {
      toast.success("Code sent to your email");
      dispatch(
        setSchool({
          id: data.id,
          name: data.name,
          address: data.address,
          email: data.email,
          phoneNo: data.phoneNo,
          website: data.website,
          country: data.country,
          state: data.state,
          lga: data.lga,
          token: data.token,
        })
      );
      navigate("/school-password-reset");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess]);

  return (
    <Container>
      <GeneratePasswordResetCode
        isLoading={isLoading}
        generateCode={generateCode}
      />
    </Container>
  );
};

export default SchoolPasswordCode;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
