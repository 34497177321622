import React, { useState } from "react";
import { uploadCurriculum } from "../../../lib/uploadCurriculum";
import { styled } from "styled-components";
import { Button } from "../../FormElements";
import { colors } from "../../../utils";

interface IUploadCurriculum {
  closeModal: () => void;
}

const UploadCurriculum = ({ closeModal }: IUploadCurriculum) => {
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState<string>("");
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setUploadError(null);
    } else {
      setUploadError("Please select a valid PDF file.");
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleUpload = async () => {
    if (!file) {
      setUploadError("No file selected.");
      return;
    }

    if (!title) {
      setUploadError("Please provide a title for the curriculum.");
      return;
    }

    setUploading(true);
    setUploadProgress(0);

    try {
      await uploadCurriculum(file, title, (progress) => {
        setUploadProgress(Math.round(progress)); // Update progress state
      });
      console.log("File uploaded successfully");
      closeModal();
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadError("Failed to upload file.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container>
      <Wrapper>
        <h3>Upload Curriculum</h3>
        <ItemWrapper>
          <input
            type="text"
            placeholder="Enter curriculum title"
            value={title}
            onChange={handleTitleChange}
          />
        </ItemWrapper>
        <ItemWrapper>
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </ItemWrapper>

        {uploadError && <div style={{ color: "red" }}>{uploadError}</div>}

        <Button
          color={colors.white}
          border="none"
          backgroundColor={colors.primary}
          text={uploading ? `Uploading... ${uploadProgress}%` : "Upload"}
          disabled={uploading || !file || !title}
          onClick={handleUpload}
        />

        <button onClick={closeModal}>Cancel</button>
      </Wrapper>
    </Container>
  );
};

export default UploadCurriculum;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.6rem;
  margin: auto;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
