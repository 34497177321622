import React, { useState } from "react";
import { styled } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { selectPartner } from "../../app/features/partnerSlice";
import PartnersStats from "./PartnersStats";
import GetAdminAttachedToPartner from "./PartnerComponents/GetAdminAttachedToPartner";
import { colors, screens } from "../../utils";
import LearningStats from "./PartnerComponents/LearningStats";

const PartnerScreens = () => {
  const { firstName } =
    useAppSelector(selectPartner);

  return (
    <Container>
      <Wrapper>
        <StatsWrapper>
          <h1>Welcome to the dashboard, {firstName}</h1>
          <StatsContent>
            <h3>Your Stats</h3>
            <PartnersStats />
          </StatsContent>

          <StatsContent>
            <h3>Learning Resources</h3>
            <LearningStats />
          </StatsContent>
        </StatsWrapper>
        <RepWrapper>
          <GetAdminAttachedToPartner />
        </RepWrapper>
      </Wrapper>
    </Container>
  );
};

export default PartnerScreens;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const StatsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.7rem;

  &:first-of-type {
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 0.9rem;
    color: ${colors.dark};
  }
`;

const RepWrapper = styled.div`
  border-left: 1px solid ${colors.primary};
  width: 35%;
  display: flex;

  @media (max-width: ${screens.tab}) {
    border-left: none;
    width: 100%;
  }
`;
