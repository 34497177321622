import React from "react";
import { styled } from "styled-components";
import TwoColumns from "../common/Landing/Hero/TwoColumns";
import UsersSection from "../common/Landing/UsersSection";
import Work from "../common/Landing/Work";
import ForSchools from "../common/Landing/ForSchools";
import ForProviders from "../common/Landing/ForProviders";
import ForAll from "../common/Landing/ForAll";

const Home = () => {
  return (
    <Container>
      <TwoColumns />
      <UsersSection />
      <Work />
      <ForSchools />
      <ForProviders />
      <ForAll />
    </Container>
  );
};

export default Home;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
