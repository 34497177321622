import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectProvider } from "../../app/features/providerSlice";
import { useGetAllActivitiesQuery } from "../../app/services/providerApi";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";
import { Button } from "../FormElements";
import Spinner from "../../utils/Spinner";
import ActivityCard from "../ActivityCard";

interface Activity {
  _id: string;
  title: string;
  description: string;
  mode: string;
  price: string;
  avatar?: string;
  students: string;
  schools: string[];
}

const AllProviderActivities: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useAppSelector(selectProvider);
  const {
    data: activities,
    isLoading,
    isError,
    error,
  } = useGetAllActivitiesQuery(id);

  // Function to handle adding a new activity
  const handleAddActivity = () => {
    navigate(`${id}/add-activity`);
  };

  return (
    <Container>
      <Wrapper>
        <HeadingWrapper>
          <h3>Provider Activities</h3>
          <ButtonWrapper>
            <Button
              onClick={handleAddActivity}
              text="Add New Activity"
              color={colors.white}
              backgroundColor={colors.primary}
              border="none"
            />
          </ButtonWrapper>
        </HeadingWrapper>

        <ContentWrapper>
          {isLoading ? (
            <Spinner color={colors.primary} />
          ) : isError ? (
            <p>Oops!!! Something went wrong.</p>
          ) : activities && activities.length > 0 ? (
            <Content>
              {activities.map((activity: Activity) => (
                <ActivityCard
                  key={activity._id}
                  title={activity.title}
                  mode={activity.mode}
                  studentsCount={activity.schools.length}
                  src={activity.avatar}
                  bgCol={colors.white}
                  titleCol={colors.black}
                  price={activity.price}
                  to={`${activity._id}`}
                />
              ))}
            </Content>
          ) : (
            <p>No activities found for this provider.</p>
          )}
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default AllProviderActivities;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 2rem auto;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 1.1rem;
    color: ${colors.black};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    grid-template-columns: 1fr;
  }
`;
