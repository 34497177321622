import React from "react";
import { styled } from "styled-components";
import { colors } from "../../utils";

interface ILabel {
  text: string;
  color?: string;
  fontSize?: string;
  marginBottom?: string;
}

const Label = ({ text, color, fontSize, marginBottom }: ILabel) => {
  return (
    <LabelWrapper
      style={{
        color: color,
        fontSize: fontSize,
        marginBottom: marginBottom,
      }}
    >
      {text}
    </LabelWrapper>
  );
};

export default Label;

const LabelWrapper = styled.label`
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.3;
`;
