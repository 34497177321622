import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../../utils";
import src from "../../../../assets/images/back3.png";
import Card from "./Card";

const data = [
  {
    id: 1,
    title: "Find reliable providers",
    action: "Get started",
    to: "/sign-up",
  },
  {
    id: 2,
    title: "Choose preferred activities",
    action: "Browse shop",
    to: "#",
  },
  {
    id: 3,
    title: "Reward excellence generously",
    action: "View rewards",
    to: "#",
  },
];

const ForAll = () => {
  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <Title>For excellence</Title>
          <Heading>Extracurricular activities, your way, with ease.</Heading>
          <Body>
            Work with a network of vetted professionals and get an active
            extracurricular structure for your school without any hassles, and
            with all the tools you need to manage the system with ease.
          </Body>
        </ContentWrapper>
        <CardWrapper>
          {data.map((card: any) => {
            return (
              <Card
                key={card.id}
                to={card.to}
                title={card.title}
                action={card.action}
              />
            );
          })}
        </CardWrapper>
      </Wrapper>
    </Container>
  );
};

export default ForAll;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 2rem auto;
  background-image: url(${src});
  background-size: cover;
  border-radius: 1.2rem;

  @media (max-width: ${screens.minitab}) {
    flex-direction: column;
    width: 100%;
    border-radius: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: 2rem;

  @media (max-width: ${screens.minitab}) {
    margin: 2rem 1rem;
    width: 90%;
  }
`;

const Title = styled.h2`
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  color: ${colors.white};
  margin-bottom: 2rem;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  color: ${colors.white};
  font-weight: 600;
  display: flex;
  width: 100%;
`;

const Body = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3;
  color: ${colors.white};
  margin: 1.5rem 0;
`;

const CardWrapper = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: 0 2rem 2rem 2rem;
  gap: 2rem;

  @media (max-width: ${screens.minitab}) {
    flex-direction: column;
    margin: 0 auto 2rem;
  }
`;
