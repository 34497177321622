import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import Form from "../../FormElements/Form";
import { Button, Input, Label } from "../../FormElements";
import useForm, { FormValues } from "../../../hooks/useForm";
import { colors, screens } from "../../../utils";
import { CustomError } from "../../../types";
import Spinner from "../../../utils/Spinner";
import { useAddLearningMutation } from "../../../app/services/learningApi";
import { ImageInput } from "../../FormElements/ImageInput";

// Initial state for form values
const initialState: FormValues = {
  title: "",
  subtitle: "",
  description: "",
  url: "",
};

const AddLearning: React.FC = () => {
  const { formValues, handleInputChange } = useForm(initialState);
  const [addLearning, { isLoading, isSuccess, isError, error }] =
    useAddLearningMutation();
  const navigate = useNavigate();

  const { title, subtitle, description, url } = formValues;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (title && subtitle && description && url) {
      await addLearning({
        title,
        subtitle,
        description,
        url,
      });

      if (isSuccess) {
        toast.success("Learning Resource successfully uploaded");
        navigate(-1);
      } else if (isError) {
        const customError = error as CustomError;
        const errorMessage =
          customError?.data?.error || "Oops! Something went wrong";
        toast.error(errorMessage);
      }
    }
  };

  return (
    <Container>
      <Wrapper>
        <h2>Add a new Learning Resource</h2>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label
              text="Title of Learning Resource"
              fontSize="1rem"
              color={colors.dark}
            />
            <Input
              type="text"
              value={title}
              name="title"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label
              text="Subtitle of Learning Resource"
              fontSize="1rem"
              color={colors.dark}
            />
            <Input
              type="text"
              value={subtitle}
              name="subtitle"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label
              text="Description of Learning Resource"
              fontSize="1rem"
              color={colors.dark}
            />
            <Input
              type="text-area"
              value={description}
              name="description"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Video URL" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={url}
              name="url"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <Button
            type="submit"
            text={
              isLoading ? (
                <Spinner color={colors.white} />
              ) : (
                "Add Learning Resource"
              )
            }
            color={colors.white}
            backgroundColor={colors.primary}
            border="none"
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default AddLearning;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin: 1rem auto;
  gap: 1rem;

  h2 {
    font-size: 1.2rem;
    color: ${colors.dark};
  }

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
