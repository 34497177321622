import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { Link } from "react-router-dom";

const LearningStats = () => {
  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <h3>Webinars and Learning Resources</h3>
          <small>
            Find helpful learning resources. Become great at what you do and
            keep up to date on important information from ExtraBox
          </small>
        </ContentWrapper>

        <ButtonWrapper to="/partner-dashboard/learning">Learn</ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default LearningStats;

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.bluePry};
  border-radius: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 2rem 1.5rem;
  gap: 1rem;
  align-items: center;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.6rem;

  h3 {
    font-size: 1rem;
    font-weight: 700;
    color: ${colors.white} !important;
  }

  small {
    font-size: 0.9rem;
    font-weight: 400;
    color: ${colors.white};
    line-height: 1.5;
  }
`;

const ButtonWrapper = styled(Link)`
  background-color: transparent;
  border: 1.5px solid ${colors.white};
  color: ${colors.white};
  border-radius: 0.25rem;
  padding: 0.5rem 2rem;
  width: fit-content;
  text-decoration: none;
  font-size: 0.9rem;
`;
