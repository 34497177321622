import React, { useEffect, useState } from "react";
import { Button, Input, Label, PasswordInput } from "../../FormElements";
import useForm, { FormValues } from "../../../hooks/useForm";
import { colors } from "../../../utils";
import Form from "../../FormElements/Form";
import { toast } from "react-hot-toast";
import { styled } from "styled-components";
import { CustomError } from "../../../types";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import Spinner from "../../../utils/Spinner";
import { useSignInSchoolMutation } from "../../../app/services/schoolApi";
import { setSchool } from "../../../app/features/schoolSlice";

const initialState: FormValues = {
  email: "",
  password: "",
};

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formValues, handleInputChange } = useForm(initialState);
  const { email, password } = formValues;
  const [schoolSignIn, { data, isLoading, isSuccess, isError, error }] =
    useSignInSchoolMutation();

  //Toggle Password visibility
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email && password) {
      await schoolSignIn({
        email,
        password,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setSchool({
          id: data.school.id,
          name: data.school.name,
          address: data.school.address,
          email: data.school.email,
          website: data.school.website,
          phoneNo: data.school.phoneNo,
          country: data.school.country,
          state: data.school.state,
          lga: data.school.lga,
          token: data.token,
          logo: data.school.logo,
        })
      );
      navigate("/school-dashboard/home");
      toast.success("Hello today. Welcome!");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Form onSubmit={handleSubmit}>
      <ItemWrapper>
        <Label text="Email" fontSize="1rem" color={colors.dark} />
        <Input
          type="email"
          value={email}
          name="email"
          onChange={handleInputChange}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Label text="Password" fontSize="1rem" color={colors.dark} />
        <PasswordInput
          type={passwordType}
          name="password"
          value={password}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />
      </ItemWrapper>

      <Button
        type="submit"
        text={isLoading ? <Spinner color={colors.white} /> : "Sign in"}
        color={colors.white}
        backgroundColor={colors.primary}
        border="none"
      />
    </Form>
  );
};

export default SignIn;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
