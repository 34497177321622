import { styled } from "styled-components";
import { PiTagChevronThin } from "react-icons/pi";
import { MdOutlineCalendarMonth, MdOutlineUpdate } from "react-icons/md";
import { colors, screens } from "../../../utils";

interface IPayment {
  year: string;
  price: string;
  term: string;
}

const Payment = ({ year, price, term }: IPayment) => {
  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <PiTagChevronThin size="0.8rem" color={colors.dark} />
          <p>
            <strong>Price: </strong>
            {price}
          </p>
        </TitleWrapper>

        <Wraps>
          <TextWrapper>
            <IconWrapper>
              <MdOutlineCalendarMonth size="0.9rem" color={colors.dark} />
            </IconWrapper>
            <Text>{year}</Text>
          </TextWrapper>
          <TextWrapper>
            <IconWrapper>
              <MdOutlineUpdate size="0.9rem" color={colors.dark} />
            </IconWrapper>
            <Text>{term}</Text>
          </TextWrapper>
        </Wraps>
      </Wrapper>
    </Container>
  );
};

export default Payment;

const Container = styled.div`
  display: flex;
  align-items: center;

  border-top: 0.5px solid ${colors.grey};
  border-bottom: 0.5px solid ${colors.grey};

  &:hover {
    border: 0.5px solid ${colors.grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0.5rem;
  gap: 1rem;
  text-decoration: none;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    margin: auto 1rem auto 0;
    display: flex;
    width: 100%;
    text-transform: capitalize;
    word-wrap: break-word;

    strong {
      font-size: 0.8rem;
      font-weight: 700;
      color: ${colors.dark};
      display: flex;
      margin-right: 0.3rem;
    }

    @media (max-width: ${screens.minitab}) {
      width: 80%;
      margin: auto;
    }
  }
`;

const Text = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.dark};
  display: flex;
  width: 100%;
  text-transform: capitalize;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const TextWrapper = styled.div`
  display: flex;
  width: fit-content;
  gap: 0.4rem;
  align-items: center;
`;

const Wraps = styled.div`
  display: grid;
  width: 30%;
  grid-template-columns: 1fr 1.8fr;
  gap: 1rem;
`;
