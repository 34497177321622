import { ReactNode } from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import { colors } from "../../utils";
import Navigation from "../../components/common/Navigation";
import FooterElements from "../../components/common/FooterElements";

const data = [
  {
    id: 1,
    title: "For Partners",
    linkTo: "#",
  },

  {
    id: 2,
    title: "For Providers",
    linkTo: "#",
  },

  {
    id: 3,
    title: "For Schools",
    linkTo: "#",
  },

  {
    id: 4,
    title: "Why ExtraBox",
    linkTo: "#",
  },
];
interface ILayout {
  children: ReactNode;
}

const HomeLayout = ({ children }: ILayout) => {
  const handleLogout = () => {
    console.log("Out");
  };

  return (
    <Container>
      <Navigation
        clickLink="sign-in"
        buttonText="Sign in"
        navData={data}
        handleLogout={handleLogout}
      />
      <Wrapper>{children}</Wrapper>
      <Footer backgroundColor={colors.white}>
        <FooterElements />
      </Footer>
    </Container>
  );
};

export default HomeLayout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  min-height: 90vh;
`;
