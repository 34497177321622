import HomeLayout from '../../layouts/HomeLayout'
import SignIn from '../../components/Authentication/SignIn'

const SignInSelectPage = () => {
  return (
    <HomeLayout>
        <SignIn />
    </HomeLayout>
  )
}

export default SignInSelectPage