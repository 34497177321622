import React from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import { FormContainer } from "../../FormElements";
import signupImage from "../../../assets/images/handshake.jpeg";
import signinImage from "../../../assets/images/collaboration.jpeg";

const Providers = () => {
  const location = useLocation();
  const isSignUpRoute = location.pathname === "/sign-up/provider-signup";
  const isSignInRoute = location.pathname === "/sign-in/provider-signin";

  return (
    <Wrapper>
      {isSignUpRoute && (
        <FormContainer
          title="Become a Provider with ExtraBox"
          subtitle="Hi there, please fill out and submit the form below:"
          formTitle="Register with Us"
          accountText="Already have an account?"
          accountTo="/sign-in/provider-signin"
          accountToText="Sign in"
          backgroundImage={signupImage}
        >
          <SignUp />
        </FormContainer>
      )}
      {isSignInRoute && (
        <FormContainer
          title="End to End Extracurricular Activities Support Platform"
          subtitle="extrabox.club is an innovative schools-students extracurricular activities platform that offer students scholarships for their achievements during elementary and high school."
          formTitle="Login for Providers"
          accountText="Don't have an account?"
          accountTo="/sign-up/provider-signup"
          accountToText="Sign up"
          backgroundImage={signinImage}
          passwordText="Reset it"
          passwordTo="/provider-password-code"
        >
          <SignIn />
        </FormContainer>
      )}
    </Wrapper>
  );
};

export default Providers;

const Wrapper = styled.div`
  display: 100%;
  width: 100%;
`;
