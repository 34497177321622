import { styled } from "styled-components";
import { colors } from "../../../../utils";

interface IFeatureCard {
  icon: any;
  title: string;
  body: string;
}

const FeatureCard = ({ icon, title, body }: IFeatureCard) => {
  return (
    <Container>
      <Wrapper>
        <ImageWrapper>{icon}</ImageWrapper>
        <TextWrapper>
          <Heading>{title}</Heading>
          <Body>{body} </Body>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};

export default FeatureCard;

const Container = styled.div`
  display: flex;
  width: 100%;

  
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`;

const ImageWrapper = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2) rotate(360deg);
  }

  img,
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;
const Heading = styled.h5`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.dark};
`;

const Body = styled.p`
  font-size: 0.7rem;
  font-weight: 400;
  color: ${colors.dark};
`;
