import React from "react";
import { styled } from "styled-components";
import { PiTagChevronThin } from "react-icons/pi";
import { MdOutlineCalendarMonth, MdOutlineUpdate } from "react-icons/md";
import { colors, screens } from "../../../utils";
import { toast } from "react-hot-toast";

interface IPaymentCard {
  year: string;
  price: number;
  term: string;
  isPaid: string;
  onClick: () => void;
  isSelected: boolean;
}

const PaymentCard = React.memo(({
  year,
  price,
  term,
  isPaid,
  onClick,
  isSelected,
}: IPaymentCard) => {
  const isPaidBackground =
    isPaid === "paid"
      ? colors.green
      : isPaid === "not-paid"
      ? colors.orange
      : colors.grey;
  const isPaidText =
    isPaid === "paid" ? "Paid" : isPaid === "not-paid" ? "Not Paid" : "Unknown";

  const handleClick = () => {
    if (isPaid === "not-paid") {
      onClick();
    } else if (isPaid === "paid") {
      toast.error("You have already paid this");
    }
  };

  return (
    <Container onClick={handleClick} isSelected={isSelected}>
      <Wrapper>
        <TitleWrapper>
          <PiTagChevronThin size="0.8rem" color={colors.dark} />
          <p>
            <strong>Amount Due: </strong>&#x20A6;{price}
          </p>
        </TitleWrapper>

        <Wraps>
          <TextWrapper>
            <IconWrapper>
              <MdOutlineCalendarMonth size="0.9rem" color={colors.dark} />
            </IconWrapper>
            <Text>{year}</Text>
          </TextWrapper>
          <TextWrapper>
            <IconWrapper>
              <MdOutlineUpdate size="0.9rem" color={colors.dark} />
            </IconWrapper>
            <Text>{term}</Text>
          </TextWrapper>

          <IsPaid style={{ backgroundColor: isPaidBackground }}>
            <small>{isPaidText}</small>
          </IsPaid>
        </Wraps>
      </Wrapper>
    </Container>
  );
});

export default PaymentCard;

const Container = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  border-top: 0.5px solid ${colors.grey};
  border-bottom: 0.5px solid ${colors.grey};
  background-color: ${({ isSelected }) =>
    isSelected ? colors.grey : "transparent"};
  cursor: pointer;

  &:hover {
    border: 0.5px solid ${colors.grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0.5rem;
  gap: 1rem;
  text-decoration: none;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    margin: auto 1rem auto 0;
    display: flex;
    width: 100%;
    text-transform: capitalize;
    word-wrap: break-word;

    strong {
      font-size: 0.8rem;
      font-weight: 700;
      color: ${colors.dark};
      display: flex;
      margin-right: 0.3rem;
    }

    @media (max-width: ${screens.minitab}) {
      width: 80%;
      margin: auto;
    }
  }
`;

const Text = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.dark};
  display: flex;
  width: 100%;
  text-transform: capitalize;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const TextWrapper = styled.div`
  display: flex;
  width: fit-content;
  gap: 0.4rem;
  align-items: center;
`;

const Wraps = styled.div`
  display: grid;
  width: 50%;
  grid-template-columns: 1fr 1.8fr 1fr;
  gap: 1rem;
`;

const IsPaid = styled.div`
  border-radius: 0.2rem;
  justify-content: center;
  width: fit-content;

  small {
    color: ${colors.white};
    font-size: 0.7rem;
    display: flex;
    margin: 0.1rem 0.2rem;
  }
`;
