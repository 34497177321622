import { Outlet } from "react-router-dom";
import UniversityLayout from "../../layouts/UniversityLayout";

const UniversityPages = () => {
  return (
    <UniversityLayout>
      <Outlet />
    </UniversityLayout>
  );
};

export default UniversityPages;
