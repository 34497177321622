import { useNavigate } from "react-router-dom";
import { colors } from "../../utils";
import { styled } from "styled-components";

const NotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Wrapper>
        <h2>404</h2>
        <p>Page not found</p>
        <ButtonWrapper>
          <div onClick={handleClick}>Go back</div>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default NotFound;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin: auto;

  h2 {
    margin-bottom: 2rem;
    font-size: 3rem;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  p {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.25rem 0.6rem;
  margin: auto;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: ${colors.secondary};
  color: ${colors.white};
  font-size: 0.9rem;
`;
