import React, { useEffect, useState } from "react";
import { useGetProviderSchedulesQuery } from "../../app/services/providerApi";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { selectProvider } from "../../app/features/providerSlice";
import { colors, screens } from "../../utils";
import { Link } from "react-router-dom";
import Pagination from "../common/Pagination";

interface Schedule {
  _id: string;
  title: string;
  date: string;
  schools: string[]; // Adjust this based on your actual data model
}

const ITEMS_PER_PAGE = 4;

const AllProviderSchedules = () => {
  const { id: providerId } = useAppSelector(selectProvider);
  const { data, error, isLoading } = useGetProviderSchedulesQuery(providerId);
  const [page, setPage] = useState(1);
  const [schedulesToDisplay, setSchedulesToDisplay] = useState<Schedule[]>([]);

  useEffect(() => {
    if (data) {
      // Sort schedules by creation date (most recent first)
      const sortedSchedules = [...data].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      // Calculate pagination
      const startIndex = (page - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;

      setSchedulesToDisplay(sortedSchedules.slice(startIndex, endIndex));
    }
  }, [data, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  if (isLoading) {
    return <p>Loading schedules...</p>;
  }

  if (error) {
    return <p>Error fetching schedules</p>;
  }

  if (!data || data.length === 0) {
    return <p>No schedules available</p>;
  }

  // Sort schedules by creation date (most recent first)
  const totalItems = data.length;

  return (
    <Container>
      <Wrapper>
        <ScheduleList>
          {schedulesToDisplay.map((schedule: Schedule) => (
            <ScheduleCard key={schedule._id} to={`${schedule._id}`}>
              <h4>{schedule.title}</h4>
              <p>Date: {new Date(schedule.date).toLocaleDateString()}</p>
              {/* <p>Schools: {schedule.schools.join(", ")}</p> */}
            </ScheduleCard>
          ))}
        </ScheduleList>
        <Pagination
          currentPage={page}
          totalItems={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
        />
      </Wrapper>
    </Container>
  );
};

export default AllProviderSchedules;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin: 2rem auto;

  @media (max-width: ${screens.tab}) {
    width: 90%;
    margin: 1rem auto;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
  }
`;

const ScheduleList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const ScheduleCard = styled(Link)`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  flex-direction: column;
  padding: 1rem;
  text-decoration: none;
  color: ${colors.dark};
  border: 1px solid ${colors.grey};
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
