import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ReactGA from "react-ga";
import { useAppDispatch } from "./app/hooks";
import { setPartner } from "./app/features/partnerSlice";
import HomePage from "./pages/HomePage";
import SignInSelectPage from "./pages/SignInSelectPage";
import PartnerAuth from "./pages/PartnerAuth";
import { setProvider } from "./app/features/providerSlice";
import { setSchool } from "./app/features/schoolSlice";
import ProviderPasswordReset from "./components/Authentication/Providers/ProviderPasswordReset";
import ProviderPasswordCode from "./components/Authentication/Providers/ProviderPasswordCode";
import PartnerPages from "./pages/PartnerPages";
import SchoolPasswordCode from "./components/Authentication/Schools/SchoolPasswordCode";
import SchoolPasswordReset from "./components/Authentication/Schools/SchoolPasswordReset";
import SignUpSelectPage from "./pages/SignUpSelectPage";
import NotFound from "./pages/404";
import AdminAuth from "./pages/AdminAuth";
import AdminPages from "./pages/AdminPages";
import { setAdmin } from "./app/features/adminSlice";
import AdminPasswordReset from "./components/Authentication/Admin/AdminPasswordReset";
import AdminPasswordCode from "./components/Authentication/Admin/AdminPasswordCode";
import AdminScreens from "./components/AdminDashboard/AdminScreens";
import SingleProvider from "./components/AdminDashboard/AdminScreens/SingleProvider";
import SingleAdmin from "./components/AdminDashboard/AdminScreens/SingleAdmin";
import PartnerScreens from "./components/PartnerScreens";
import SingleProviderPartner from "./components/PartnerScreens/SingleProviderPartner";
import ProvidersList from "./components/PartnerScreens/ProvidersList";
import SchoolsList from "./components/PartnerScreens/SchoolsList";
import SingleSchool from "./components/PartnerScreens/SingleSchool";
import SchoolPages from "./pages/SchoolPages";
import SchoolAuth from "./pages/SchoolAuth";
import SinglePartner from "./components/AdminDashboard/AdminScreens/SinglePartner";
import Learning from "./components/PartnerScreens/Learning";
import LearningDetails from "./components/PartnerScreens/LearningDetails";
import AddLearning from "./components/AdminDashboard/AdminScreens/AddLearning";
import SingleSchoolAdmin from "./components/AdminDashboard/AdminScreens/SingleSchoolAdmin";
import AllPartnersScreen from "./components/AdminDashboard/AdminScreens/AllPartnersScreen";
import AllProvidersScreen from "./components/AdminDashboard/AdminScreens/AllProvidersScreen";
import AllSchoolsScreen from "./components/AdminDashboard/AdminScreens/AllSchoolsScreen";
import AdminLearning from "./components/AdminDashboard/AdminScreens/AdminLearning";
import { setParent } from "./app/features/parentSlice";
import ParentAuth from "./pages/ParentAuth";
import ParentScreens from "./components/ParentScreens";
import SingleUniversity from "./components/PartnerScreens/SingleUniversity";
import UniversitiesList from "./components/PartnerScreens/UniversitiesList";
import UniversityAuth from "./pages/UniversityAuth";
import UniversityPasswordReset from "./components/Authentication/Universities/UniversityPasswordReset";
import UniversityPasswordCode from "./components/Authentication/Universities/UniversityPasswordCode";
import { setUniversity } from "./app/features/universitySlice";
import UniversityPages from "./pages/UniversityPages";
import UniversityScreens from "./components/UniversityScreens";
import SingleUniversityAdmin from "./components/AdminDashboard/AdminScreens/SingleUniversityAdmin";
import AllUniversities from "./components/AdminDashboard/AdminScreens/AllUniversities";
import ParentPages from "./pages/ParentPages";
import SchoolStudents from "./components/SchoolScreens/SchoolStudents";
import SchoolHome from "./components/SchoolScreens/SchoolHome";
import SingleStudent from "./components/SchoolScreens/SchoolStudents/SingleStudent";
import SchoolActivities from "./components/SchoolScreens/SchoolActivities";
import SingleActivity from "./components/SchoolScreens/SchoolActivities/SingleActivity";
import SchoolParents from "./components/SchoolScreens/SchoolParents";
import SingleParent from "./components/SchoolScreens/SchoolParents/SingleParent";
import SchoolPayments from "./components/SchoolScreens/SchoolPayments";
import SchoolCurriculum from "./components/SchoolScreens/SchoolCurriculum";
import AdminPayments from "./components/AdminDashboard/AdminScreens/AdminPayments";
import RentAGadget from "./components/SchoolScreens/SchoolActivities/RentAGadget";
import Results from "./components/SchoolScreens/SchoolActivities/Result";
import SingleStudentResult from "./components/SchoolScreens/SchoolActivities/SingleStudentResult";
import PartnerPasswordReset from "./components/Authentication/Partners/PartnerPasswordReset";
import PartnerPasswordCode from "./components/Authentication/Partners/PartnerPasswordCode";
import EditStudent from "./components/SchoolScreens/SchoolStudents/EditStudent";
import EditParent from "./components/SchoolScreens/SchoolParents/EditParent";
import ActivityRegister from "./components/SchoolScreens/SchoolActivities/ActivityRegister";
import ProviderAuth from "./pages/ProviderAuth";
import ProviderPages from "./pages/ProviderPages";
import ProviderScreens from "./components/ProviderScreens";
import AllProviderActivities from "./components/ProviderScreens/AllProviderActivities";
import AddActivity from "./components/ProviderScreens/AddActivity";
import ProviderSingleActivity from "./components/ProviderScreens/ProviderSingleActivity";
import Marketplace from "./components/Marketplace";
import ProviderActivityDetails from "./components/Marketplace/ProviderActivityDetails";
import Enrolled from "./components/Marketplace/Enrolled";
import ProviderSchoolsEnrolled from "./components/ProviderScreens/ProviderSchoolsEnrolled";
import SingleActivityByProvider from "./components/SchoolScreens/SchoolActivities/SingleActivityByProvider";
import ProviderScheduleSession from "./components/ProviderScreens/ProviderScheduleSession";
import CreateOnlineMeeting from "./components/ProviderScreens/CreateOnlineMeeting";
import AllProviderSchedules from "./components/ProviderScreens/AllProviderSchedules";
import SchoolSchedules from "./components/SchoolScreens/SchoolSchedules";
import AllActivitySchedules from "./components/SchoolScreens/SchoolSchedules/AllActivitySchedules";
import ScheduleDetails from "./components/SchoolScreens/SchoolSchedules/ScheduleDetails";
import SchoolScheduleSession from "./components/SchoolScreens/SchoolActivities/SchoolScheduleSession";

const TRACKING_ID = "G-23RZMNSWGK";

ReactGA.initialize(TRACKING_ID);

function App() {
  const dispatch = useAppDispatch();

  const admin = JSON.parse(localStorage.getItem("admin") || "{}");
  const school = JSON.parse(localStorage.getItem("school") || "{}");
  const provider = JSON.parse(localStorage.getItem("provider") || "{}");
  const partner = JSON.parse(localStorage.getItem("partner") || "{}");
  const parent = JSON.parse(localStorage.getItem("parent") || "{}");
  const university = JSON.parse(localStorage.getItem("university") || "{}");

  useEffect(() => {
    dispatch(setAdmin(admin));
  }, [dispatch, admin]);

  useEffect(() => {
    dispatch(setPartner(partner));
  }, [dispatch, partner]);

  useEffect(() => {
    dispatch(setProvider(provider));
  }, [dispatch, provider]);

  useEffect(() => {
    dispatch(setSchool(school));
  }, [dispatch, school]);

  useEffect(() => {
    dispatch(setParent(parent));
  }, [dispatch, parent]);

  useEffect(() => {
    dispatch(setUniversity(university));
  }, [dispatch, university]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Toaster position="top-right" />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/sign-in" element={<SignInSelectPage />} />
        <Route path="/sign-up" element={<SignUpSelectPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="sign-up/partner-signup" element={<PartnerAuth />} />
        <Route path="/sign-in/partner-signin" element={<PartnerAuth />} />
        <Route path="/sign-in/admin-signin" element={<AdminAuth />} />
        <Route path="/sign-in/school-signin" element={<SchoolAuth />} />
        <Route path="/sign-up/school-signup" element={<SchoolAuth />} />
        <Route path="/sign-in/parent-signin" element={<ParentAuth />} />
        <Route path="/sign-up/parent-signup" element={<ParentAuth />} />
        <Route path="/sign-in/university-signin" element={<UniversityAuth />} />
        <Route path="/sign-up/university-signup" element={<UniversityAuth />} />
        <Route path="sign-up/provider-signup" element={<ProviderAuth />} />
        <Route path="/sign-in/provider-signin" element={<ProviderAuth />} />

        <Route
          path="/partner-password-reset"
          element={<PartnerPasswordReset />}
        />
        <Route
          path="/partner-password-code"
          element={<PartnerPasswordCode />}
        />

        <Route
          path="/provider-password-reset"
          element={<ProviderPasswordReset />}
        />
        <Route
          path="/provider-password-code"
          element={<ProviderPasswordCode />}
        />

        <Route
          path="/school-password-reset"
          element={<SchoolPasswordReset />}
        />
        <Route path="/school-password-code" element={<SchoolPasswordCode />} />

        <Route
          path="/university-password-reset"
          element={<UniversityPasswordReset />}
        />
        <Route
          path="/university-password-code"
          element={<UniversityPasswordCode />}
        />

        <Route path="/admin-password-reset" element={<AdminPasswordReset />} />
        <Route path="/admin-password-code" element={<AdminPasswordCode />} />

        <Route path="/partner-dashboard" element={<PartnerPages />}>
          <Route path="home" element={<PartnerScreens />} />
          <Route path="providers" element={<ProvidersList />} />
          <Route path="schools" element={<SchoolsList />} />
          <Route path="providers/:id" element={<SingleProviderPartner />} />
          <Route path="universities" element={<UniversitiesList />} />
          <Route path="universities/:id" element={<SingleUniversity />} />
          <Route path="schools/:id" element={<SingleSchool />} />
          <Route path="learning" element={<Learning />} />
          <Route path="learning/:id" element={<LearningDetails />} />
          <Route path="provider" element={<ProvidersList />} />
          <Route path="school" element={<SchoolsList />} />
        </Route>

        <Route path="/admin-dashboard" element={<AdminPages />}>
          <Route path="home" element={<AdminScreens />} />
          <Route path="partners" element={<AllPartnersScreen />} />
          <Route path="providers" element={<AllProvidersScreen />} />
          <Route path="schools" element={<AllSchoolsScreen />} />
          <Route path="universities" element={<AllUniversities />} />
          <Route path="providers/:id" element={<SingleProvider />} />
          <Route path="admins/:id" element={<SingleAdmin />} />
          <Route path="partners/:id" element={<SinglePartner />} />
          <Route path="schools/:id" element={<SingleSchoolAdmin />} />
          <Route path="learning" element={<AdminLearning />} />
          <Route path="learning/:id" element={<LearningDetails />} />
          <Route path="learning/add-learning" element={<AddLearning />} />
          <Route path="universities/:id" element={<SingleUniversityAdmin />} />
          <Route path="payments" element={<AdminPayments />} />
        </Route>

        <Route path="/school-dashboard" element={<SchoolPages />}>
          <Route path="home" element={<SchoolHome />} />
          <Route path="students" element={<SchoolStudents />} />
          <Route path="schedules" element={<SchoolSchedules />} />
          <Route path="schedules/:id" element={<AllActivitySchedules />} />
          <Route
            path="schedules/:schoolId/:activityId/:scheduleId/schedule-details"
            element={<ScheduleDetails />}
          />
          <Route path="students/:id" element={<SingleStudent />} />
          <Route path="students/:id/edit" element={<EditStudent />} />
          <Route path="activities" element={<SchoolActivities />} />
          <Route path="activities/results/:id" element={<Results />} />
          <Route path="home/marketplace" element={<Marketplace />} />
          <Route
            path="home/marketplace/activities/:id"
            element={<ProviderActivityDetails />}
          />
          <Route
            path="home/marketplace/activities/:id/enrolled"
            element={<Enrolled />}
          />
          <Route
            path="activities/results/:id/:studentId"
            element={<SingleStudentResult />}
          />
          <Route path="activities/:id" element={<SingleActivity />} />
          <Route path="activities/:id/create-schedule" element={<SchoolScheduleSession />} />
          <Route
            path="activities/providers-activities/:id"
            element={<SingleActivityByProvider />}
          />
          <Route
            path="activities/:id/register"
            element={<ActivityRegister />}
          />
          <Route path="activities/rent-a-gadget" element={<RentAGadget />} />
          <Route path="parents" element={<SchoolParents />} />
          <Route path="parents/:id" element={<SingleParent />} />
          <Route path="parents/:id/edit" element={<EditParent />} />
          <Route path="payments" element={<SchoolPayments />} />
          <Route path="home/curriculum" element={<SchoolCurriculum />} />
        </Route>

        <Route path="/provider-dashboard" element={<ProviderPages />}>
          <Route path="home" element={<ProviderScreens />} />
          <Route path="activities" element={<AllProviderActivities />} />
          <Route path="schedules" element={<AllProviderSchedules />} />
          <Route
            path="activities/:providerId/add-activity"
            element={<AddActivity />}
          />
          <Route path="activities/:id" element={<ProviderSingleActivity />} />
          <Route
            path="activities/:id/:id/schools"
            element={<ProviderSchoolsEnrolled />}
          />
          <Route
            path="activities/:id/:id/schools/schedule"
            element={<ProviderScheduleSession />}
          />
          <Route
            path="schedules/:id/create-online-meeting"
            element={<CreateOnlineMeeting />}
          />
        </Route>

        <Route path="/parent-dashboard" element={<ParentPages />}>
          <Route path="home" element={<ParentScreens />} />
        </Route>

        <Route path="/university-dashboard" element={<UniversityPages />}>
          <Route path="home" element={<UniversityScreens />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
