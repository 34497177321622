import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import Spinner from "../../utils/Spinner";
import { colors, screens } from "../../utils";
import { useGetSchoolByIdQuery } from "../../app/services/partnerApi";
import { BiSolidSchool } from "react-icons/bi";
import { FaAddressBook, FaPhone } from "react-icons/fa6";
import { MdEmail, MdMyLocation, MdLocationOn } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";

const SingleSchool = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess, isError, error } =
    useGetSchoolByIdQuery(id);

  return (
    <Container>
      <Wrapper>
        <h2>School Details</h2>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Oops!!! Something went wrong</p>
        ) : isSuccess ? (
          <SectionWrapper>
            <ActivitiesWrapper>
              <h3>Activities subscribed to</h3>
              {data.activities.length > 0 ? (
                data.activities.map((activitie: any, index: any) => {
                  return <p>{index}</p>;
                })
              ) : (
                <p>This school has not updated their activities.</p>
              )}
            </ActivitiesWrapper>
            <ContentWrapper>
              <Content>
                <IconWrapper>
                  <BiSolidSchool color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Name of School: </h1>
                <h1>{data.name}</h1>
              </Content>

              <Content>
                <IconWrapper>
                  <MdLocationOn color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Address: </h1>
                <p>{data.address}</p>
              </Content>

              <Content>
                <IconWrapper>
                  <FaPhone color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Phone No: </h1>
                <p>{data.phoneNo}</p>
              </Content>

              <Content>
                <IconWrapper>
                  <MdEmail color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Email: </h1>
                <p>{data.email}</p>
              </Content>

              <Content>
                <IconWrapper>
                  <CgWebsite color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Website: </h1>
                <p>{data.website}</p>
              </Content>

              <Content>
                <IconWrapper>
                  <MdMyLocation color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Location: </h1>
                <p>{`Country: ${data.country} | State: ${data.state} | LGA: ${data.lga}`}</p>
              </Content>
            </ContentWrapper>
          </SectionWrapper>
        ) : null}
      </Wrapper>
    </Container>
  );
};

export default SingleSchool;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 1rem auto 3rem;
  flex-direction: column;

  h2 {
    margin: auto;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-direction: row-reverse;

  @media (max-width: ${screens.tab}) {
    flex-direction: column-reverse;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 65%;
  border-radius: 0.5rem;
  padding: 2rem;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
  background-color: ${colors.blueSec};

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 35%;
  border: 1px solid ${colors.grey};
  border-radius: 0.5rem;
  padding: 2rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 0.6rem;

  h1 {
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.4;
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.4;
  }

  small {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  height: 1rem;
  margin-top: 0.1rem;
`;
