import React, { useState } from "react";
import { useDefaultPaymentMutation } from "../../../app/services/adminApi";
import { toast } from "react-hot-toast";
import { Button, Form, Input, Label } from "../../FormElements";
import Select from "../../FormElements/Select";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import { styled } from "styled-components";

const termData = [
  { optionValue: "first-term", optionText: "First Term" },
  { optionValue: "second-term", optionText: "Second Term" },
  { optionValue: "third-term", optionText: "Third Term" },
];

interface DiscountCode {
  code: string;
  amount: number;
}

const initialState = {
  price: "",
  term: "",
  year: "",
};

const DefaultPayment: React.FC = () => {
  const [formValue, setFormValue] = useState(initialState);
  const [defaultPayment, { isLoading }] = useDefaultPaymentMutation();
  const [discountCodes, setDiscountCodes] = useState<DiscountCode[]>([]);

  const { price, term, year } = formValue;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await defaultPayment({
        ...formValue,
        discountCodes,
      }).unwrap();
      toast.success("Default payment added successfully!");
      setFormValue(initialState);
      setDiscountCodes([]);
    } catch (error) {
      toast.error("Failed to add default payment.");
    }
  };

  const addDiscountCode = () => {
    setDiscountCodes([...discountCodes, { code: "", amount: 0 }]);
  };

  const handleDiscountCodeChange = (
    index: number,
    field: keyof DiscountCode,
    value: string | number
  ) => {
    const updatedDiscountCodes = discountCodes.map((discount, i) =>
      i === index ? { ...discount, [field]: value } : discount
    );
    setDiscountCodes(updatedDiscountCodes);
  };

  return (
    <Container>
      <h2>Add Default Payment</h2>
      <Form onSubmit={handleSubmit}>
        <ItemWrapper>
          <Label text="Price:" />
          <Input
            type="number"
            placeholder="Enter price"
            value={price}
            name="price"
            onChange={handleInputChange}
          />
        </ItemWrapper>

        <ItemWrapper>
          <Label text="Term:" />
          <Select
            value={term}
            name="term"
            defaultOption="Select Term"
            options={termData}
            onChange={handleInputChange}
          />
        </ItemWrapper>

        <ItemWrapper>
          <Label text="Year:" />
          <Input
            type="text"
            placeholder="Enter year"
            value={year}
            name="year"
            onChange={handleInputChange}
          />
        </ItemWrapper>

        <ItemWrapper>
          <h3>Discount Codes</h3>
          {discountCodes.map((discount, index) => (
            <div key={index}>
              <Input
                type="text"
                placeholder="Code"
                value={discount.code}
                onChange={(e) =>
                  handleDiscountCodeChange(index, "code", e.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Amount"
                value={discount.amount}
                onChange={(e) =>
                  handleDiscountCodeChange(
                    index,
                    "amount",
                    parseFloat(e.target.value)
                  )
                }
              />
            </div>
          ))}
          <Button
            type="button"
            text="Add Discount Code"
            color={colors.white}
            backgroundColor={colors.green}
            onClick={addDiscountCode}
          />
        </ItemWrapper>

        <Button
          type="submit"
          text={
            isLoading ? <Spinner color={colors.white} /> : "Add Default Payment"
          }
          color={colors.white}
          backgroundColor={colors.primary}
          disabled={isLoading}
        />
      </Form>
    </Container>
  );
};

export default DefaultPayment;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;

  div {
    gap: 0.2rem;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.6rem;

  div {
    gap: 0.2rem;
  }
`;
