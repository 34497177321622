import React, { FormEvent, useEffect, useState } from "react";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { Button, Form, Input, Label } from "../../FormElements";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import Select from "../../FormElements/Select";
import { CustomError } from "../../../types";
import { useAddActivityMutation } from "../../../app/services/schoolApi";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";

interface IAddActivity {
  closeModal: () => void;
}

const modeData = [
  {
    optionText: "Online",
    optionValue: "online",
  },

  {
    optionText: "Onsite",
    optionValue: "onsite",
  },
];

const initialState = {
  title: "",
  mode: "",
  description: "",
};

const AddActivity = ({ closeModal }: IAddActivity) => {
  const { id } = useAppSelector(selectSchool);
  const [formValue, setFormValue] = useState(initialState);
  const [addActivity, { isLoading, isSuccess, isError, error }] =
    useAddActivityMutation();

  const { title, mode, description } = formValue;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (title && mode && description) {
      await addActivity({
        schoolId: id,
        activityData: { title, mode, description },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Activity added successfully");
      closeModal();
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Add an activity</h3>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label text="Activity title" />
            <Input
              type="text"
              value={title}
              name="title"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Activity description" />
            <Input
              type="text"
              value={description}
              name="description"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Activity mode" fontSize="1rem" color={colors.dark} />
            <Select
              value={mode}
              name="mode"
              defaultOption="Select mode"
              onChange={handleInputChange}
              options={modeData}
            />
          </ItemWrapper>

          <Button
            color={colors.white}
            type="submit"
            border="none"
            backgroundColor={colors.primary}
            text={isLoading ? <Spinner color={colors.white} /> : "Add activity"}
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default AddActivity;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.6rem;
  margin: auto;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
