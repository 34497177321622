import React, { ReactNode } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { selectPartner } from "../../app/features/partnerSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Navigation from "../../components/common/Navigation";
import { logoutUniversity, selectUniversity } from "../../app/features/universitySlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const data = [
  {
    id: 1,
    title: "Dashboard",
    linkTo: "university-dashboard/home",
  },

  {
    id: 2,
    title: "Students",
    linkTo: "university-dashboard/students",
  },

  {
    id: 3,
    title: "Schorlarships",
    linkTo: "university-dashboard/schorlarships",
  },

  {
    id: 4,
    title: "Settings",
    linkTo: "university-dashboard/settings",
  },
];

interface IUniversityLayout {
  children: ReactNode;
}

const PartnerLayout = ({ children }: IUniversityLayout) => {
  const { token: universityToken, universityName } = useAppSelector(selectUniversity);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(logoutUniversity());
    navigate("/sign-in/university-signin");
    toast.success("You successfully logged out");
  };




  return (
    <ProtectedRoute token={universityToken} linkTo="/sign-in/university-signin">
      <Navigation
        token={universityToken}
        firstName={universityName}
        clickLink="#"
        buttonText="Sign in"
        navData={data}
        handleLogout={handleLogout}
      />
      {children}
    </ProtectedRoute>
  );
};

export default PartnerLayout;
