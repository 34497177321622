import { styled } from "styled-components";
import Spinner from "../../../utils/Spinner";
import { colors } from "../../../utils";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import { useGetParentsAddedBySchoolQuery } from "../../../app/services/schoolApi";
import Parent from "./Parent";
import Pagination from "../../common/Pagination";
import { useEffect, useState } from "react";

const ITEMS_PER_PAGE = 10;

const SchoolParents = () => {
  const { id } = useAppSelector(selectSchool);
  const { data, isLoading, isError } = useGetParentsAddedBySchoolQuery(id);
  const [page, setPage] = useState(1);
  const [parentsToDisplay, setParentsToDisplay] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const startIndex = (page - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      setParentsToDisplay(data.slice(startIndex, endIndex));
    }
  }, [data, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : data?.length === 0 ? (
          <ContentWrapper>
            <p>You have not added any parents</p>
            <p>Go to students tab to add a parent</p>
          </ContentWrapper>
        ) : data?.length >= 1 ? (
          <ContentWrapper>
            <TopWrapper>
              <h3>List of Parents</h3>
            </TopWrapper>
            <Content>
              {parentsToDisplay.map((parent: any) => {
                return (
                  <Parent
                    key={parent._id}
                    linkTo={parent._id}
                    title={`${parent.firstName} ${parent.lastName}`}
                    wards={parent.wards.length}
                    parentId={parent._id}
                  />
                );
              })}
            </Content>
            <Pagination
              currentPage={page}
              totalItems={data.length}
              itemsPerPage={ITEMS_PER_PAGE}
              onPageChange={handlePageChange}
            />
            <small>Select a parent to view them and their children</small>
          </ContentWrapper>
        ) : null}
      </Wrapper>
    </Container>
  );
};

export default SchoolParents;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.lightBack};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 1.5rem;

  small {
    font-size: 0.7rem;
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
