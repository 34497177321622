import { styled } from "styled-components";
import { selectAdmin } from "../../../app/features/adminSlice";
import { useAppSelector } from "../../../app/hooks";
import { colors, screens } from "../../../utils";
import { useGetDefaultPriceQuery } from "../../../app/services/adminApi";
import Payment from "./Payment";
import DefaultPayment from "./DefaultPayment";
import Spinner from "../../../utils/Spinner";

const AdminPayments = () => {
  const { tag } = useAppSelector(selectAdmin);
  const { data } = useGetDefaultPriceQuery({});

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <h1>Prices List</h1>
          <PaymentsWrapper>
            {data ? (
              data.length > 0 ? (
                data.map((payment: any) => (
                  <Payment
                    key={payment._id}
                    price={payment.price.toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    year={payment.year}
                    term={payment.term}
                  />
                ))
              ) : (
                <p>No payments added yet</p>
              )
            ) : (
              <Spinner color={colors.grey} />
            )}
          </PaymentsWrapper>
        </ContentWrapper>

        <Aside>
          {(tag === "ceo" || tag === "manager") && (
            <AddAdminWrapper>
              <DefaultPayment />
            </AddAdminWrapper>
          )}
        </Aside>
      </Wrapper>
    </Container>
  );
};

export default AdminPayments;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Aside = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 2rem;
  border-left: 1px solid ${colors.primary};
  padding: 1rem 0 1rem 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }
`;

const AddAdminWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.dark};
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.8rem;
`;

const PaymentsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
