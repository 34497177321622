import React, { FormEvent, useEffect, useState } from "react";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { Button, Form, Input, Label } from "../../FormElements";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import { CustomError } from "../../../types";
import {
  useEditParentMutation,
  useGetOneParentQuery,
} from "../../../app/services/schoolApi";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";

const EditParent = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: parentId } = useParams<{ id: string }>();
  const { data: parentData } = useGetOneParentQuery(parentId);
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
  });
  const [editParent, { isLoading, isSuccess, isError, error }] =
    useEditParentMutation();

  const { firstName, lastName, email, phoneNo } = formValue;

  useEffect(() => {
    if (parentData) {
      setFormValue({
        firstName: parentData.firstName || "",
        lastName: parentData.lastName || "",
        email: parentData.email || "",
        phoneNo: parentData.phoneNo || "",
      });
    }
  }, [parentData]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (e.target instanceof HTMLInputElement && e.target.files) {
      setFormValue({ ...formValue, [name]: e.target.files[0] });
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (firstName && lastName) {
      await editParent({
        parentId,
        schoolId,
        parentData: { firstName, lastName, email, phoneNo },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Parent updated successfully");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Edit Parent</h3>
        <Form onSubmit={handleSubmit}>
          <Label text="Parent's First Name" />
          <Input
            type="text"
            value={firstName}
            name="firstName"
            onChange={handleInputChange}
          />

          <ItemWrapper>
            <Label
              text="Parent's Last Name"
              fontSize="1rem"
              color={colors.dark}
            />
            <Input
              type="text"
              value={lastName}
              name="lastName"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Parent's email" fontSize="1rem" color={colors.dark} />
            <Input
              type="email"
              name="email"
              value={email}
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label
              text="Parent's Phone No"
              fontSize="1rem"
              color={colors.dark}
            />
            <Input
              type="text"
              name="phoneNo"
              value={phoneNo}
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <Button
            color={colors.white}
            type="submit"
            border="none"
            backgroundColor={colors.primary}
            text={isLoading ? <Spinner color={colors.white} /> : "Save Changes"}
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default EditParent;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 0.6rem;
  margin: 1rem auto;

  @media (max-width: ${screens.tab}) {
    width: 80%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const AvatarWrapper = styled.div`
  display: flex;
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  border-radius: 0.4rem;
  border: 1px solid ${colors.primary};

  img {
    object-fit: contain;
  }
`;
