import React, { ReactNode } from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";
import { Link } from "react-router-dom";

interface IImageWrapper {
  backgroundImage: string;
}

interface IFormContainer {
  title: string;
  subtitle: string;
  formTitle: string;
  children: ReactNode;
  accountText: string;
  accountTo: string;
  accountToText: string;
  backgroundImage?: any;
  passwordTo?: string;
  passwordText?: string;
}

const FormContainer = ({
  title,
  subtitle,
  formTitle,
  children,
  accountText,
  accountTo,
  accountToText,
  backgroundImage,
  passwordTo,
  passwordText,
}: IFormContainer) => {
  return (
    <Container>
      <ImageWrapper backgroundImage={backgroundImage}>
        <ImageTextWrapper>
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </ImageTextWrapper>
      </ImageWrapper>
      <FormWrapper>
        <h3>{formTitle}</h3>
        {children}
        <div className="account">
          <p>
            {accountText}{" "}
            <Link className="account-link" to={accountTo}>
              {accountToText}
            </Link>
          </p>
        </div>
        {passwordTo && passwordText && (
          <div className="account">
            <p>
              Forgot Password?{" "}
              <Link className="account-link" to={passwordTo}>
                {passwordText}
              </Link>
            </p>
          </div>
        )}
      </FormWrapper>
    </Container>
  );
};

export default FormContainer;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;

  @media Screen and (max-width: ${screens.tab}) {
    flex-direction: column;
    height: fit-content;
  }
`;

const ImageWrapper = styled.div<IImageWrapper>`
  display: flex;
  width: 75%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.backgroundImage});

  @media Screen and (max-width: ${screens.tab}) {
    width: 90%;
    margin: 2rem auto 0 auto;
    height: fit-content;
  }
`;

const ImageTextWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: auto auto 2rem 2rem;
  backdrop-filter: blur(10px);
  background-color: rgba(8, 37, 82, 0.8);
  color: ${colors.white};

  @media Screen and (max-width: ${screens.tab}) {
    width: 90%;
    margin: 2rem auto;
    backdrop-filter: blur(25px);
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
  }

  p {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 2rem 0 2rem;
  padding-bottom: 2rem;
  gap: 1rem;
  width: 25%;
  overflow: scroll;

  @media Screen and (max-width: ${screens.tab}) {
    width: 90%;
    margin: 2rem auto;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
    color: ${colors.dark};
  }

  .account {
    margin-bottom: 0;

    p {
      font-size: 0.8rem;

      @media Screen and (max-width: ${screens.tab}) {
        font-size: 1rem;
      }
    }

    .account-link {
      font-size: 0.8rem;

      @media Screen and (max-width: ${screens.tab}) {
        font-size: 1rem;
      }
    }
  }
`;
