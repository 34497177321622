import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";
import { useGetSchoolsEnrolledToActivityQuery } from "../../app/services/providerApi";
import { useAppSelector } from "../../app/hooks";
import { selectProvider } from "../../app/features/providerSlice";
import google from "../../assets/images/google-calendar-icon.png";

const ProviderSchoolsEnrolled = () => {
  const navigate = useNavigate()
  const { id: providerId } = useAppSelector(selectProvider);
  const { id } = useParams();
  const { data } = useGetSchoolsEnrolledToActivityQuery({
    providerId: providerId,
    activityId: id,
  });

  const addSchedule = () => {
    navigate("schedule")
  }

  console.log(data);

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          {data?.map((school: any) => {
            return (
              <SchoolCard key={school._id}>
                <h2>{school.name}</h2>
                <p>
                  <strong>Address:</strong> {school.address}
                </p>
                <p>
                  <strong>Email:</strong> {school.email}
                </p>
                <p>
                  <strong>Website:</strong> {school.website}
                </p>
                <p>
                  <strong>Phone No:</strong> {school.phoneNo}
                </p>
                <p>
                  <strong>Country:</strong> {school.country}
                </p>
                <p>
                  <strong>State:</strong> {school.state}
                </p>
                <p>
                  <strong>LGA:</strong> {school.lga}
                </p>

                {school.headOfSchool && (
                  <>
                    <h3>Head of School</h3>
                    <p>
                      <strong>Name:</strong> {school.headOfSchool.name}
                    </p>
                    <img
                      src={school.headOfSchool.signature}
                      alt="Head of School Signature"
                      width="100"
                    />
                  </>
                )}

                {/* <h3>Activities Enrolled</h3>
                {school.activities.length > 0 ? (
                  school.activities.map((activityObj: any) => (
                    <div key={activityObj._id}>
                      <p>
                        <strong>Activity Title:</strong> {activityObj.activity.title}
                      </p>
                      <p>
                        <strong>Description:</strong> {activityObj.activity.description}
                      </p>
                      <p>
                        <strong>Mode:</strong> {activityObj.activity.mode}
                      </p>
                      <p>
                        <strong>Price:</strong> {activityObj.activity.price}
                      </p>
                      <img
                        src={activityObj.activity.avatar}
                        alt={`${activityObj.activity.title} Avatar`}
                        width="100"
                      />
                    </div>
                  ))
                ) : (
                  <p>No activities enrolled.</p>
                )} */}

                <h3>Students</h3>
                <p>{school.students.length}</p>
                {/* {school.students.length > 0 ? (
                  school.students.map((student: any) => (
                    <div key={student._id}>
                      <p>
                        <strong>Name:</strong> {student.fullName}
                      </p>
                      <p>
                        <strong>Class:</strong> {student.studentClass}
                      </p>
                      <p>
                        <strong>Gender:</strong> {student.gender}
                      </p>
                      {student.avatar && (
                        <img
                          src={student.avatar}
                          alt={`${student.fullName} Avatar`}
                          width="100"
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <p>No students enrolled.</p>
                )} */}
              </SchoolCard>
            );
          })}

          <ScheduleButton onClick={addSchedule}>
            <IconWrapper>
              <img src={google} alt="" />
            </IconWrapper>
            <ButtonText>Schedule a Session</ButtonText>
          </ScheduleButton>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default ProviderSchoolsEnrolled;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const SchoolCard = styled.div`
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;

  img {
    margin-top: 0.5rem;
  }

  h2,
  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0.2rem 0;
  }
`;

const ScheduleButton = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.5rem 1.2rem 0.5rem 0.5rem;
  border: 1px solid ${colors.grey};
  border-radius: 0.5rem;
  cursor: pointer;
  gap: 0.5rem;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 3rem;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

const ButtonText = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
  color: ${colors.black};
`;
