import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <PaginationWrapper>
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <IoIosArrowDropleft size="2rem" />
      </Button>

      <PageInfo>
        Page {currentPage} of {totalPages}
      </PageInfo>
      <Button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <IoIosArrowDropright size="2rem" />
      </Button>
    </PaginationWrapper>
  );
};

export default Pagination;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 30%;
  margin: auto;
  gap: 2rem;

  @media (max-width: ${screens.mobile}) {
    width: 100%;
  }
`;

const PageInfo = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const Button = styled.button`
  display: flex;
  width: fit-content;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;
