import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { PiTagChevronThin } from "react-icons/pi";
import { TiLocationArrowOutline } from "react-icons/ti";
import { colors, screens } from "../../../utils";
import { Button } from "../../FormElements";

interface IActivity {
  linkTo: string;
  title: string;
  mode: string;
}

const Activity = ({ linkTo, title, mode }: IActivity) => {
  const navigate = useNavigate();

  const handleViewResults = () => {
    navigate(`results/${linkTo}`);
  };

  return (
    <Container>
      <ActivityLink to={linkTo}>
        <Wrapper>
          <Title>
            <PiTagChevronThin size="0.8rem" color={colors.dark} />
            <p>{title}</p>
          </Title>

          <Mode>
            <IconWrapper>
              <TiLocationArrowOutline size="0.9rem" color={colors.dark} />
            </IconWrapper>
            <ModeText>{mode}</ModeText>
          </Mode>
        </Wrapper>
      </ActivityLink>
      <ButtonWrapper>
        <Button
          color={colors.white}
          backgroundColor={colors.primary}
          border="none"
          onClick={handleViewResults}
          text="View Results"
        />
      </ButtonWrapper>
    </Container>
  );
};

export default Activity;

const Container = styled.div`
  display: flex;
  //flex-direction: column;
  border-top: 0.5px solid ${colors.grey};
  border-bottom: 0.5px solid ${colors.grey};

  &:hover {
    border: 0.5px solid ${colors.grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const ActivityLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0.5rem;
  gap: 1rem;
`;

const Title = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    margin: auto 1rem auto 0;
    display: flex;
    width: 100%;
    text-transform: capitalize;
    word-wrap: break-word;

    @media (max-width: ${screens.minitab}) {
      width: 80%;
      margin: auto;
    }
  }
`;

const ModeText = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.dark};
  display: flex;
  width: 100%;
  text-transform: capitalize;

  strong {
    font-size: 0.8rem;
    font-weight: 700;
    color: ${colors.dark};
    display: flex;
    margin-right: 0.3rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const Mode = styled.div`
  display: flex;
  width: fit-content;
  margin-left: auto;
  gap: 0.4rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 15%;
  margin: 0.5rem 0;

  @media (max-width: ${screens.tab}) {
    width: fit-content;
  }
`;
