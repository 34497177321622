import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { useAppDispatch } from "../../../app/hooks";
import Form from "../../FormElements/Form";
import { Button, Input, Label, PasswordInput } from "../../FormElements";
import useForm, { FormValues } from "../../../hooks/useForm";
import { colors } from "../../../utils";
import PasswordCheck from "../../../utils/PasswordCheck";
import { CustomError } from "../../../types";
import Spinner from "../../../utils/Spinner";
import { useSignupParentMutation } from "../../../app/services/parentApi";
import { setParent } from "../../../app/features/parentSlice";

const initialState: FormValues = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNo: "",
  password: "",
  confirmPassword: "",
};

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formValues, handleInputChange } = useForm(initialState);
  const [passwordType, setPasswordType] = useState("password");
  const {
    email,
    firstName,
    lastName,
    phoneNo,
    country,
    state,
    lga,
    password,
    confirmPassword,
  } = formValues;
  const [parentSignUp, { data, isLoading, isSuccess, isError, error }] =
    useSignupParentMutation();

  //Toggle Password visibility

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (firstName && lastName && email && password && phoneNo) {
      await parentSignUp({
        firstName,
        lastName,
        email,
        phoneNo,
        password,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setParent({
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNo: data.phoneNo,
          token: data.token,
        })
      );
      navigate("/parent-dashboard/home");
      toast.success("Hello today. Welcome!");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Form onSubmit={handleSubmit}>
      <ItemWrapper>
        <Label text="First Name" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={firstName}
          name="firstName"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Last Name" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={lastName}
          name="lastName"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Email" fontSize="1rem" color={colors.dark} />
        <Input
          type="email"
          value={email}
          name="email"
          onChange={handleInputChange}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Label text="Phone No" fontSize="1rem" color={colors.dark} />
        <Input
          type="string"
          value={phoneNo}
          name="phoneNo"
          onChange={handleInputChange}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Label text="Password" fontSize="1rem" color={colors.dark} />
        <PasswordInput
          type={passwordType}
          placeholder="Password"
          name="password"
          value={password}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />

        <Label text="Confirm Password" fontSize="1rem" color={colors.dark} />
        <PasswordInput
          type={passwordType}
          placeholder="Confirm Password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />
        <PasswordCheck password={password} />
      </ItemWrapper>

      <Button
        type="submit"
        text={isLoading ? <Spinner color={colors.white} /> : "Sign up"}
        color={colors.white}
        backgroundColor={colors.primary}
        border="none"
        disabled={isLoading}
      />
    </Form>
  );
};

export default SignUp;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
