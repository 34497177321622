import { styled } from "styled-components";

interface ITabs {
  children: any;
  renderNavItems: any;
}

const TabsWrapper = ({ children, renderNavItems }: ITabs) => {
  return (
    <Container>
      <Wrapper>{renderNavItems && renderNavItems()}</Wrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Container>
  );
};

export default TabsWrapper;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
`;
