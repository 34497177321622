import React from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { styled } from 'styled-components';
import { colors, screens } from '../../utils';


interface IPassword {
    type: string;
    value: string | number;
    name: string;
    placeholder?: string;
    passwordType: string;
    onChange: any;
    onClick: () => void;
    accept?: any;
  }

const PasswordInput = ({
    type,
    value,
    name,
    placeholder,
    passwordType,
    onChange,
    onClick,
  }: IPassword) => {
  return (
    <PasswordInputContainer>
      <input
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
      <span onClick={onClick}>
        {passwordType === "password" ? (
          <AiOutlineEye color={colors.grey} />
        ) : (
          <AiOutlineEyeInvisible color={colors.grey} />
        )}
      </span>
    </PasswordInputContainer>
  )
}

export default PasswordInput

const PasswordInputContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  outline: none;
  font-size: 1rem;
  padding: 0.4rem;
  border: 1px solid ${colors.primary};
  border-radius: 0.25rem;
  width: 100%;

  input {
    outline: none;
    border: none;
    display: flex;
    background-color: transparent;
    padding: 0 0.4rem;
    font-size: 0.8rem;
    flex: 1;

    @media Screen and (max-width: ${screens.tab}) {
      font-size: 1rem;
    }

    ::placeholder {
      color: grey;
    }
  }

  span {
    border-left: 1px solid ${colors.primary};
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0 0.4rem;
    margin: 0;
  }
`;