import { styled } from "styled-components";
import { PiTagChevronThin } from "react-icons/pi";
import { colors, screens } from "../../../utils";

interface ICurriculum {
  url: string;
  title: string;
}

const Curriculum = ({ url, title }: ICurriculum) => {
  return (
    <Container>
      <Wrapper href={url} target="_blank" rel="noopener noreferrer">
        <TitleWrapper>
          <PiTagChevronThin size="0.8rem" color={colors.dark} />
          <p>{title}</p>
        </TitleWrapper>
      </Wrapper>
    </Container>
  );
};

export default Curriculum;

const Container = styled.div`
  display: flex;
  align-items: center;

  border-top: 0.5px solid ${colors.grey};
  border-bottom: 0.5px solid ${colors.grey};

  &:hover {
    border: 0.5px solid ${colors.grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const Wrapper = styled.a`
  display: flex;
  width: 100%;
  margin: 0.5rem;
  gap: 1rem;
  text-decoration: none;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    margin: auto 1rem auto 0;
    display: flex;
    width: 100%;
    text-transform: capitalize;
    word-wrap: break-word;

    @media (max-width: ${screens.minitab}) {
      width: 80%;
      margin: auto;
    }
  }
`;


