import React from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import SignIn from "./SignIn";
import { FormContainer } from "../../FormElements";
import signinImage from "../../../assets/images/collaboration.jpeg";

const Admin = () => {
  const location = useLocation();
  const isSignInRoute = location.pathname === "/sign-in/admin-signin";

  return (
    <Wrapper>
      {isSignInRoute && (
        <FormContainer
          title="End to End Extracurricular Activities Support Platform"
          subtitle="extrabox.club is an innovative schools-students extracurricular activities platform that offer students scholarships for their achievements during elementary and high school."
          formTitle="Login for Admins"
          accountText="Forgot your password?"
          accountTo="/admin-password-code"
          accountToText="Update it here"
          backgroundImage={signinImage}
        >
          <SignIn />
        </FormContainer>
      )}
    </Wrapper>
  );
};

export default Admin;

const Wrapper = styled.div`
  display: 100%;
  width: 100%;
`;
