import React, { useState } from "react";
import { styled } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { colors, screens } from "../../utils";
import { selectParent } from "../../app/features/parentSlice";

const ParentScreens = () => {
  const { firstName } = useAppSelector(selectParent);

  return (
    <Container>
      <Wrapper>
        <h1>Welcome to the parent dashboard, {firstName}</h1>
      </Wrapper>
    </Container>
  );
};

export default ParentScreens;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;
