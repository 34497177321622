import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../../../utils";
//import src from "../../../../../assets/images/child-with-guitar.png";
import src from "../../../../../assets/images/playing-chess.jpeg";
import src1 from "../../../../../assets/images/karate.png";
import { Button } from "../../../../FormElements";
import { useNavigate } from "react-router-dom";

const TwoColumns = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate("/sign-up")
  };

  return (
    <Container>
      <Wrapper>
        <Column>
          <Content>
            <h1>Africa's Extracurricular Platform for Schools</h1>
            <p>
              The one-stop shop for all exracurricular activities for schools,
              kids and service providers. Find all you need with ease all in one
              place. Manage your students on the platform and connect with
              vetted providers with ease.
            </p>
            <div className="button-wrapper">
              <Button
                text="Sign up now"
                backgroundColor={colors.primary}
                color={colors.white}
                onClick={handleClick}
                border="none"
              />
            </div>
          </Content>
        </Column>
        <Column>
          <ImageWrapper>
            <img src={src} alt="" />
          </ImageWrapper>
        </Column>
      </Wrapper>
    </Container>
  );
};

export default TwoColumns;

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.blueBack};

  @media Screen and (max-width: ${screens.tab}) {
    background-color: ${colors.blueBack};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 3rem auto;
  gap: 1rem;

  @media Screen and (max-width: ${screens.tab}) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const Column = styled.div`
  display: flex;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  gap: 1rem;
  margin: auto;

  @media Screen and (max-width: ${screens.tab}) {
    text-align: center;
    width: 100%;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 800;
    color: ${colors.black};
    line-height: 1.3;

    @media Screen and (max-width: ${screens.tab}) {
      font-size: 2.3rem;
    }
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    color: ${colors.black};
    line-height: 1.4;
  }

  .button-wrapper {
    width: 30%;

    @media Screen and (max-width: ${screens.tab}) {
      margin: auto;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  border-radius: 0.8rem;

  @media Screen and (max-width: ${screens.tab}) {
    height: 50vh;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;

    &:hover {
      scale: 1.1;
      transition: all 0.2s ease-in-out;
    }
  }
`;
