import React from "react";
import { useGetActivitiesByProvidersQuery } from "../../app/services/schoolApi";
import { styled } from "styled-components";
import Spinner from "../../utils/Spinner";
import { colors } from "../../utils";
import { Link } from "react-router-dom";

const Marketplace = () => {
  const { data, isLoading, isError } = useGetActivitiesByProvidersQuery({});

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.primary} />
        ) : isError ? (
          <p>Oops!! Something went wrong</p>
        ) : data && data.length > 0 ? (
          data.map((activity: any) => (
            <ActivityLink key={activity._id} to={`activities/${activity._id}`}>
              <h3>{activity.title}</h3>
            </ActivityLink>
          ))
        ) : (
          <p>No activities available.</p>
        )}
      </Wrapper>
    </Container>
  );
};

export default Marketplace;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 2rem auto;
`;

const ActivityLink = styled(Link)`  // Styled Link component
  text-decoration: none;
  color: ${colors.black};
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }

  h3 {
    margin: 0;
  }
`;
