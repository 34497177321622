import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useGetUniversitiesQuery } from "../../app/services/partnerApi";
import { useAppSelector } from "../../app/hooks";
import { selectPartner } from "../../app/features/partnerSlice";
import { colors, screens } from "../../utils";
import { Button } from "../FormElements";
import Modal from "../Modal";
import { Link } from "react-router-dom";
import { BsFillCircleFill } from "react-icons/bs";
import Spinner from "../../utils/Spinner";
import NoData from "../../utils/NoData";
import AddUniversity from "./AddUniversity";

const UniversitiesList = () => {
  const { id } = useAppSelector(selectPartner);
  const { data, isLoading, isError } = useGetUniversitiesQuery(id);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>("thisMonth");
  const [filteredData, setFilteredData] = useState<any[]>([]);

  // Function to handle changing the filter
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(e.target.value);
  };

  // Filter data based on selectedFilter
  useEffect(() => {
    if (!data) return;

    const filtered = data.filter((university: any) => {
      const createdAt = new Date(university.createdAt);
      const start = new Date(selectedFilter.split("_")[0]);
      const end = new Date(selectedFilter.split("_")[1]);

      return createdAt >= start && createdAt <= end;
    });

    setFilteredData(filtered);
  }, [data, selectedFilter]);

  // Function to handle opening the modal for adding a new university
  const handleAddUniversity = () => {
    setIsOpen(true);
  };

  const getDateRanges = () => {
    // Current date
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    // Start date (earliest possible date)
    const startDate = new Date(1900, 0, 1);

    // End date (tomorrow)
    const endDate = new Date();
    endDate.setDate(today.getDate() + 1);

    // Options for select
    const dateRanges = [
      {
        label: "All Time",
        value: `${formatDateString(startDate)}_${formatDateString(endDate)}`,
      },
      {
        label: "Current Month",
        value: `${formatDateString(
          new Date(currentYear, currentMonth - 1, 1)
        )}_${formatDateString(endDate)}`,
      },
      {
        label: "2 Months",
        value: `${formatDateString(
          new Date(currentYear, currentMonth - 2, 1)
        )}_${formatDateString(endDate)}`,
      },
      {
        label: "3 Months",
        value: `${formatDateString(
          new Date(currentYear, currentMonth - 3, 1)
        )}_${formatDateString(endDate)}`,
      },
      {
        label: "4 Months",
        value: `${formatDateString(
          new Date(currentYear, currentMonth - 4, 1)
        )}_${formatDateString(endDate)}`,
      },
      {
        label: "5 Months",
        value: `${formatDateString(
          new Date(currentYear, currentMonth - 5, 1)
        )}_${formatDateString(endDate)}`,
      },
      {
        label: "6 Months",
        value: `${formatDateString(
          new Date(currentYear, currentMonth - 6, 1)
        )}_${formatDateString(endDate)}`,
      },
      // Add other date range options here...
    ];

    return dateRanges;
  };

  // Function to format date string as MM/DD/YYYY
  const formatDateString = (date: Date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  // Generate date range options
  const dateRanges = getDateRanges();

  return (
    <Container>
      <Wrapper>
        <h3>Universities List</h3>
        <FilterSection>
          <h3>Filter by Date</h3>
          <select value={selectedFilter} onChange={handleFilterChange}>
            {dateRanges.map((range, index) => (
              <option key={index} value={range.value}>
                {range.label}
              </option>
            ))}
          </select>
        </FilterSection>

        <Wraps>
          {isLoading ? (
            <Spinner color={colors.grey} />
          ) : isError ? (
            <p>Oops! Something went wrong. Try again</p>
          ) : !data ? (
            <NoData iconColor={colors.grey} textColor={colors.grey} />
          ) : (
            <ItemWrapper>
              {selectedFilter === "thisMonth" ? (
                data.map((university: any) => (
                  <Item
                    key={university._id}
                    to={`/partner-dashboard/universities/${university._id}`}
                  >
                    <div className="heading">
                      <BsFillCircleFill color={colors.dark} size="0.5rem" />
                      <p>{university.universityName}</p>
                    </div>

                    <small>{university.email}</small>
                  </Item>
                ))
              ) : filteredData.length === 0 ? (
                <NoData iconColor={colors.grey} textColor={colors.dark} />
              ) : (
                filteredData.map((university: any) => (
                  <Item
                    key={university._id}
                    to={`/partner-dashboard/universities/${university._id}`}
                  >
                    <div className="heading">
                      <BsFillCircleFill color={colors.dark} size="0.5rem" />
                      <p>{university.firstName}</p>
                    </div>

                    <small>{university.email}</small>
                  </Item>
                ))
              )}
            </ItemWrapper>
          )}

          <SectionWrapper>
            <p>No of Universities you have onboarded: {data && data.length}</p>

            <ButtonWrapper>
              <Button
                color={colors.white}
                border="none"
                backgroundColor={colors.primary}
                text="Add a University"
                onClick={handleAddUniversity}
              />
            </ButtonWrapper>

            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
              <AddUniversity closeModal={() => setIsOpen(false)} />
            </Modal>
          </SectionWrapper>
        </Wraps>
      </Wrapper>
    </Container>
  );
};

export default UniversitiesList;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 1rem;
  margin: 1rem auto;
`;

const Wraps = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h3 {
    font-size: 0.9rem;
    color: ${colors.dark};
  }

  select {
    padding: 0.3rem;
    border-radius: 0.25rem;
    border: 1px solid ${colors.primary};
    font-size: 0.7rem;
    color: ${colors.dark};
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }

  h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: ${colors.dark};
  }
`;

const Item = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-decoration: none;
  background-color: ${colors.blueSec};
  padding: 0.6rem 0.6rem;
  border-radius: 0.15rem;
  align-items: center;

  .heading {
    display: flex;
    gap: 0.8rem;
    align-items: center;

    p {
      font-size: 0.9rem;
      color: ${colors.black};
      font-weight: 600;
    }
  }

  small {
    font-size: 0.7rem;
    color: ${colors.black};
    font-style: italic;

    strong {
      font-size: 0.7rem;
      color: ${colors.black};
      font-style: italic;
    }
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-left: 1.5rem;
  border-left: 1px solid ${colors.primary};
  gap: 1rem;

  p {
    margin: auto;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.4;
    color: ${colors.dark};
  }

  @media (max-width: ${screens.tab}) {
    width: 100%;
    border-left: none;
    padding-left: 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 70%;
  margin: auto;

  @media Screen and (max-width: ${screens.minitab}) {
    width: 80%;
  }
`;
