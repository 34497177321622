import React from "react";
import { styled } from "styled-components";
import UserSectionCard from "./UserSectionCard";
import { colors, screens } from "../../../../utils";
import src1 from "../../../../assets/images/collaboration.png";
import src2 from "../../../../assets/images/child-with-bubble.jpeg";
import src3 from "../../../../assets/images/chess.jpeg";

const data = [
  {
    id: 1,
    src: src1,
    to: "#",
    title: "Become an ExtraBox Partner and earn",
  },
  {
    id: 2,
    src: src2,
    to: "#",
    title: "Tap ExtraBox activities for your school",
  },
  {
    id: 3,
    src: src3,
    to: "#",
    title: "Find a ready market for your services",
  },
];

const UsersSection = () => {
  return (
    <Container>
      <Wrapper>
        {data.map((card: any) => {
          return (
            <UserSectionCard
              key={card.id}
              to={card.to}
              src={card.src}
              title={card.title}
            />
          );
        })}
      </Wrapper>
    </Container>
  );
};

export default UsersSection;

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.offWhite};
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  gap: 2rem;

  @media (max-width: ${screens.minitab}) {
    flex-direction: column;
    gap: 1rem;
  }
`;
