import React, { useEffect, useState } from "react";
import { listFilesWithTitles } from "../../../lib/firebase-client";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import styled from "styled-components";
import Curriculum from "./Curriculum";

const CurriculumList = () => {
  const [curriculums, setCurriculums] = useState<
    { title: string; url: string }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCurriculums = async () => {
      try {
        const files = await listFilesWithTitles("curriculums");
        setCurriculums(files);
      } catch (error) {
        setError("Failed to fetch curriculums.");
      } finally {
        setLoading(false);
      }
    };

    fetchCurriculums();
  }, []);

  if (loading) {
    return <Spinner color={colors.grey} />;
  }

  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  return (
    <Container>
      <Wrapper>
        <Curriculums>
          {curriculums.map((file, index) => (
            <Curriculum key={index} url={file.url} title={file.title} />
          ))}
        </Curriculums>
      </Wrapper>
    </Container>
  );
};

export default CurriculumList;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem auto;
  flex-direction: column;
  gap: 1rem;
`;

const Curriculums = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
