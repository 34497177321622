import React from "react";
import { styled } from "styled-components";
import { colors } from "../../../utils";
import { Button } from "../../FormElements";
import { MdOutlineCloudDownload } from "react-icons/md";
import CurriculumList from "../../AdminDashboard/AdminScreens/CurriculumList";

const SchoolCurriculum = () => {
  return (
    <Container>
      <Wrapper>
        <h3>Download Curriculum</h3>
        <CurriculumList />

        {/* <ButtonWrapper>
          <Button
            text={
              <TextWrapper>
                <MdOutlineCloudDownload />
                <p>Download</p>
              </TextWrapper>
            }
            color={colors.white}
            backgroundColor={colors.primary}
            border="none"
          />
        </ButtonWrapper> */}
      </Wrapper>
    </Container>
  );
};

export default SchoolCurriculum;

const Container = styled.div`
  display: flex;
  width: 100%;
  text-decoration: none;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  flex-direction: column;
  gap: 0.5rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
`;
