import { storage, firestore } from "../lib/firebase-client";

export const uploadCurriculum = (file: File, title: string, onProgress: (progress: number) => void): Promise<string> => {
  return new Promise((resolve, reject) => {
    const storageRef = storage.ref(`curriculums/${file.name}`);
    const uploadTask = storageRef.put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          const docRef = firestore.collection("curriculums").doc(file.name); 
          await docRef.set({
            title,
            url: downloadURL,
            createdAt: new Date(),
          });
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};
