import React from "react";
import { styled } from "styled-components";
import thumbnail1 from "../../../assets/images/handshake.jpeg";
import thumbnail2 from "../../../assets/images/child-with-bubble.jpeg";
import thumbnail3 from "../../../assets/images/child-with-guitar.png";
import { useGetAllLearningsQuery } from "../../../app/services/learningApi";
import Spinner from "../../../utils/Spinner";
import { colors, screens } from "../../../utils";
import NoData from "../../../utils/NoData";
import LearningCard from "../../PartnerScreens/PartnerComponents/LearningCard";
import { Link } from "react-router-dom";

const AdminResources = () => {
  const { data, isLoading, isSuccess, isError } = useGetAllLearningsQuery({});
  const thumbnails = [thumbnail1, thumbnail2, thumbnail3];

  return (
    <Container>
      <Wrapper>
        <HeadingWrapper>
          <h2>Learning Resources</h2>
          <ButtonWrapper to="add-learning">Add a new Resource</ButtonWrapper>
        </HeadingWrapper>

        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Oops! Something went wrong. Try again</p>
        ) : !data ? (
          <NoData iconColor={colors.grey} textColor={colors.grey} />
        ) : (
          data && (
            <LearningWrapper>
              {data.map((learn: any) => {
                const randomThumbnail =
                  thumbnails[Math.floor(Math.random() * thumbnails.length)];

                return (
                  <LearningCard
                    key={learn._id}
                    to={`/admin-dashboard/learning/${learn._id}`}
                    title={learn.title}
                    subtitle={learn.subtitle}
                    thumbnail={randomThumbnail || learn.thumbnail}
                  />
                );
              })}
            </LearningWrapper>
          )
        )}
      </Wrapper>
    </Container>
  );
};

export default AdminResources;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1rem auto 2rem;
  gap: 1rem;
`;

const HeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWrapper = styled(Link)`
  background-color: transparent;
  color: ${colors.white};
  background-color: ${colors.green};
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  width: fit-content;
  text-decoration: none;
  font-size: 0.9rem;
`;

const LearningWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${screens.microtab}) {
    grid-template-columns: 1fr;
  }
`;
