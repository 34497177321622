import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

//Base URL
const url = process.env.REACT_APP_BASE_URI as string;

export const learningApi = createApi({
  reducerPath: "learningApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}learning/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.admin.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Learning"],
  endpoints: (builder) => ({
    // Add an Admin
    addLearning: builder.mutation({
      query: (data) => {
        console.log("Data in addLearning mutation:", data); // <-- Add this line
        return {
          url: "add-new",
          method: "POST",
          body: data,
        };
      },
    }),


    //Get all providers
    getAllLearnings: builder.query({
      query: () => "",
      providesTags: ["Learning"],
    }),

    // Get a single admin by ID
    getLearningById: builder.query({
      query: (id) => `${id}`,
      providesTags: ["Learning"],
    }),
    
    //...
  }),
});

export const {
  useAddLearningMutation,
  useGetAllLearningsQuery,
  useGetLearningByIdQuery,
} = learningApi;
