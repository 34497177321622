import React, { useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { CiLogout, CiUser, CiSettings } from "react-icons/ci";
import { RxCaretDown } from "react-icons/rx";
import { colors, screens } from "../../../utils";

interface IconWrapperProps {
  isOpen: boolean;
}

interface TopBarProps {
  firstName: string;
  lastName: string;
  title: string;
  handleLogout: () => void;
  linkTo: string;
}

const TopBar = ({
  firstName,
  lastName,
  title,
  handleLogout,
  linkTo,
}: TopBarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const iconStyle = { fontSize: "1rem" };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ProfileWrapper>
      <Profile onClick={toggle}>
        <DashLink to="#">
          <p>{firstName && firstName.slice(0, 1)}</p>
        </DashLink>
        <IconWrapper isOpen={isOpen}>
          <RxCaretDown />
        </IconWrapper>
      </Profile>
      {isOpen && (
        <ListWrapper>
          <ListItem to={`${linkTo}/settings`}>
            <div className="icon">
              <CiUser style={iconStyle} />
            </div>
            <p>Profile</p>
          </ListItem>
          <ListItem to={`${linkTo}/settings`}>
            <div className="icon">
              <CiSettings style={iconStyle} />
            </div>
            <p>Settings</p>
          </ListItem>
          <LogoutItem onClick={handleLogout}>
            <div className="icon">
              <CiLogout style={iconStyle} />
            </div>
            <p>Logout</p>
          </LogoutItem>
        </ListWrapper>
      )}
    </ProfileWrapper>
  );
};

export default TopBar;

const ProfileWrapper = styled.div`
  position: relative;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const IconWrapper = styled.div<IconWrapperProps>`
  font-size: 1.5rem;
  margin-left: 0.5rem;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease;
`;

const DashLink = styled(Link)`
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 0.2rem;
  background-color: ${colors.bluePry};
  width: 2rem;
  height: 2rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  p {
    color: ${colors.white};
    font-weight: 700;
    font-size: 1rem;
    margin: auto;
    padding: 0;
    display: flex;
  }
`;

const ListWrapper = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 0;
  background-color: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 25%);
  padding: 0.6rem 0.4rem;
  z-index: 1000;

  @media (max-width: ${screens.tab}) {
    right: 0.2rem;
  }
`;

const ListItem = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.3rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  gap: 0.5rem;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.black};
  }

  p {
    font-size: 0.8rem;
    color: ${colors.black};
  }

  &:hover {
    background-color: ${colors.lightTer};
    transition: background-color 0.3s linear;
  }
`;

const LogoutItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.3rem;
  border-radius: 0.5rem;
  cursor: pointer;
  gap: 0.5rem;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.black};
  }

  p {
    font-size: 0.8rem;
    color: ${colors.black};
  }

  &:hover {
    background-color: ${colors.lightTer};
    transition: background-color 0.3s linear;
  }
`;
