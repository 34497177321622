import { useEffect, useState } from "react";

export const useFetchStatesAndLgas = (initialState: string) => {
  const [states, setStates] = useState<string[]>([]);
  const [lgas, setLgas] = useState<string[]>([]);
  const [selectedState, setSelectedState] = useState(initialState);

  useEffect(() => {
    const fetchStates = async () => {
      const url = "https://nga-states-lga.onrender.com/fetch";
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await fetch(url, options);
        if (response.ok) {
          const result = await response.json();
          setStates(result);
        } else {
          console.error("Failed to fetch states:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const fetchLgas = async () => {
        const url = `https://nga-states-lga.onrender.com/?state=${selectedState}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        try {
          const response = await fetch(url, options);
          if (response.ok) {
            const result = await response.json();
            setLgas(result);
          } else {
            console.error("Failed to fetch LGAs:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching LGAs:", error);
        }
      };

      fetchLgas();
    }
  }, [selectedState]);

  return { states, lgas, setSelectedState };
};
