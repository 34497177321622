import React, { useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useGetAllSchedulesQuery } from "../../../app/services/schoolApi";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import styled from "styled-components";
import Modal from "../../Modal";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";

const localizer = momentLocalizer(moment);

const SchoolSchedules = () => {
  const { id } = useAppSelector(selectSchool);
  const { data, isLoading, isSuccess, isError } = useGetAllSchedulesQuery(id);

  // State to manage selected session and modal visibility
  const [selectedSession, setSelectedSession] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Transform the schedules into the format required by the Calendar component
  const events = useMemo(() => {
    if (isSuccess && data) {
      return data.map((schedule: any) => ({
        id: schedule._id,
        title: schedule.title,
        start: new Date(schedule.date),
        end: new Date(schedule.date),
        description: schedule.description,
      }));
    }
    return [];
  }, [data, isSuccess]);

  // Handle event selection
  const handleSelectEvent = (event: any) => {
    setSelectedSession(event); // Store selected session details
    setIsModalOpen(true); // Open the modal
  };

  // Close modal handler
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSession(null); // Clear selected session when modal closes
  };

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Error loading data. Try again please!!!</p>
        ) : data ? (
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "100%", width: "100%" }}
            defaultView="month"
            tooltipAccessor={(event: any) => event.description}
            onSelectEvent={handleSelectEvent}
          />
        ) : (
          <p>Oops! Something went wrong</p>
        )}
      </Wrapper>

      {/* Modal for displaying session details */}
      <Modal open={isModalOpen} onClose={closeModal}>
        {selectedSession && (
          <DetailsWrapper>
            <h2>{selectedSession.title}</h2>
            <p>
              <strong>Date:</strong>{" "}
              {moment(selectedSession.start).format("MMMM Do YYYY")}
            </p>
            <p>
              <strong>Description:</strong> {selectedSession.description}
            </p>
          </DetailsWrapper>
        )}
      </Modal>
    </Container>
  );
};

export default SchoolSchedules;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.lightBack};
  padding: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  background-color: ${colors.white};
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 2rem);
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  gap: 0.8rem;

  h2 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: ${colors.black};

    strong {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 600;
      color: ${colors.black};
    }
  }
`;
