import React from 'react'
import HomeLayout from '../../layouts/HomeLayout'
import Providers from '../../components/Authentication/Providers'

const ProviderAuth = () => {
  return (
    <HomeLayout>
      <Providers />
    </HomeLayout>
  )
}

export default ProviderAuth