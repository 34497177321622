import React from "react";
import { styled } from "styled-components";
import { colors } from "../../../utils";
import { Link } from "react-router-dom";

interface ILearningCard {
  title: string;
  subtitle: string;
  thumbnail: any;
  to: string;
}

const LearningCard = ({ to, title, subtitle, thumbnail }: ILearningCard) => {
  return (
    <Container to={to}>
      <Wrapper>
        <ImageWrapper>
          <img src={thumbnail} alt="" />
        </ImageWrapper>

        <TextWrapper>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};

export default LearningCard;

const Container = styled(Link)`
  display: flex;
  border: 1px solid ${colors.grey};
  border-radius: 0.25rem;
  text-decoration: none;
  width: 100%;
  height: 16rem !important;
  height: fit-content;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem;
  flex-direction: column;
  gap: 1rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 70%;
  border-radius: 0.25rem;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.dark};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const SubTitle = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: ${colors.dark};
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
