import React, { ChangeEvent, useState } from "react";
import { styled } from "styled-components";
import { Button, Input, Label } from "../../FormElements";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";

interface IGenerateCode {
  generateCode: any;
  isLoading: boolean;
}

const GeneratePasswordResetCode = ({
  generateCode,
  isLoading,
}: IGenerateCode) => {
  const [formValue, setFormValue] = useState({ email: "" });

  const { email } = formValue;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email) {
      await generateCode({ email });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label text="Provide the email address you registered with" />
      <Input
        type="email"
        value={email}
        name="email"
        onChange={handleInputChange}
      />
      <Button
        color={colors.white}
        type="submit"
        border="none"
        backgroundColor={colors.primary}
        text={isLoading ? <Spinner color={colors.white} /> : "Get Reset Code"}
        disabled={isLoading}
      />
    </Form>
  );
};

export default GeneratePasswordResetCode;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 18rem;
  height: fit-content;
  margin: 2rem auto;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2rem;
  gap: 1.5rem;

  @media Screen and (max-width: ${screens.minitab}) {
    width: 100%;
  }

  div {
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
  }
`;
