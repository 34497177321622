import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import { styled } from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { colors, screens } from "../../utils";

interface ModalProps {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ open, children, onClose }) => {
  const iconStyle = { fontSize: "1.2rem", color: colors.dark };

  if (!open) return null;
  return ReactDOM.createPortal(
    <>
      <Overlay onClick={onClose} />
      <ModalWrapper>
        <IconWrapper onClick={onClose}>
          <AiOutlineClose style={iconStyle} />
        </IconWrapper>

        <ModalContentWrapper>{children}</ModalContentWrapper>
      </ModalWrapper>
    </>,
    document.getElementById("portal")!
  );
};

export default Modal;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  border-radius: 0.5rem;
  position: fixed;
  width: 50vw;
  height: 70vh;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  z-index: 1000;
  color: ${colors.black};
  overflow: scroll;

  @media Screen and (max-width: ${screens.tab}) {
    width: 95%;
  }
`;

const IconWrapper = styled.button`
  position: absolute;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  right: 2%;
  top: 2%;
  cursor: pointer;
  display: flex;
  background-color: ${colors.blueLight};
  border: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

const ModalContentWrapper = styled.div`
  align-items: center;
  justify-content: center;
`;
