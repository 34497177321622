import React from 'react'
import HomeLayout from '../../layouts/HomeLayout'
import Parents from '../../components/Authentication/Parent'

const ParentAuth = () => {
  return (
    <HomeLayout>
      <Parents />
    </HomeLayout>
  )
}

export default ParentAuth