import React, { ReactNode } from "react";
import { styled } from "styled-components";
import ProviderLayout from "../../layouts/ProviderLayout";

interface IProviderDashboard {
  children: ReactNode;
}

const ProviderDashboard = ({ children }: IProviderDashboard) => {
  return (
    <ProviderLayout>
      <Container>{children}</Container>
    </ProviderLayout>
  );
};

export default ProviderDashboard;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem auto;
`;
