import { CSSProperties } from "react";
import styled from "styled-components";
import { PulseLoader } from "react-spinners";
import { colors } from "./colors";

interface ISpinner {
  color: string;
}

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Spinner({ color }: ISpinner) {
  return (
    <Container>
      <PulseLoader
        color={color}
        cssOverride={override}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Container>
  );
}

export default Spinner;

const Container = styled.div`
  display: flex;
  //position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* z-index: 900;
  top: 0;
  left: 0; */
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    height: 100%;
    background-color: transparent;
    //position: relative;
  }
`;
