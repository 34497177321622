import React, { ReactNode } from "react";
import { styled } from "styled-components";
import PartnerLayout from "../../layouts/PartnerLayout";

interface IPartnerDashboard {
  children: ReactNode;
}

const PartnerDashboard = ({ children }: IPartnerDashboard) => {
  return (
    <PartnerLayout>
      <Container>{children}</Container>
    </PartnerLayout>
  );
};

export default PartnerDashboard;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem auto;
`;
