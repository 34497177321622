import React from "react";
import { useGetSingleScheduleQuery } from "../../../app/services/schoolApi";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";

const ScheduleDetails = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { scheduleId, activityId } = useParams(); // Retrieve scheduleId and activityId from URL params

  // Fetch the specific schedule using schoolId, activityId, and scheduleId
  const { data, isLoading, isError } = useGetSingleScheduleQuery({
    schoolId,
    activityId,
    scheduleId,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading schedule details</div>;
  }

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Error loading data. Try again please!!!</p>
        ) : data ? (
          <DetailsWrapper>
            <h1>{data?.title}</h1>
            <p>
              <strong>Description:</strong> {data?.description}
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {data?.date && new Date(data.date).toLocaleDateString()}
            </p>
          </DetailsWrapper>
        ) : (
          <p>Oops! Something went wrong</p>
        )}
      </Wrapper>
    </Container>
  );
};

export default ScheduleDetails;

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${colors.lightBack};
  padding: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: ${colors.white};
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 2rem auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: ${colors.black};

    strong {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 600;
      color: ${colors.black};
    }
  }
`;
