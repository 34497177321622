// src/firebaseConfig.ts

import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

// Define the shape of your Firebase config object
interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
  appId: process.env.REACT_APP_FIREBASE_APP_ID!,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const storage = firebase.storage();
export const firestore = firebase.firestore();

// Helper function to list files recursively from Firebase Storage
const listFilesRecursively = async (folderPath: string): Promise<string[]> => {
  let fileUrls: string[] = [];

  try {
    // Reference to the folder in Firebase Storage
    const listRef = storage.ref(folderPath);
    const listResult = await listRef.listAll();

    // Iterate over the items in the folder and get their download URLs
    for (const itemRef of listResult.items) {
      const url = await itemRef.getDownloadURL();
      fileUrls.push(url);
    }

    // Recursively list files in subdirectories
    for (const prefix of listResult.prefixes) {
      const nestedUrls = await listFilesRecursively(prefix.fullPath);
      fileUrls = fileUrls.concat(nestedUrls);
    }
  } catch (error) {
    console.error("Error listing files:", error);
  }

  return fileUrls;
};

// Main function to list files, possibly from Firestore if needed
export const listFiles = async (folderPath: string): Promise<string[]> => {
  try {
    // Optional: Retrieve metadata from Firestore if you're storing file metadata there
    // Uncomment if you use Firestore to store metadata
    /*
    const metadataSnapshot = await firestore.collection(folderPath).get();
    const fileUrls = metadataSnapshot.docs.map(doc => doc.data().url);
    return fileUrls;
    */

    // Default to listing files directly from Firebase Storage
    return await listFilesRecursively(folderPath);
  } catch (error) {
    console.error("Error listing files:", error);
    return [];
  }
};

//List uploaded curriculums with titles
export const listFilesWithTitles = async (
  folderPath: string
): Promise<{ title: string; url: string }[]> => {
  try {
    // Fetch metadata from Firestore
    const metadataSnapshot = await firestore.collection(folderPath).get();
    const files = metadataSnapshot.docs.map((doc) => ({
      title: doc.data().title,
      url: doc.data().url,
    }));
    return files;
  } catch (error) {
    console.error("Error listing files with titles:", error);
    return [];
  }
};

// Function to get the number of items in the 'curriculums' collection
export const getCurriculumsCount = async (): Promise<number> => {
  try {
    // Reference to the 'curriculums' collection in Firestore
    const curriculumsCollectionRef = firestore.collection("curriculums");

    // Fetch the documents in the collection
    const snapshot = await curriculumsCollectionRef.get();

    // Return the number of documents in the collection
    return snapshot.size;
  } catch (error) {
    console.error("Error getting curriculums count:", error);
    return 0; // Return 0 or handle error as needed
  }
};

// Function to upload images
export const uploadImage = (file: File, folderPath: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Validate if the file is an image
    if (!file.type.startsWith('image/')) {
      reject(new Error("Only image files are allowed"));
      return;
    }

    const storageRef = storage.ref(`${folderPath}/${file.name}`);
    const uploadTask = storageRef.put(file, { contentType: file.type });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Track the upload progress if needed
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};
