import React, { FormEvent, useEffect, useState } from "react";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { Button, Form, Input, Label } from "../../FormElements";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import { CustomError } from "../../../types";
import { useUpdateHeadOfSchoolMutation } from "../../../app/services/schoolApi";
import { uploadImage } from "../../../lib/firebase-client";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";

interface IAddActivity {
  closeModal: () => void;
}

const UpdateHeadOfSchool = ({ closeModal }: IAddActivity) => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const [formValue, setFormValue] = useState({
    name: "",
    signature: null as File | null,
  });
  const [updateHeadOfSchool, { isLoading, isSuccess, isError, error }] =
    useUpdateHeadOfSchoolMutation();

  const { name, signature } = formValue;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      setFormValue({ ...formValue, [name]: files[0] });
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (name) {
      let signatureUrl = "";

      if (signature) {
        try {
          // Upload signature and get the URL
          signatureUrl = await uploadImage(
            signature,
            `schools/${schoolId}/signatures`
          );
        } catch (uploadError) {
          toast.error("Failed to upload signature. Please try again.");
          return;
        }
      }

      await updateHeadOfSchool({
        schoolId,
        headData: { name, signature: signatureUrl },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Head of School updated successfully");
      closeModal();
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Update Head of School</h3>
        <Form onSubmit={handleSubmit}>
          <Label text="Full Name" />
          <Input
            type="text"
            value={name}
            name="name"
            onChange={handleInputChange}
          />

          <ItemWrapper>
            <Label text="Signature" fontSize="1rem" color={colors.dark} />
            <Input
              type="file"
              name="signature"
              accept="image/*"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <Button
            color={colors.white}
            type="submit"
            border="none"
            backgroundColor={colors.primary}
            text={isLoading ? <Spinner color={colors.white} /> : "Save Changes"}
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default UpdateHeadOfSchool;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 0.6rem;
  margin: 1rem auto;

  @media (max-width: ${screens.tab}) {
    width: 80%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const SignatureWrapper = styled.div`
  display: flex;
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  border-radius: 0.4rem;
  border: 1px solid ${colors.primary};

  img {
    object-fit: contain;
  }
`;
