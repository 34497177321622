import React, { ReactNode } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Navigation from "../../components/common/Navigation";
import { logoutSchool, selectSchool } from "../../app/features/schoolSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const data = [
  {
    id: 1,
    title: "Dashboard",
    linkTo: "school-dashboard/home",
  },
  {
    id: 2,
    title: "Activities",
    linkTo: "school-dashboard/activities",
  },
  {
    id: 3,
    title: "Students",
    linkTo: "school-dashboard/students",
  },
  {
    id: 4,
    title: "Parents",
    linkTo: "school-dashboard/parents",
  },
  {
    id: 5,
    title: "Schedules",
    linkTo: "school-dashboard/schedules",
  },
  {
    id: 6,
    title: "Payments",
    linkTo: "school-dashboard/payments",
  },
];

interface ISchoolLayout {
  children: ReactNode;
}

const SchoolLayout = ({ children }: ISchoolLayout) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token: schoolToken, name } = useAppSelector(selectSchool);

  const handleLogout = () => {
    dispatch(logoutSchool());
    navigate("/sign-in/school-signin");
    toast.success("You successfully logged out");
  };

  return (
    <ProtectedRoute token={schoolToken} linkTo="/sign-in/school-signin">
      <Navigation
        token={schoolToken}
        firstName={name}
        clickLink="school-signup"
        buttonText="Sign in"
        navData={data}
        handleLogout={handleLogout}
      />
      {children}
    </ProtectedRoute>
  );
};

export default SchoolLayout;
