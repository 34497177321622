import React, { useState } from "react";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { Button } from "../../FormElements";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import Select from "react-select";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import {
  useAddStudentToActivityMutation,
  useGetAllActivitiesQuery,
  useGetStudentByIdQuery,
} from "../../../app/services/schoolApi";
import { useParams } from "react-router-dom";

interface IEnrolStudent {
  closeModal: () => void;
}

type StudentOption = {
  value: string;
  label: string;
};

const EnrolStudent = ({ closeModal }: IEnrolStudent) => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: studentId } = useParams();
  const [selectedActivity, setSelectedActivity] =
    useState<StudentOption | null>(null);
  const [addStudentToActivity] = useAddStudentToActivityMutation();
  const { data: activityData } = useGetAllActivitiesQuery(schoolId);
  const { data, isLoading, isError, refetch } = useGetStudentByIdQuery({
    studentId,
    schoolId,
  });

  const handleAddStudent = async () => {
    if (selectedActivity) {
      await addStudentToActivity({
        schoolId,
        activityId: selectedActivity.value,
        studentId,
      });
      refetch();
      closeModal();
    }
  };

  const activityOptions = activityData?.map((activity: any) => ({
    value: activity._id,
    label: `${activity.title}`,
  }));

  return (
    <Container>
      <Wrapper>
        <h3>Enrol student</h3>

        <Select
          options={activityOptions}
          value={selectedActivity}
          onChange={setSelectedActivity}
          placeholder="Select an activity"
        />

        <Button
          color={colors.white}
          onClick={handleAddStudent}
          border="none"
          backgroundColor={colors.primary}
          text={
            isLoading ? <Spinner color={colors.white} /> : "Enrol this student"
          }
          disabled={isLoading}
        />
      </Wrapper>
    </Container>
  );
};

export default EnrolStudent;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.6rem;
  margin: auto;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
