import React from 'react'
import HomeLayout from '../../layouts/HomeLayout'
import Partners from '../../components/Authentication/Partners'

const PartnerAuth = () => {
  return (
    <HomeLayout>
      <Partners />
    </HomeLayout>
  )
}

export default PartnerAuth