import React, { useState, useCallback } from "react";
import { styled } from "styled-components";
import {
  useGetOverviewQuery,
  useGetPriceQuery,
  usePayForSchoolMutation,
} from "../../../app/services/schoolApi";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import { toast } from "react-hot-toast";
import { Button } from "../../FormElements";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import PaymentCard from "./PaymentCard";

const SchoolPayments = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState("");
  const { data: overviewData } = useGetOverviewQuery(schoolId);
  const { data: priceData } = useGetPriceQuery(schoolId);
  const [payForSchool, { isLoading: payIsLoading }] = usePayForSchoolMutation();

  const studentCount = overviewData?.totalStudents || 0;
  const selectedPayment = priceData?.find(
    (item: any) => item._id === selectedPaymentId
  );
  const totalPrice = studentCount * (selectedPayment?.price || 0);

  const handlePayment = async () => {
    if (schoolId && studentCount && selectedPayment) {
      const response = await payForSchool({
        schoolId,
        studentsNo: studentCount,
        price: selectedPayment.price,
        term: selectedPayment.term,
        year: selectedPayment.year,
        paymentId: selectedPayment._id,
      });

      //@ts-ignore
      if (response.data?.authorizationUrl) {
        setIsRedirecting(true);
        //@ts-ignore
        window.location.href = response.data.authorizationUrl;
      } else {
        toast.error("No payment link. Try again");
      }
    } else {
      toast.error(
        "Missing schoolId, studentCount, term, or selected payment. Try again"
      );
    }
  };

  const handlePaymentCardClick = useCallback((paymentId: any) => {
    setSelectedPaymentId(paymentId);
  }, []);

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <h1>Prices List</h1>
          <PaymentsWrapper>
            {priceData ? (
              priceData.length > 0 ? (
                priceData.map((payment: any) => (
                  <PaymentCard
                    key={payment._id}
                    price={payment.price * studentCount}
                    year={payment.year}
                    term={payment.term}
                    isPaid={payment.isPaid}
                    onClick={() => handlePaymentCardClick(payment._id)}
                    isSelected={payment._id === selectedPaymentId}
                  />
                ))
              ) : (
                <p>No payments added yet</p>
              )
            ) : (
              <Spinner color={colors.grey} />
            )}
          </PaymentsWrapper>
        </ContentWrapper>

        <Aside>
          <h3>Make a Payment</h3>
          <p>No of students: {studentCount}</p>
          <p>Unit price per student: ₦{selectedPayment?.price || 0}</p>

          <ItemWrapper>
            <Button
              text={
                payIsLoading ? (
                  <Spinner color={colors.white} />
                ) : (
                  `Pay total of ₦${totalPrice}`
                )
              }
              color={colors.white}
              backgroundColor={colors.primary}
              border="none"
              onClick={handlePayment}
            />
          </ItemWrapper>
        </Aside>
      </Wrapper>
    </Container>
  );
};

export default SchoolPayments;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Aside = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 0.5rem;
  border-left: 1px solid ${colors.primary};
  padding: 1rem 0 1rem 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.8rem;
`;

const PaymentsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
