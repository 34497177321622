import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";

interface IPartnerCard {
  title: string;
  count: number;
  to: string;
}

const PartnerCard = ({ count, title, to }: IPartnerCard) => {
  return (
    <Container to={to}>
      <Wrapper>
        <p>{title}</p>
        <CountWrapper>
          <h1>{count}</h1>
          <small>onboarded</small>
        </CountWrapper>
      </Wrapper>
    </Container>
  );
};

export default PartnerCard;

const Container = styled(Link)`
  display: flex;
  width: fit-content;
  height: 8rem;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-decoration: none;

  @media Screen and (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 5rem 2rem 2rem;
  gap: 2rem;
  color: ${colors.dark};

  p {
    font-size: 0.9rem;
  }
`;

const CountWrapper = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: baseline;

  small {
    font-size: 0.8rem;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    display: flex;
  }
`;
