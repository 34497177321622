import React, { ReactNode } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Navigation from "../../components/common/Navigation";
import { logoutParent, selectParent } from "../../app/features/parentSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const data = [
  {
    id: 1,
    title: "Dashboard",
    linkTo: "parent-dashboard/home",
  },

  {
    id: 2,
    title: "Children",
    linkTo: "parent-dashboard/children",
  },

  {
    id: 3,
    title: "Schools",
    linkTo: "parent-dashboard/schools",
  },

  {
    id: 4,
    title: "Activities",
    linkTo: "parent-dashboard/activities",
  },

  {
    id: 5,
    title: "Settings",
    linkTo: "parent-dashboard/settings",
  },
];

interface IParentsLayout {
  children: ReactNode;
}

const ParentLayout = ({ children }: IParentsLayout) => {
  const { token: parentToken, firstName } = useAppSelector(selectParent);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(logoutParent());
    navigate("/sign-in/parent-signin");
    toast.success("You successfully logged out");
  };



  return (
    <ProtectedRoute token={parentToken} linkTo="/sign-in/parent-signin">
      <Navigation
        token={parentToken}
        firstName={firstName}
        clickLink="parent-signup"
        buttonText="Sign in"
        navData={data}
        handleLogout={handleLogout}
      />
      {children}
    </ProtectedRoute>
  );
};

export default ParentLayout;
