import React from "react";
import { styled } from "styled-components";
import { colors } from "../../../utils";
import AllProviders from "./AllProviders";

const AllProvidersScreen = () => {
  return (
    <Container>
      <Wrapper>
        <h3>All Partners</h3>
        <AllProviders />
      </Wrapper>
    </Container>
  );
};

export default AllProvidersScreen;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  flex-direction: column;
  gap: 1rem;

  h3 {
    font-size: 1rem;
    font-weight: 600;
    color: ${colors.dark};
  }
`;
