import React from "react";
import HomeLayout from "../../layouts/HomeLayout";
import Universities from "../../components/Authentication/Universities";

const UniversityAuth = () => {
  return (
    <HomeLayout>
      <Universities />
    </HomeLayout>
  );
};

export default UniversityAuth;
