import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import FooterElement from "./FooterElement";
import { Link } from "react-router-dom";

const data = [
  {
    fId: 1,
    title: "For Partners",
    content: [
      {
        id: 1,
        heading: "Partners' Roadmap",
        linkTo: "#",
      },
      {
        id: 2,
        heading: "How it works",
        linkTo: "#",
      },
    ],
  },
  {
    fId: 2,
    title: "For Providers",
    content: [
      {
        id: 1,
        heading: "The Activity Solution",
        linkTo: "#",
      },
      {
        id: 2,
        heading: "Registration",
        linkTo: "#",
      },
    ],
  },
  {
    fId: 3,
    title: "For Schools",
    content: [
      {
        id: 1,
        heading: "Micro Schorlarships",
        linkTo: "#",
      },
      {
        id: 2,
        heading: "Out of School Time Program",
        linkTo: "#",
      },
    ],
  },
  {
    fId: 1,
    title: "Company",
    content: [
      {
        id: 1,
        heading: "About us",
        linkTo: "#",
      },
      {
        id: 2,
        heading: "Why ExtraBox",
        linkTo: "#",
      },
      {
        id: 3,
        heading: "Our story",
        linkTo: "#",
      },
      {
        id: 4,
        heading: "Blog",
        linkTo: "#",
      },
    ],
  },
];

const FooterElements = () => {
  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          {data.map((item) => {
            return (
              <FooterElement
                key={item.fId}
                title={item.title}
                content={item.content}
              />
            );
          })}
        </ContentWrapper>

        <ExtraWrapper>
          <Copy>
            <p>&copy; 2024 ExtraBox</p>
          </Copy>
          <LinkWrapper>
            <LinkContent to="#">Terms of Service</LinkContent>
            <LinkContent to="#">Privacy Policy</LinkContent>
            <LinkContent to="#">Cookies Policy</LinkContent>
          </LinkWrapper>
        </ExtraWrapper>
      </Wrapper>
    </Container>
  );
};

export default FooterElements;

const Container = styled.div`
  display: flex;
  width: 90%;
  border-radius: 0.8rem;
  background-color: ${colors.secondary};
  margin: 2rem auto 4rem auto;

  @media (max-width: ${screens.minitab}) {
    width: 100%;
    border-radius: 0;
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 3rem 4rem;

  @media (max-width: ${screens.minitab}) {
    margin: 2rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.white};

  @media (max-width: ${screens.minitab}) {
    flex-direction: column;
  }
`;

const ExtraWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 2rem auto;
  gap: 1.5rem;

  @media (max-width: ${screens.minitab}) {
    flex-direction: column;
  }
`;

const Copy = styled.div`
  display: flex;
  width: 100%;

  p {
    font-size: 0.8rem;
    color: ${colors.white};
    font-weight: 400;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;

  @media (max-width: ${screens.minitab}) {
    flex-direction: column;
  }
`;

const LinkContent = styled(Link)`
  font-size: 0.8rem;
  color: ${colors.white};
  font-weight: 400;
  display: flex;
  width: fit-content;
  text-decoration: none;
`;
