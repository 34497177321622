import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import SignUpCard from "./SignUpCard";
import partner from "../../../assets/images/collaboration.jpeg";
import provider from "../../../assets/images/chess2.jpeg";
import school from "../../../assets/images/karate.jpeg";
import parent from "../../../assets/images/parents.jpeg";

const data = [
  {
    id: 1,
    title: "Partner",
    subtitle: "Are you a Partner? Sign up here.",
    linkTo: "partner-signup",
    backgroundColor: colors.primary,
    src: partner,
  },
  {
    id: 2,
    title: "Provider",
    subtitle: "Are you a Service Provider? Sign up here.",
    linkTo: "provider-signup",
    backgroundColor: colors.green,
    src: provider,
  },
  {
    id: 3,
    title: "School",
    subtitle: "Are you a School Owner? Sign up here.",
    linkTo: "school-signup",
    backgroundColor: colors.orange,
    src: school,
  },
  {
    id: 4,
    title: "Parent",
    subtitle: "Are you a Parent? Sign in here.",
    linkTo: "parent-signup",
    backgroundColor: colors.bluePry,
    src: parent,
  },
];

const SignUp = () => {
  return (
    <Container>
      <Wrapper>
        <h1>Sign up</h1>
        <small>Select your user type and create an account.</small>
        <CardWrapper>
          {data.map((item) => {
            return (
              <SignUpCard
                key={item.id}
                title={item.title}
                subtitle={item.subtitle}
                linkTo={item.linkTo}
                backgroundColor={item.backgroundColor}
                src={item.src}
              />
            );
          })}
        </CardWrapper>
      </Wrapper>
    </Container>
  );
};

export default SignUp;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: 90vh;
  margin: 1rem auto;

  h1 {
    font-size: 2rem;
    font-weight: 500;
    color: ${colors.dark};
    margin-bottom: 0.5rem;
  }

  small {
    font-size: 0.7rem;
    color: ${colors.dark};
    margin-bottom: 2rem;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  row-gap: 2rem;

  @media Screen and (max-width: ${screens.tab}) {
    flex-direction: column;
    gap: 1rem;
  }
`;
