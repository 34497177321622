import { Outlet } from "react-router-dom";
import ProviderDashboard from "../../components/ProviderDashboard";

const ProviderPages = () => {
  return (
    <ProviderDashboard>
      <Outlet />
    </ProviderDashboard>
  );
};

export default ProviderPages;
