import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";

interface IOption {
  optionValue: string;
  optionText: string;
}

interface ISelect {
  value?: string;
  onChange: any;
  name: string;
  defaultOption: string;
  options: IOption[];
  disabled?: boolean;
}

const Select = ({ value, onChange, name, defaultOption, options }: ISelect) => {
  return (
    <SelectInput value={value} name={name} onChange={onChange}>
      <option className="option-default">{defaultOption}</option>
      {options.map((option) => {
        return <option value={option.optionValue}>{option.optionText}</option>;
      })}
    </SelectInput>
  );
};

export default Select;

const SelectInput = styled.select`
  outline: none;
  display: flex;
  width: 100%;
  padding: 0.4rem;
  border: 1px solid ${colors.primary};
  border-radius: 0.25rem;
  background-color: transparent;
  font-size: 1rem;

  option {
    color: grey;
  }

  .option-default {
    color: grey;
    font-family: 1rem;
  }
`;
