import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import { useAppDispatch } from "../../../app/hooks";
import Form from "../../FormElements/Form";
import { Button, Input, Label, PasswordInput } from "../../FormElements";
import useForm, { FormValues } from "../../../hooks/useForm";
import { colors } from "../../../utils";
import PasswordCheck from "../../../utils/PasswordCheck";
import { CustomError } from "../../../types";
import Spinner from "../../../utils/Spinner";
import Select from "../../FormElements/Select";
import { useFetchStatesAndLgas } from "../../../hooks/useFetchStatesAndLgas";
import { useSignupProviderMutation } from "../../../app/services/providerApi";
import { setProvider } from "../../../app/features/providerSlice";

const initialState: FormValues = {
  emailAddress: "",
  firstName: "",
  lastName: "",
  phoneNo: "",
  businessName: "",
  businessAddress: "",
  country: "Nigeria",
  state: "",
  lga: "",
  password: "",
  confirmPassword: "",
};

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { states, lgas, setSelectedState } = useFetchStatesAndLgas("");
  const { formValues, handleInputChange } = useForm(initialState);
  const [passwordType, setPasswordType] = useState("password");
  const {
    emailAddress,
    firstName,
    lastName,
    phoneNo,
    country,
    state,
    lga,
    businessName,
    businessAddress,
    password,
    confirmPassword,
  } = formValues;
  const [providerSignUp, { data, isLoading, isSuccess, isError, error }] =
    useSignupProviderMutation();

  //Toggle Password visibility
  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value;
    handleInputChange({
      target: {
        name: "state",
        value: selectedState,
      },
    } as React.ChangeEvent<HTMLInputElement>);
    setSelectedState(selectedState);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      firstName &&
      lastName &&
      emailAddress &&
      password &&
      phoneNo &&
      country &&
      state &&
      businessName &&
      businessAddress &&
      lga
    ) {
      await providerSignUp({
        firstName,
        lastName,
        emailAddress,
        phoneNo,
        country,
        state,
        lga,
        password,
        businessName,
        businessAddress,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setProvider({
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          emailAddress: data.emailAddress,
          phoneNo: data.phoneNo,
          country: data.country,
          state: data.state,
          lga: data.lga,
          businessAddress: data.businessAddress,
          businessName: data.businessName,
          token: data.token,
        })
      );
      navigate("/provider-dashboard/home");
      toast.success("Hello today. Welcome!");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
    }
  }, [isSuccess, isError, error]);

  return (
    <Form onSubmit={handleSubmit}>
      <ItemWrapper>
        <Label text="First Name" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={firstName}
          name="firstName"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Last Name" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={lastName}
          name="lastName"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Email" fontSize="1rem" color={colors.dark} />
        <Input
          type="email"
          value={emailAddress}
          name="emailAddress"
          onChange={handleInputChange}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Label
          text="Phone No (WhatsApp Preferred)"
          fontSize="1rem"
          color={colors.dark}
        />
        <Input
          type="text"
          value={phoneNo}
          name="phoneNo"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label
          text="Business Name"
          fontSize="1rem"
          color={colors.dark}
        />
        <Input
          type="text"
          value={businessName}
          name="businessName"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label
          text="Business Address"
          fontSize="1rem"
          color={colors.dark}
        />
        <Input
          type="text"
          value={businessAddress}
          name="businessAddress"
          onChange={handleInputChange}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Label text="Country" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={country}
          name="country"
          onChange={handleInputChange}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Label text="State" fontSize="1rem" color={colors.dark} />
        <Select
          value={state}
          onChange={handleStateChange}
          name="state"
          defaultOption="Select state"
          options={states.map((state) => ({
            optionValue: state,
            optionText: state,
          }))}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Label
          text="Local Government Area (LGA)"
          fontSize="1rem"
          color={colors.dark}
        />
        <Select
          value={lga}
          onChange={(e: any) => handleInputChange(e)}
          name="lga"
          defaultOption="Select LGA"
          options={lgas.map((lga) => ({
            optionValue: lga,
            optionText: lga,
          }))}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Label text="Password" fontSize="1rem" color={colors.dark} />
        <PasswordInput
          type={passwordType}
          placeholder="Password"
          name="password"
          value={password}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />

        <Label text="Confirm Password" fontSize="1rem" color={colors.dark} />
        <PasswordInput
          type={passwordType}
          placeholder="Confirm Password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />
        <PasswordCheck password={password} />
      </ItemWrapper>

      <Button
        type="submit"
        text={isLoading ? <Spinner color={colors.white} /> : "Sign up"}
        color={colors.white}
        backgroundColor={colors.primary}
        border="none"
        disabled={isLoading}
      />
    </Form>
  );
};

export default SignUp;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
