import React from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import SignIn from "./SignIn";
import { FormContainer } from "../../FormElements";
import signupImage from "../../../assets/images/uni1.jpeg";
import signinImage from "../../../assets/images/uni2.jpeg";

const Universities = () => {
  const location = useLocation();
  const isSignUpRoute = location.pathname === "/sign-up/university-signup";
  const isSignInRoute = location.pathname === "/sign-in/university-signin";

  return (
    <Wrapper>
      {isSignUpRoute && (
        <FormContainer
          title="Become a Partner Higher Institution with ExtraBox"
          subtitle="Hi there, please fill out and submit the form below:"
          formTitle="Register with Us"
          accountText="Already have an account?"
          accountTo="/sign-in/university-signin"
          accountToText="Sign in"
          backgroundImage={signupImage}
        >
          {/* <SignUp /> */}
          <p>Sign up</p>
        </FormContainer>
      )}
      {isSignInRoute && (
        <FormContainer
          title="End to End Extracurricular Activities Support Platform"
          subtitle="extrabox.club is an innovative schools-students extracurricular activities platform that offer students scholarships for their achievements during elementary and high school."
          formTitle="Login for Universities"
          accountText="Don't have an account?"
          accountTo="/sign-up/university-signup"
          accountToText="Sign up"
          backgroundImage={signinImage}
        >
          <SignIn />
        </FormContainer>
      )}
    </Wrapper>
  );
};

export default Universities;

const Wrapper = styled.div`
  display: 100%;
  width: 100%;
`;
