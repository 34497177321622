import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { styled } from "styled-components";
import {
  useGetSchoolsEnrolledToActivityQuery,
  useCreateScheduleMutation,
} from "../../app/services/providerApi";
import { useAppSelector } from "../../app/hooks";
import { selectProvider } from "../../app/features/providerSlice";
import { useNavigate, useParams } from "react-router-dom";
import { colors, screens } from "../../utils";
import { Input, Form, Button, Label } from "../FormElements";
import Select from "../FormElements/Select";
import Spinner from "../../utils/Spinner";

// Define form state interface
interface FormState {
  schools: string[];
  title: string;
  type: string;
  description: string;
  location: string;
  date: string;
}

const ProviderScheduleSession = () => {
  const navigate = useNavigate();
  const { id: providerId } = useAppSelector(selectProvider);
  const { id: activityId } = useParams();
  const { data: enrolledSchools, isLoading: isLoadingSchools } =
    useGetSchoolsEnrolledToActivityQuery({
      providerId,
      activityId,
    });

  const [createSchedule, { isLoading: isCreating, isSuccess, data }] =
    useCreateScheduleMutation();

  const [formState, setFormState] = useState<FormState>({
    schools: [],
    title: "",
    type: "online",
    description: "",
    location: "",
    date: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (schoolId: string) => {
    setFormState((prevState) => {
      const selectedSchools = prevState.schools.includes(schoolId)
        ? prevState.schools.filter((id) => id !== schoolId)
        : [...prevState.schools, schoolId];
      return { ...prevState, schools: selectedSchools };
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const scheduleData = {
      activityId,
      schools: formState.schools,
      title: formState.title,
      type: formState.type,
      description: formState.description,
      location: formState.location,
      date: formState.date,
    };
    await createSchedule(scheduleData);
  };

  useEffect(() => {
    if (isSuccess && data?.schedule?.type === "online") {
      navigate(
        `/provider-dashboard/schedules/${data.schedule._id}/create-online-meeting`
      );
    }
  }, [isSuccess, data, navigate]);

  return (
    <Container>
      <Wrapper>
        <h3>Schedule a Session</h3>
        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            {/* School Selection */}
            <Section>
              <Label text="Select schools" />
              <small>
                Click the checkbox to add a school and their students to this
                schedule. You can include as many schools as are required.
              </small>
              <CheckboxSection>
                {isLoadingSchools ? (
                  <p>Loading schools...</p>
                ) : (
                  enrolledSchools?.map(
                    (school: { _id: string; name: string }) => (
                      <CheckboxWrapper key={school._id}>
                        <input
                          type="checkbox"
                          value={school._id}
                          checked={formState.schools.includes(school._id)}
                          onChange={() => handleCheckboxChange(school._id)}
                        />
                        <span>{school.name}</span>
                      </CheckboxWrapper>
                    )
                  )
                )}
              </CheckboxSection>
            </Section>

            {/* Title Input */}
            <Section>
              <Label text="Title" />
              <small>
                Write a title that describes the session to schools enrolled
                under this activity
              </small>
              <Input
                type="text"
                name="title"
                value={formState.title}
                onChange={handleChange}
                placeholder="Enter session title"
              />
            </Section>

            {/* Date Input */}
            <Section>
              <Label text="Date" />
              <small>Choose the date for this schedule to take place</small>
              <Input
                type="date"
                name="date"
                value={formState.date}
                onChange={handleChange}
              />
            </Section>

            {/* Type Dropdown */}
            <Section>
              <Label text="Type" />
              <small>Choose whether this schedule is online or onsite</small>
              <Select
                name="type"
                value={formState.type}
                onChange={handleChange}
                defaultOption="Select type"
                options={[
                  { optionValue: "online", optionText: "Online" },
                  { optionValue: "onsite", optionText: "Onsite" },
                ]}
              />
            </Section>

            {/* Description Input */}
            <Section>
              <Label text="Description" />
              <small>Describe the agenda for this schedule</small>
              <textarea
                name="description"
                value={formState.description}
                onChange={handleChange}
                placeholder="Enter session description"
                rows={4}
                required
              />
            </Section>

            {/* Location Input */}
            <Section>
              <Label text="Location" />
              <small>Is it online or onsite?</small>
              <Input
                type="text"
                name="location"
                value={formState.location}
                onChange={handleChange}
                placeholder="Enter location"
              />
            </Section>

            {/* Submit Button */}
            <Button
              type="submit"
              text={
                isCreating ? (
                  <Spinner color={colors.white} />
                ) : (
                  "Create Schedule"
                )
              }
              color="white"
              backgroundColor={isCreating ? colors.grey : colors.primary}
              disabled={isCreating}
              border="none"
            />
          </Form>

          {isSuccess && <p>Schedule created successfully!</p>}
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};

export default ProviderScheduleSession;

// Styled Components
const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 2rem auto;
  gap: 1.5rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  small {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
  }
`;

const CheckboxSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 0.5rem;
  background-color: ${colors.lightBack};
  padding: 1rem;
  border-radius: 0.25rem;

  @media (max-width: ${screens.tab}) {
    grid-template-columns: 1fr;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
