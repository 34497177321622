import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import Spinner from "../../../utils/Spinner";
import { colors } from "../../../utils";
import { styled } from "styled-components";
import Pagination from "../../common/Pagination";
import Activity from "./Activity";
import { useGetAllProviderActivitiesQuery } from "../../../app/services/schoolApi";

const ITEMS_PER_PAGE = 10;

const ProvidersActivities = () => {
  const navigate = useNavigate();
  const { id } = useAppSelector(selectSchool);
  const { data, isLoading, isError } = useGetAllProviderActivitiesQuery(id);
  const [page, setPage] = useState(1);
  const [activitiesToDisplay, setActivitiesToDisplay] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const startIndex = (page - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      setActivitiesToDisplay(data.slice(startIndex, endIndex));
    }
  }, [data, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      {isLoading ? (
        <Spinner color={colors.grey} />
      ) : data?.length === 0 ? (
        <ContentWrapper>
          <p>You have not enrolled for any external activities</p>
        </ContentWrapper>
      ) : data?.length >= 1 ? (
        <ContentWrapper>
          <TopWrapper>
            <h3>List of activities</h3>
          </TopWrapper>
          <Content>
            {activitiesToDisplay.map((activity: any) => {
              return (
                <Activity
                  key={activity._id}
                  linkTo={`providers-activities/${activity._id}`}
                  title={activity.title}
                  mode={activity.mode}
                />
              );
            })}
          </Content>
          <Pagination
            currentPage={page}
            totalItems={data.length}
            itemsPerPage={ITEMS_PER_PAGE}
            onPageChange={handlePageChange}
          />
          <small>
            Select an activity to manage their students and progress
          </small>
        </ContentWrapper>
      ) : null}
    </>
  );
};

export default ProvidersActivities;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.lightBack};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 1.5rem;

  small {
    font-size: 0.7rem;
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
