import HomeLayout from "../../layouts/HomeLayout";
import Home from "../../components/Home";

const HomePage = () => {
  return (
    <HomeLayout>
      <Home />
    </HomeLayout>
  );
};

export default HomePage;
