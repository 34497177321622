import React from "react";
import { styled } from "styled-components";
import { screens } from "../../../utils";
import AdminResources from "../AdminComponents/AdminResources";

const AdminLearning = () => {
  return (
    <Container>
      <Wrapper>
        <AdminResources />
      </Wrapper>
    </Container>
  );
};

export default AdminLearning;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;
