import React from "react";
import { styled } from "styled-components";
import { colors } from "../../../../utils";
import { Link } from "react-router-dom";
import { IoArrowForwardCircleOutline } from "react-icons/io5";

interface IUserSectionCard {
    to: string;
    src: string;
    title: string;
}

const UserSectionCard = ({ to, src, title }: IUserSectionCard) => {
  return (
    <Container to={to}>
      <Wrapper>
        <ImageWrapper>
            <img src={src} alt="" />
        </ImageWrapper>
        <ContentWrapper>
            <TextWrapper>
                <h4>{title}</h4>
            </TextWrapper>
            <IconWrapper>
                <IoArrowForwardCircleOutline color={colors.dark} size="1.3rem" />
            </IconWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default UserSectionCard;

const Container = styled(Link)`
  display: flex;
  width: 100%;
  border-radius: 1.3rem;
  background-color: ${colors.white};
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
  text-decoration: none;
  height: 6rem;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 35%;
  overflow: hidden;
  border-radius: 1.3rem 0 0 1.3rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;

    &:hover {
      scale: 1.1;
      transition: all 0.2s ease-in-out;
    }
  }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    margin: auto 1rem auto 0.5rem;
    flex-direction: column;
`

const TextWrapper = styled.div`
    display: flex;
    width: 100%;
    flex: 1;

    h4 {
        font-size: 0.9rem;
        color: ${colors.dark};
        font-weight: 600;
        line-height: 1.2;
    }
`

const IconWrapper = styled.div`
    display: flex;
    width: fit-content;
    margin-left: auto;
`
