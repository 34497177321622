import React, { useState } from "react";
import {
  useGetAllAdminsQuery,
  useAssignAdminToProviderMutation,
} from "../../../app/services/adminApi";
import Select from "../../FormElements/Select";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";

const AssignAdminToProvider: React.FC<{ providerId: string }> = ({
  providerId,
}) => {
  const {
    data: adminsData,
    isLoading: adminsLoading,
    isError: adminsError,
  } = useGetAllAdminsQuery({});
  const [selectedAdmin, setSelectedAdmin] = useState<string>("");
  const [assignAdminToProvider, { isLoading, isError, isSuccess }] =
    useAssignAdminToProviderMutation();

  const handleAssignAdmin = (e: any) => {
    e.preventDefault();

    if (selectedAdmin) {
      assignAdminToProvider({ adminId: selectedAdmin, providerId });
    }
  };

  if (!providerId || adminsLoading || adminsError) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <Select
          value={selectedAdmin}
          name="selectedAdmin"
          onChange={(e: any) => setSelectedAdmin(e.target.value)}
          defaultOption="Select Admin"
          options={adminsData.map((admin: any) => ({
            optionValue: admin._id,
            optionText: `${admin.firstName} ${admin.lastName}`,
          }))}
        />
        <button
          onClick={handleAssignAdmin}
          disabled={!selectedAdmin || isLoading}
        >
          {isLoading ? (
            <Spinner color={colors.grey} />
          ) : (
            "Assign Admin to Provider"
          )}
        </button>
        {isError && <p>Error assigning admin to provider.</p>}
        {isSuccess && <p>Admin successfully assigned to provider.</p>}
      </Wrapper>
    </Container>
  );
};

export default AssignAdminToProvider;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem auto;
  gap: 1rem;

  button {
    background-color: ${colors.green};
    color: ${colors.white};
    outline: none;
    border: none;
    border-radius: 0.25rem;
    display: flex;
    width: fit-content;
    font-size: 0.7rem;
    padding: 0.2rem 0.9rem;
    cursor: pointer;
  }

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;
