import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { PiTagChevronThin } from "react-icons/pi";
import { TiLocationArrowOutline } from "react-icons/ti";
import { FaChildren } from "react-icons/fa6";
import { colors, screens } from "../../../utils";
import { MdEdit } from "react-icons/md";

interface IParent {
  linkTo: string;
  title: string;
  wards: string;
  parentId?: string; // Optional parentId for edit functionality
}

const Parent = ({ linkTo, title, wards, parentId }: IParent) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    if (parentId) {
      navigate(`${parentId}/edit`);
    }
  };

  return (
    <Container>
      <Wrapper to={linkTo}>
        <Title>
          <PiTagChevronThin size="0.8rem" color={colors.dark} />
          <p>{title}</p>
        </Title>
        <Mode>
          <IconWrapper>
            <FaChildren size="0.9rem" color={colors.dark} />
          </IconWrapper>
          <ModeText>{wards}</ModeText>
        </Mode>
      </Wrapper>
      {parentId && (
        <EditButton onClick={handleEditClick}>
          <MdEdit size="1rem" color={colors.primary} />
          <EditText>Edit</EditText>
        </EditButton>
      )}
    </Container>
  );
};

export default Parent;

const Container = styled.div`
  display: flex;
  align-items: center;
  border-top: 0.5px solid ${colors.grey};
  border-bottom: 0.5px solid ${colors.grey};
  gap: 0.5rem;

  &:hover {
    border: 0.5px solid ${colors.grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const Wrapper = styled(Link)`
  display: flex;
  width: 100%;
  margin: 0.5rem;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
`;

const Title = styled.div`
  display: flex;
  width: 40%; /* Adjust width to make room for Edit button */
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    margin: auto 1rem auto 0;
    display: flex;
    width: 100%;
    text-transform: capitalize;
    word-wrap: break-word;

    @media (max-width: ${screens.minitab}) {
      width: 80%;
      margin: auto;
    }
  }
`;

const Mode = styled.div`
  display: flex;
  width: fit-content;
  margin-left: auto;
  gap: 0.4rem;

  @media (max-width: ${screens.minitab}) {
    display: none;
  }
`;

const ModeText = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.dark};
  display: flex;
  width: 100%;
  text-transform: capitalize;

  strong {
    font-size: 0.8rem;
    font-weight: 700;
    color: ${colors.dark};
    display: flex;
    margin-right: 0.3rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.2rem;
  margin-left: 1rem;
`;

const EditText = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.primary};
`;
