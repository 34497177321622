import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetOneActivityByProviderQuery } from "../../app/services/schoolApi";
import { useAddProviderActivityToSchoolMutation } from "../../app/services/schoolApi";
import { styled } from "styled-components";
import Spinner from "../../utils/Spinner";
import { colors } from "../../utils";
import { useAppSelector } from "../../app/hooks";
import { selectSchool } from "../../app/features/schoolSlice";

const ProviderActivityDetails = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: activityId } = useParams();
  const navigate = useNavigate();
  const [
    enrollActivity,
    {
      isLoading: isEnrolling,
      isError: isEnrollError,
      isSuccess: isEnrolSuccess,
    },
  ] = useAddProviderActivityToSchoolMutation();
  const {
    data: activity,
    isLoading,
    isError,
  } = useGetOneActivityByProviderQuery(activityId);

  const handleEnroll = async () => {
    try {
      await enrollActivity({ schoolId, activityId });
      if (isEnrolSuccess) {
        navigate("enrolled");
      }
    } catch (error) {
      console.error("Enrollment failed", error);
    }
  };

  // Check if the school is already enrolled
  const isSchoolEnrolled = activity?.schools.includes(schoolId);

  return (
    <Container>
      {isLoading ? (
        <Spinner color={colors.primary} />
      ) : isError ? (
        <p>Oops!! Something went wrong</p>
      ) : activity ? (
        <DetailsWrapper>
          <h2>{activity.title}</h2>
          {/* Render description as HTML */}
          <Description
            dangerouslySetInnerHTML={{ __html: activity.description }}
          />
          <p>
            <strong>Price:</strong> {activity.price}
          </p>
          <EnrollButton
            onClick={handleEnroll}
            disabled={isEnrolling || isSchoolEnrolled}
          >
            {isEnrolling
              ? "Enrolling..."
              : isSchoolEnrolled
              ? "Already Enrolled"
              : "Enroll Now"}
          </EnrollButton>
          {isEnrollError && <p>Error enrolling. Please try again.</p>}
        </DetailsWrapper>
      ) : (
        <p>Activity not found.</p>
      )}
    </Container>
  );
};

export default ProviderActivityDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
`;

const DetailsWrapper = styled.div`
  width: 80%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid ${colors.primary};
  border-radius: 8px;
  background-color: ${colors.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 1rem;
    color: ${colors.primary};
  }

  p {
    margin-bottom: 0.5rem;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  em {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  ul,
  ol {
    padding-left: 0.8rem;

    li {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }
`;

const EnrollButton = styled.button`
  background-color: ${colors.primary};
  color: ${colors.white};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.grey};
  }

  &:disabled {
    background-color: ${colors.grey};
    cursor: not-allowed;
  }
`;
