import React, { ReactNode } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { logoutPartner, selectPartner } from "../../app/features/partnerSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Navigation from "../../components/common/Navigation";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const data = [
  {
    id: 1,
    title: "Dashboard",
    linkTo: "partner-dashboard/home",
  },

  {
    id: 2,
    title: "Providers",
    linkTo: "partner-dashboard/provider",
  },

  {
    id: 3,
    title: "Schools",
    linkTo: "partner-dashboard/schools",
  },

  {
    id: 4,
    title: "Learning",
    linkTo: "partner-dashboard/learning",
  },

  {
    id: 5,
    title: "Settings",
    linkTo: "partner-dashboard/settings",
  },
];

interface IPartnersLayout {
  children: ReactNode;
}

const PartnerLayout = ({ children }: IPartnersLayout) => {
  const { token: partnerToken, firstName } = useAppSelector(selectPartner);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(logoutPartner());
    navigate("/sign-in/partner-signin");
    toast.success("You successfully logged out");
  };



  return (
    <ProtectedRoute token={partnerToken} linkTo="/sign-in/partner-signin">
      <Navigation
        token={partnerToken}
        firstName={firstName}
        clickLink="partner-signup"
        buttonText="Sign in"
        navData={data}
        handleLogout={handleLogout}
      />
      {children}
    </ProtectedRoute>
  );
};

export default PartnerLayout;
