import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetCoachQuery,
  useGetHeadOfSchoolQuery,
  useGetStudentActivityResultQuery,
} from "../../../app/services/schoolApi";
import Spinner from "../../../utils/Spinner";
import { colors, screens } from "../../../utils";
import styled from "styled-components";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import generatePDF from "../../../lib/generatePDF";

const SingleStudentResult: React.FC = () => {
  const {
    id: schoolId,
    name,
    address,
    email,
    logo,
  } = useAppSelector(selectSchool);
  const { id: activityId, studentId } = useParams();

  const { data: principalData } = useGetHeadOfSchoolQuery(schoolId);
  const { data: coachData } = useGetCoachQuery({ schoolId, activityId });

  const { data, error, isLoading } = useGetStudentActivityResultQuery({
    schoolId,
    activityId,
    studentId,
  });

  if (isLoading) return <Spinner color={colors.grey} />;
  if (error) return <p>Something went wrong!</p>;

  const activityData = data?.activity;
  const studentData = data?.student?.student;
  const scoreData = data?.student;

  const handleGeneratePDF = () => {
    if (activityData && studentData && scoreData) {
      generatePDF(
        activityData,
        studentData,
        scoreData,
        name || "",
        address || "",
        email || ""
      );
    }
  };

  return (
    <Container>
      <Wrapper id="report-content">
        <FrontMatter>
          {/* <Headline>
            <h1>{name && name.slice(0, 1)}</h1>
          </Headline> */}
          <Headline>
            <img src={logo} alt={name && name.slice(0, 1)} />
          </Headline>
          <h3>{name}</h3>
          <p>{address}</p>
          <small>{email}</small>
          <h4>Activity: {activityData.title}</h4>
        </FrontMatter>
        <GeneratePDFButton onClick={handleGeneratePDF}>
          Generate PDF
        </GeneratePDFButton>

        <ContentWrapper>
          <StudentWrapper>
            <h3>Student Details</h3>
            <StudentData>
              <h5>{studentData.fullName}</h5>
              <p>
                <strong>Gender:</strong> {studentData.gender}
              </p>
              <p>
                <strong>Class:</strong> {studentData.studentClass}
              </p>
            </StudentData>

            <StudentWrapper>
              <h3>Parents Details</h3>
              <ParentWrapper>
                {studentData.parents?.map((parent: any) => (
                  <p key={parent._id}>
                    {parent.firstName} {parent.lastName} - {parent.phoneNo}
                  </p>
                ))}
              </ParentWrapper>
            </StudentWrapper>
          </StudentWrapper>
          <StudentWrapper>
            <ScoresWrapper>
              <Score>
                <h5>Student's Score</h5>
                <p>
                  {scoreData.totalScore}/{scoreData.maxScore}
                </p>
              </Score>

              <Score>
                <h5>% Score</h5>
                <p>{scoreData.percentageScore?.toFixed(1)}%</p>
              </Score>
            </ScoresWrapper>

            <SessionWrapper>
              <h5>Sessions attended with scores</h5>
              <SessionTable>
                <thead>
                  <tr>
                    <th>Session</th>
                    <th>Score</th>
                    <th>Attendance</th>
                  </tr>
                </thead>
                <tbody>
                  {scoreData.register?.map((item: any) => (
                    <tr key={item._id}>
                      <td>{item?.schedule.title}</td>
                      <td>{item?.score}</td>
                      <td>{item?.attendance ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </SessionTable>
            </SessionWrapper>
          </StudentWrapper>
        </ContentWrapper>
        <OfficersWrapper>
          <Officer>
            <OfficerName>
              <strong>Principal's Name: </strong>
              <p>{principalData?.name}</p>
            </OfficerName>
            <OfficerSignature>
              <strong>Signature: </strong>
              <img src={principalData?.signature} alt="" />
            </OfficerSignature>
          </Officer>
          <Officer>
            <OfficerName>
              <strong>Coach's Name: </strong>
              <p>{coachData?.name}</p>
            </OfficerName>
            <OfficerSignature>
              <strong>Signature: </strong>
              <img src={coachData?.signature} alt="" />
            </OfficerSignature>
          </Officer>
        </OfficersWrapper>
      </Wrapper>
    </Container>
  );
};

export default SingleStudentResult;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 2rem;
  flex-direction: column;
  gap: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.1rem;
  border: 2px dotted ${colors.primary};
  padding: 2rem;
  gap: 1rem;
`;

const FrontMatter = styled.div`
  display: flex;
  width: 100%;
  gap: 0.4rem;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px dotted ${colors.grey};
  padding-bottom: 1rem;

  h3 {
    font-size: 0.8rem;
  }

  h4 {
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }

  p {
    font-size: 0.6rem;
    font-style: italic;
    font-weight: 600;
  }

  small {
    font-size: 0.6rem;
    font-style: italic;
  }
`;

const Headline = styled.div`
  display: flex;
  background-color: ${colors.green};
  width: 3rem;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }

  h3 {
    font-size: 0.8rem;
  }
`;

const StudentWrapper = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

const StudentData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.2rem;

  h5 {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.7rem;
    text-transform: capitalize;

    strong {
      font-size: 0.7rem;
    }
  }
`;

const ParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -0.8rem;

  p {
    font-size: 0.7rem;
    text-transform: capitalize;

    strong {
      font-size: 0.7rem;
    }
  }
`;

const ScoresWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;
`;

const Score = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  h5 {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.7rem;
  }
`;

const SessionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;

  h5 {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.7rem;
  }
`;

const SessionTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid ${colors.grey};
    padding: 0.5rem;
    text-align: left;
    font-size: 0.8rem;
  }

  th {
    background-color: ${colors.lightBack};
    font-size: 0.8rem;
  }
`;

const GeneratePDFButton = styled.button`
  background-color: ${colors.primary};
  color: ${colors.white};
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.8rem;
  margin-top: 1rem;
  width: fit-content;
  outline: none;
  border-radius: 0.25rem;

  &:hover {
    background-color: ${colors.secondary};
  }
`;

const OfficersWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  border-top: 0.5px solid ${colors.grey};
`;

const Officer = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 0.3rem;
`;

const OfficerName = styled.div`
  display: flex;
  width: 100%;
  gap: 0.3rem;

  strong {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.8rem;
  }
`;

const OfficerSignature = styled.div`
  display: flex;
  width: 100%;
  gap: 0.3rem;

  strong {
    font-size: 0.8rem;
  }

  img {
    max-height: 1rem;
    width: fit-content;
  }
`;
