import { styled } from "styled-components";
import { colors, screens } from "../../../../utils";
import src from "../../../../assets/images/schools.jpeg";
import { TbTools } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";
import { FiInbox } from "react-icons/fi";
import { Link } from "react-router-dom";

const ForSchools = () => {
  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <Title>For schools</Title>
          <Heading>
            This is how
            <span>great schools </span>
            <span>find good partners.</span>
          </Heading>
          <Body>
            Access the top 1% of extracurricular activities' providers, and a
            full suite of tools to help you manage your students seamlessly.
          </Body>
          <TextWrapper>
            <Text>
              <TbTools color={colors.bluePry} />
              <p>Access vetted extracurricular activities providers</p>
            </Text>
            <Text>
              <BiSupport color={colors.bluePry} />
              <p>Enjoy high level of flexibility and support</p>
            </Text>
            <Text>
              <FiInbox color={colors.bluePry} />
              <p>Partner with ExtraBox for value adding support</p>
            </Text>
          </TextWrapper>
          <LearnMore to="#">
            <p>Learn more</p>
          </LearnMore>
        </ContentWrapper>
        <ImageWrapper>
          <img src={src} alt="" />
        </ImageWrapper>
      </Wrapper>
    </Container>
  );
};

export default ForSchools;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  background-color: ${colors.primary};
  border-radius: 1.2rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem;
`;

const Title = styled.h2`
  font-size: 1rem;
  font-weight: 700;
  color: ${colors.white};
  margin-bottom: 2rem;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  color: ${colors.white};
  font-weight: 600;

  span {
    font-size: 2.5rem;
    color: ${colors.bluePry};
    display: block;
    font-weight: 600;
  }
`;

const Body = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3;
  color: ${colors.white};
  margin-top: 1.5rem;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 1.5rem 0;
  gap: 0.5rem;
`;

const Text = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 0.4rem;

  p {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.3;
    color: ${colors.white};
  }
`;

const LearnMore = styled(Link)`
  display: flex;
  text-decoration: none;
  outline: 1px solid ${colors.white};
  border-radius: 0.5rem;
  background-color: transparent;
  width: fit-content;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.3;
    color: ${colors.white};
    margin: 0.6rem 1.2rem;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 0 1.2rem 1.2rem 0;

  @media (max-width: ${screens.minitab}) {
    display: none;
  }

  img {
    width: 90%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    margin-left: auto;
  }
`;
