import React, { useEffect } from "react";
import { styled } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PasswordReset from "../../common/PasswordReset";
import { CustomError } from "../../../types";
import {
  selectUniversity,
  setUniversity,
} from "../../../app/features/universitySlice";
import { useUpdatePasswordMutation } from "../../../app/services/universityApi";

const UniversityPasswordReset = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { email } = useAppSelector(selectUniversity);
  const [updatePassword, { data, isSuccess, isLoading, isError, error }] =
    useUpdatePasswordMutation();

    console.log(email)

  useEffect(() => {
    if (isSuccess) {
      toast.success("Code sent to your email");
      dispatch(
        setUniversity({
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNo: data.phoneNo,
          universityName: data.universityName,
          universityAddress: data.universityAddress,
          token: data.token,
        })
      );
      navigate("/university-dashboard");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess]);

  return (
    <Container>
      <PasswordReset
        isLoading={isLoading}
        updatePassword={updatePassword}
        email={email}
      />
    </Container>
  );
};

export default UniversityPasswordReset;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
