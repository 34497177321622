import AdminLayout from "../../layouts/AdminLayout";
import { Outlet } from "react-router-dom";

const AdminPages = () => {
  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};

export default AdminPages;
