import React from "react";
import { styled } from "styled-components";
import { colors } from "../../utils";

const Enrolled = () => {
  return (
    <Container>
      <h2>Enrollment Successful!</h2>
      <p>You have successfully enrolled in the activity. Check your dashboard for more details.</p>
    </Container>
  );
};

export default Enrolled;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
`;

const Heading = styled.h2`
  color: ${colors.primary};
`;

const Message = styled.p`
  color: ${colors.black};
`;
