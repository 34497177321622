import React, { FormEvent, ReactNode } from "react";
import { styled } from "styled-components";

interface IForm {
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  children: ReactNode;
}

const Form = ({ onSubmit, children }: IForm) => {
  return <FormContainer onSubmit={onSubmit}>{children}</FormContainer>;
};

export default Form;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
