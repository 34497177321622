import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import Spinner from "../../utils/Spinner";
import { colors, screens } from "../../utils";
import { useGetProviderByIdQuery } from "../../app/services/partnerApi";
import { BsFillPersonFill } from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { FaProjectDiagram } from "react-icons/fa";

const SingleProviderPartner = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess, isError, error } =
    useGetProviderByIdQuery(id);
  return (
    <Container>
      <Wrapper>
        <h2>Provider Details</h2>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Oops!!! Something went wrong</p>
        ) : isSuccess ? (
          <SectionWrapper>
            <ServicesWrapper>
              {data.services.length > 0 ? (
                data.services.map((service: any, index: any) => {
                  return <p>{index}</p>;
                })
              ) : (
                <p>This provider has not updated their services.</p>
              )}
            </ServicesWrapper>
            <ContentWrapper>
              <Content>
                <IconWrapper>
                  <FaProjectDiagram color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Name of Provider: </h1>
                <h1>{data.businessName}</h1>
              </Content>

              <Content>
                <IconWrapper>
                  <BsFillPersonFill color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Name of Provider's Contact: </h1>
                <p>
                  {data.firstName} {data.lastName}
                </p>
              </Content>

              <Content>
                <IconWrapper>
                  <FaPhone color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Phone No: </h1>
                <p>{data.phoneNo}</p>
              </Content>

              <Content>
                <IconWrapper>
                  <MdEmail color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Email: </h1>
                <p>{data.emailAddress}</p>
              </Content>

              <Content>
                <IconWrapper>
                  <MdLocationOn color={colors.dark} size="1rem" />
                </IconWrapper>
                <h1>Address: </h1>
                <p>{data.businessAddress}</p>
              </Content>
            </ContentWrapper>
          </SectionWrapper>
        ) : null}
      </Wrapper>
    </Container>
  );
};

export default SingleProviderPartner;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 1rem auto 3rem;
  flex-direction: column;

  h2 {
    margin: auto;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-direction: row-reverse;

  @media (max-width: ${screens.tab}) {
    flex-direction: column-reverse;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 65%;
  border-radius: 0.5rem;
  padding: 2rem;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
  background-color: ${colors.blueSec};

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 35%;
  border: 1px solid ${colors.grey};
  border-radius: 0.5rem;
  padding: 2rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 0.6rem;

  h1 {
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.4;
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.4;
  }

  small {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  height: 1rem;
  margin-top: 0.1rem;
`;
