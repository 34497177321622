import React from "react";
import { useGetAllUniversitiesQuery } from "../../../app/services/adminApi";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import { MdFlagCircle } from "react-icons/md";
import NoData from "../../../utils/NoData";

const AllUniversities = () => {
  const { data, isError, isSuccess, isLoading, error } =
    useGetAllUniversitiesQuery({});

  return (
    <Container>
      <Wrapper>
        <ItemWrapper>
          <TableWrapper>
            <TabHeading>
              <h3>
                <MdFlagCircle
                  color={colors.primary}
                  size="0.7rem"
                  style={{ marginRight: "0.5rem" }}
                />{" "}
                University Name
              </h3>
              <h3>Email</h3>
              <h3>Phone No</h3>
            </TabHeading>
            <Items>
              {isLoading ? (
                <Spinner color={colors.grey} />
              ) : isError ? (
                <p>Error: Something went wrong!!!</p>
              ) : !data ? (
                <NoData iconColor={colors.grey} textColor={colors.dark} />
              ) : data ? (
                data.map((university: any) => {
                  return (
                    <Item
                      key={university._id}
                      to={`/admin-dashboard/universities/${university._id}`}
                    >
                      <h3>
                        <IconWrapper>
                          <MdFlagCircle
                            color={colors.primary}
                            size="0.7rem"
                            style={{ marginRight: "0.5rem" }}
                          />{" "}
                        </IconWrapper>
                        <MobileText>University: </MobileText>
                        {university.universityName}
                      </h3>
                      <p>
                        <MobileText>Email: </MobileText>
                        {university.email}
                      </p>
                      <p>
                        <MobileText>Phone No: </MobileText>
                        {university.phoneNo}
                      </p>
                    </Item>
                  );
                })
              ) : null}
            </Items>
          </TableWrapper>
        </ItemWrapper>
      </Wrapper>
    </Container>
  );
};

export default AllUniversities;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem auto;
  flex-direction: column;
  gap: 0.5rem;
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
`;

const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.7rem;
`;

const TabHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  gap: 1rem;
  background-color: ${colors.blueSec};
  padding: 0.6rem 0.5rem;

  @media (max-width: ${screens.minitab}) {
    display: none;
  }

  h3 {
    font-size: 0.9rem;
    color: ${colors.dark};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${screens.minitab}) {
    display: none;
  }
`;

const Items = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Item = styled(Link)`
  text-decoration: none;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  gap: 1rem;
  width: 100%;
  border-bottom: 0.5px solid ${colors.grey};

  @media (max-width: ${screens.minitab}) {
    grid-template-columns: 1fr;
    gap: 0.2rem;
  }

  &:first-of-type {
    border-top: 0.5px solid ${colors.grey};
  }

  p {
    font-size: 0.9rem;
    color: ${colors.dark};

    @media (max-width: ${screens.minitab}) {
      display: grid;
      grid-template-columns: 0.3fr 1fr;
    }
  }

  h3 {
    font-size: 0.9rem;
    color: ${colors.dark};
    font-weight: 600;
    display: flex;

    @media (max-width: ${screens.minitab}) {
      display: grid;
      grid-template-columns: 0.3fr 1fr;
      align-items: center;
    }
  }

  &:hover {
    background-color: ${colors.lightBack};
    margin-bottom: 0.5rem;
    transition: all 0.2s ease-out;
  }
`;

const MobileText = styled.span`
  display: none;
  font-weight: 600;
  font-size: inherit;
  color: inherit;

  @media (max-width: ${screens.minitab}) {
    display: flex;
  }
`;
