import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { useNavigate } from "react-router-dom";
import TabsWrapper from "../../common/TabsWrapper";
import InHouseActivities from "./InHouseActivities";
import RentAGadget from "./RentAGadget";
import ProvidersActivities from "./ProvidersActivities";

const tabItems = ["In-House", "Providers", "Rent a Gadget"];

const SchoolActivities = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<string>(() => {
    const storedTab = sessionStorage.getItem("activeTab");
    return storedTab || tabItems[0];
  });

  useEffect(() => {
    sessionStorage.setItem("activeTab", tab);
  }, [tab]);

  const renderChildren = () => {
    switch (tab) {
      case tabItems[0]:
        return <InHouseActivities />;
      case tabItems[1]:
        return <ProvidersActivities />;
      case tabItems[2]:
        return <RentAGadget />;
      default:
        return <InHouseActivities />;
    }
  };

  const renderNavItems = () => {
    return (
      <TabList className="sub-nav">
        {tabItems.map((item) => (
          <li
            key={item}
            className={item === tab ? "selected" : ""}
            onClick={() => setTab(item)}
          >
            {item}
          </li>
        ))}
      </TabList>
    );
  };

  return (
    <Container>
      <Wrapper>
        <TabsWrapper renderNavItems={renderNavItems}>
          <TabsContent>{renderChildren()}</TabsContent>
        </TabsWrapper>
      </Wrapper>
    </Container>
  );
};

export default SchoolActivities;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 90%;
  margin: 1rem auto 2rem;
`;

const TabList = styled.ul`
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
  gap: 2rem;
  flex-wrap: wrap;

  @media Screen and (max-width: ${screens.tab}) {
    gap: 1rem;
  }

  li {
    padding: 0.2rem 1rem;
    background-color: ${colors.white};
    border-radius: 0.25rem;
    cursor: pointer;
    color: ${colors.dark};
    font-size: 0.9rem;
    border: 1px solid ${colors.primary};

    @media Screen and (max-width: ${screens.tab}) {
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .selected {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;

const TabsContent = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  overflow: scroll;
  padding-bottom: 2rem;
`;
