import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useUpdatePasswordMutation } from "../../../app/services/partnerApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PasswordReset from "../../common/PasswordReset";
import { CustomError } from "../../../types";
import { selectPartner, setPartner } from "../../../app/features/partnerSlice";

const PartnerPasswordReset = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { email } = useAppSelector(selectPartner);
  const [updatePassword, { data, isSuccess, isLoading, isError, error }] =
    useUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Code sent to your email");
      dispatch(
        setPartner({
          id: data.existingPartner._id,
          firstName: data.existingPartner.firstName,
          lastName: data.existingPartner.lastName,
          email: data.existingPartner.email,
          phoneNo: data.existingPartner.phoneNo,
          country: data.existingPartner.country,
          state: data.existingPartner.state,
          lga: data.existingPartner.lga,
          token: data.token,
        })
      );
      navigate("/partner-dashboard/home");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess]);

  return (
    <Container>
      <PasswordReset
        isLoading={isLoading}
        updatePassword={updatePassword}
        email={email}
      />
    </Container>
  );
};

export default PartnerPasswordReset;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
