import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectProvider } from "../../app/features/providerSlice";
import { Link, useParams } from "react-router-dom";
import { useGetOneActivityQuery } from "../../app/services/providerApi";
import Spinner from "../../utils/Spinner";
import { colors, screens } from "../../utils";
import { styled } from "styled-components";

const ProviderSingleActivity = () => {
  const { id: providerId } = useAppSelector(selectProvider);
  const { id: activityId } = useParams();

  const { data, isLoading, isError, error } = useGetOneActivityQuery({
    activityId,
    providerId,
  });

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.primary} />
        ) : isError ? (
          <p>Oops! Something went wrong.</p>
        ) : (
          <ContentWrapper>
            <ImageWrapper>
              <img src={data.avatar} alt="" />
            </ImageWrapper>
            <Content>
              <Title>{data?.title}</Title>
              <DescriptionWrapper>
                <h4>Description:</h4>
                <Description
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              </DescriptionWrapper>
              <StudentsWrapper to={`${data?._id}/schools`}>
                <h4>Schools enrolled:</h4>
                <p>{data?.schools.length}</p>
              </StudentsWrapper>
              <StudentsWrapper to={`${data?._id}/students`}>
                <h4>Students enrolled:</h4>
                <p>{data?.students?.length}</p>
              </StudentsWrapper>
            </Content>
          </ContentWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

export default ProviderSingleActivity;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  max-height: 25rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.h3`
  font-size: 1rem;
  color: ${colors.black};
  line-height: 1.5;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
  gap: 0.6rem;
  border-top: 1px solid ${colors.grey};
  border-bottom: 1px solid ${colors.grey};

  h4 {
    font-size: 1rem;
    color: ${colors.black};
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  em {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  ul,
  ol {
    padding-left: 0.8rem;

    li {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }
`;

const StudentsWrapper = styled(Link)`
  display: flex;
  width: 100%;
  gap: 0.6rem;
  text-decoration: none;
  align-items: center;

  h4 {
    text-decoration: none;
    font-size: 1rem;
    color: ${colors.white};
    padding: 0.2rem 0.5rem;
    background-color: ${colors.green};
    border-radius: 0.25rem;
    font-weight: 600;
  }

  p {
    font-size: 1.8rem;
    font-weight: 400;
    color: ${colors.black};
    display: flex;
    margin: auto;
  }
`;
