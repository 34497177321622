import { ReactNode, useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Spinner from "../Spinner";
import { colors } from "../colors";

interface IProtectedRoute {
  linkTo: string;
  token: string | null;
  children: any;
}

interface ILoading {
  link: string;
}

const ProtectedRoute = ({ token, children, linkTo }: IProtectedRoute) => {
  return token ? children : <LoadingToRedirect link={linkTo} />;
};

export default ProtectedRoute;

const LoadingToRedirect = ({ link }: ILoading) => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => currentCount - 1);
    }, 1000);

    count === 0 && navigate(`${link}`);

    return () => clearInterval(interval);
  }, [count, navigate]);

  return (
    <Container>
      <Wrapper>
        <Spinner color={colors.grey} />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.h1`
  font-size: 1.5rem;
  font-weight: 400;
  margin: 10rem auto 0;
  height: 100%;
`;
