import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors, screens } from "../../../utils";
import { PiTagChevronThin } from "react-icons/pi";
import { TiLocationArrowOutline } from "react-icons/ti";

interface INew {
  to: string;
  title: string;
  mode: string;
}

const NewActivity = ({ to, title, mode }: INew) => {
  return (
    <Container to={`/school-dashboard/activities/${to}`}>
      <Wrapper>
        <Title>
          <PiTagChevronThin size="0.8rem" color={colors.dark} />
          <p>{title}</p>
        </Title>

        <Mode>
          <IconWrapper>
            <TiLocationArrowOutline size="0.9rem" color={colors.dark} />
          </IconWrapper>
          <ModeText>{mode}</ModeText>
        </Mode>
      </Wrapper>
    </Container>
  );
};

export default NewActivity;

const Container = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  border-top: 0.5px solid ${colors.grey};
  border-bottom: 0.5px solid ${colors.grey};

  &:hover {
    border: 0.5px solid ${colors.grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0.5rem;
  gap: 1rem;
`;

const Title = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    margin: auto 1rem auto 0;
    display: flex;
    width: 100%;
    text-transform: capitalize;
    word-wrap: break-word;

    @media (max-width: ${screens.minitab}) {
      width: 80%;
      margin: auto;
    }
  }
`;

const ModeText = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${colors.dark};
  display: flex;
  width: 100%;
  text-transform: capitalize;

  strong {
    font-size: 0.8rem;
    font-weight: 700;
    color: ${colors.dark};
    display: flex;
    margin-right: 0.3rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const Mode = styled.div`
  display: flex;
  width: fit-content;
  margin-left: auto;
  gap: 0.4rem;
`;
