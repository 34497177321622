import React, { ReactNode } from 'react'
import { styled } from 'styled-components'

interface IFooter {
    backgroundColor: string;
    children: ReactNode;
}

const Footer = ({backgroundColor, children}: IFooter) => {
  return (
    <Container style={{ backgroundColor: backgroundColor}}>{children}</Container>
  )
}

export default Footer

const Container = styled.div`
    display: flex;
    width: 100%;
`