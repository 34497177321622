import React from 'react'
import HomeLayout from '../../layouts/HomeLayout'
import Schools from '../../components/Authentication/Schools'

const SchoolAuth = () => {
  return (
    <HomeLayout>
        <Schools />
    </HomeLayout>
  )
}

export default SchoolAuth