import React from "react";
import { CiCircleInfo } from "react-icons/ci";
import { styled } from "styled-components";
import { colors } from "./colors";

interface INoData {
  iconColor: string;
  textColor: string;
}

const NoData = ({ iconColor, textColor }: INoData) => {
  return (
    <Container>
      <Wrapper>
        <CiCircleInfo color={iconColor} />
        <p style={{ color: textColor }}>Oops!! No data...</p>
      </Wrapper>
    </Container>
  );
};

export default NoData;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 1rem 2rem;
  flex-direction: column;
  border: 0.5px solid ${colors.grey};
  border-radius: 0.5rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;

  p {
    font-size: 0.8rem;
  }
`;
