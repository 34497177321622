import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  country: "",
  state: "",
  lga: "",
  token: null,
  id: null,
};

export const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    createPartner: (state, action) => {},

    setPartner: (state, action) => {
      localStorage.setItem(
        "partner",
        JSON.stringify({
          id: action.payload.id,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          phoneNo: action.payload.phoneNo,
          country: action.payload.country,
          state: action.payload.state,
          lga: action.payload.lga,
          token: action.payload.token,
        })
      );
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phoneNo = action.payload.phoneNo;
      state.country = action.payload.country;
      state.state = action.payload.state;
      state.lga = action.payload.lga;
      state.token = action.payload.token;
    },

    logoutPartner: (state) => {
      localStorage.clear();
      state.firstName = "";
      state.lastName = "";
      state.id = null;
      state.email = "";
      state.phoneNo = "";
      state.country = "";
      state.state = "";
      state.lga = "";
      state.token = null;
    },
  },
});

export const selectPartner = (state: any) => state.partner;

export const { createPartner, setPartner, logoutPartner } =
  partnerSlice.actions;

export default partnerSlice.reducer;
