import { createGlobalStyle } from "styled-components"

const Colab = "Raleway";

 export const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        //font-family: '${Colab}', sans-serif; 
        font-family: 'Raleway', sans-serif;
        font-size: 16px;
    }
    #root{
        margin: 0 auto;
    }
 `