import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  token: null,
  id: null,
};

export const parentSlice = createSlice({
  name: "parent",
  initialState,
  reducers: {
    createParent: (state, action) => {},

    setParent: (state, action) => {
      localStorage.setItem(
        "parent",
        JSON.stringify({
          id: action.payload.id,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          phoneNo: action.payload.phoneNo,
          token: action.payload.token,
        })
      );
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phoneNo = action.payload.phoneNo;
      state.token = action.payload.token;
    },

    logoutParent: (state) => {
      localStorage.clear();
      state.firstName = "";
      state.lastName = "";
      state.id = null;
      state.email = "";
      state.phoneNo = "";
      state.token = null;
    },
  },
});

export const selectParent = (state: any) => state.admin;

export const { createParent, setParent, logoutParent } = parentSlice.actions;

export default parentSlice.reducer;
