import HomeLayout from "../../layouts/HomeLayout";
import SignUp from "../../components/Authentication/SignUp";

const SignUpSelectPage = () => {
  return (
    <HomeLayout>
      <SignUp />
    </HomeLayout>
  );
};

export default SignUpSelectPage;
